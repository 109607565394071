import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import { AppContext } from '../../AppContext';
import onboardFrame from '../../assets/images/onboard-frame.png';
import cameraIcon from '../../assets/svg/camer-icon.svg';
import {
  ALL_IMAGE_UPLOAD,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
} from '../../common/constants';
import {
  formValidatorRules,
  getBase64,
  googleAnalyticsEvents,
} from '../../common/utils';
import { usePlans } from '../../context/PlanProvider';
import { CREATE_TEAM, GET_SIGNED_URL } from './graphql/mutation';
import './style/workspace.less';

const {
  required,
  workspaceAppNameCheck,
  whiteSpaceWorkspaceName,
} = formValidatorRules;

const WorkSpace = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { refetchUserTeams, setTeamId, ownerTeam } = usePlans();
  const history = useHistory();
  const [form] = Form?.useForm();
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });
  const [currentFile, setCurrentFile] = useState(null);
  const { selectedImage } = state;
  const [imageUpload, setImageUpload] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState();

  const [getSignedUrl] = useMutation(GET_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
      setLoading(false);
    },
  });

  const [team] = useMutation(CREATE_TEAM, {
    onCompleted() {
      googleAnalyticsEvents(GA_EVENT?.WORKSPACE_NAME_ADDED, {
        label: GA_LABEL?.WORKSPACE_NAME_ADDED,
        member_id: currentUser?.id,
      });
    },
    onError(error) {
      setLoading(false);
      return error;
    },
  });

  const redirectToNextStep = () => {
    const path = `${ROUTES?.ONBOARD}${ROUTES?.APP_CREATE}`;
    history?.push(path);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData?.append('file', selectedImage);

    setState({
      ...state,
      uploading: true,
    });
  };

  const onImageCrop = async (info) => {
    setCropImage(info);
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(cropImage);

      const preview = await getBase64(info?.fileList?.[0]?.originFileObj);
      setPreviewImage(preview);
    } else {
      setCropImage(null);
      setCurrentFile(null);
      setImageUpload([]);
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleUpload = async (values) => {
    setLoading(true);
    try {
      let teamLogoUrl = null;
      if (currentFile) {
        const { data: signedUrlData } = await getSignedUrl({
          variables: {
            data: {
              fileName: currentFile?.name,
              fileType: currentFile?.type,
            },
          },
        });

        const signedUrl = signedUrlData?.getSignedUrl?.signedURL;
        teamLogoUrl = signedUrlData?.getSignedUrl?.fileUrl || null;

        if (signedUrl) {
          await axios?.put(signedUrl, currentFile, {
            headers: {
              'Content-Type': currentFile?.type,
              'x-amz-acl': 'public-read',
            },
          });
        }
      }

      const { data: teamData } = await team({
        variables: {
          teamName: values?.teamName,
          teamLogo: teamLogoUrl,
        },
      });

      if (teamData) {
        setTeamId(teamData?.createTeam?.teamData?.id);
        refetchUserTeams();
        redirectToNextStep();
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  useEffect(() => {
    if (cropImage) {
      setCurrentFile(cropImage);
    }
  }, [cropImage]);

  useEffect(() => {
    if (ownerTeam && !currentUser?.allowMultiWorkspaceCreation) {
      history?.push(ROUTES?.APPS);
    }
  }, [currentUser, ownerTeam]);

  return (
    <Row justify="center" className="mt-35 onboard-container">
      <div className="workspace-wrapper">
        <div className="step-content h-192 full-width mt-35 p-24 b-8">
          <span className="workspace-text">Name Your Workspace</span>
          <div className="step-content-icon h-30 mb-8 d-flex mt-24">
            <ImgCrop
              showGrid
              rotationSlider
              onModalOk={onImageCrop}
              modalOk="Save"
            >
              <Upload
                maxCount={1}
                onChange={onChangeImageUpload}
                fileList={imageUpload}
                beforeUpload={() => false}
                showUploadList={false}
                className="apk-uploader"
                customRequest={({ onSuccess }) => {
                  onSuccess('ok');
                }}
              >
                {previewImage ? (
                  <img src={previewImage} alt="app-icon" className="h-16" />
                ) : (
                  <img src={cameraIcon} alt="camera-icon" />
                )}
              </Upload>
            </ImgCrop>
            <span className="image-text">Set a Workspace Image</span>
          </div>
          <div className="step-content-form">
            <div className="step-content-input">
              <Form
                form={form}
                onFinish={handleUpload}
                className="d-flex gap-20"
              >
                <Form.Item
                  name="teamName"
                  rules={[
                    { ...required, message: 'Enter a valid Workspace Name' },
                    workspaceAppNameCheck,
                    whiteSpaceWorkspaceName,
                  ]}
                  className="b-8 workspace-input"
                >
                  <Input
                    placeholder="Workspace Name"
                    maxLength={50}
                    autoFocus
                  />
                </Form.Item>
                <Form.Item shouldUpdate className="step-action">
                  {() => (
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="next-btn"
                      disabled={
                        !form?.isFieldsTouched(true) ||
                        !!form
                          ?.getFieldsError()
                          ?.filter(({ errors }) => errors?.length)?.length
                      }
                      loading={loading}
                    >
                      Next
                      {!loading && <ArrowRightOutlined />}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className="team mt-45">
          <div className="team-box">
            <img src={onboardFrame} alt="OnboardFrame" />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default WorkSpace;
