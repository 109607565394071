import { Col, Row } from 'antd';
import React, { useState } from 'react';
import GroupsList from './components/GroupsList';
import MembersList from './components/MembersList';
import './style/teams.less';

const Teams = () => {
  const [updateTeamsComp, setUpdateTeamsComp] = useState(false);

  return (
    <div className="teams">
      <Row className="w-full" gutter={[30, 30]}>
        <Col md={24} lg={24} xl={12} xxl={12}>
          <MembersList
            updateTeamsComp={updateTeamsComp}
            setUpdateTeamsComp={setUpdateTeamsComp}
          />
        </Col>
        <Col md={24} lg={24} xl={12} xxl={12}>
          <GroupsList
            setUpdateTeamsComp={setUpdateTeamsComp}
            updateTeamsComp={updateTeamsComp}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Teams;
