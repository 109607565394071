import { Col, Modal, Row } from 'antd';
import React from 'react';
import { messageContext } from '../../../../app/components/AppContextHolder';
import { DocumentCopy } from '../../../../assets/svg';
import { copyToClipboard } from '../../../../common/utils';

const ManageDeploymentModal = ({
  visible,
  setVisible,
  manageDeploymentKey,
}) => {
  // comment code as it will use in future
  // const { isFreePlan } = usePlans();

  const copy = () => {
    copyToClipboard(manageDeploymentKey);
    messageContext?.success('App deployment key copied to clipboard');
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // comment code as it will use in future
  // const items = [
  //   {
  //     key: 'deleteApp',
  //     label: (
  //       <div className="d-flex align-center">
  //         <Trash size={18} className="mr-8" />
  //         Delete
  //       </div>
  //     ),
  //     // onClick: () => setDeleteAppModal(true),
  //     danger: true,
  //   },
  //   {
  //     type: 'divider',
  //   },
  //   {
  //     key: 'regenerate',
  //     label: (
  //       <div className="d-flex align-center">
  //         <Repeat className="mr-8" size={18} />
  //         Regenerate
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <Modal
      width={600}
      title="Manage deployment"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      rootClassName="manage-deployment"
      footer={false}
    >
      <Row gutter={[16, 32]}>
        <Col span={6}>Name</Col>
        <Col span={18}>Production</Col>
        <Col span={6} className="d-flex align-center">
          Access Key
        </Col>
        <Col span={18}>
          <div className="api-key-copy">
            <p className="col-content">
              <div>{manageDeploymentKey}</div>
            </p>
            <p className="col-content">
              <DocumentCopy onClick={copy} />
            </p>
          </div>
        </Col>
        {/* comment code as it will use in future
        <Col span={2} className="d-flex align-center">
          <Dropdown
            overlayClassName="dropdown"
            menu={{ items }}
            placement="bottomRight"
            trigger={['click']}
          >
            <DotsThreeVertical size={32} />
          </Dropdown>
        </Col> */}
      </Row>
    </Modal>
  );
};
export default ManageDeploymentModal;
