import { useMutation } from '@apollo/client';
import { Button, Divider, Segmented } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  ActivePlanDot,
  PopularBadge,
  SaveArrow,
  TickBlue,
  TickLightBlue,
} from '../../../../assets/svg';
import { GA_EVENT, GA_LABEL, PLANS } from '../../../../common/constants';
import { googleAnalyticsEvents } from '../../../../common/utils';
import { usePlans } from '../../../../context/PlanProvider';
import { triggerPaddlePayment } from '../../common/paddlePaymentHandlers';
import {
  PURCHASE_SUBSCRIPTION,
  UPGRADE_DOWNGRADE_SUBSCRIPTION,
} from '../graphql/mutations';
import DowngradeSubscriptionModal from './DowngradeSubscriptionModal';
import PurchasePlanAgainModal from './PurchasePlanAgainModal';

function PricingOfPlans({
  monthlyPlans = [],
  yearlyPlans = [],
  handleVisible,
  isPlansInfoLoading,
  paymentFeedback,
  setCountdownModalOpen,
  setCancelPlanRestrictionModal,
}) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription, isFreePlan, teamId } = usePlans();
  const [planType, setPlanType] = useState('Monthly billing');
  const [loadingPlanId, setLoadingPlanId] = useState(null);
  const [downgradeSubscription, setDowngradeSubscription] = useState(false);
  const [purchaseAgainModal, setPurchaseAgainModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState({});
  const isSubscriptionPlanExists = !!teamSubscription?.subscriptionPlan;

  const [upgradeDowngradeSubscription] = useMutation(
    UPGRADE_DOWNGRADE_SUBSCRIPTION,
    {
      onCompleted() {
        setLoadingPlanId(false);
        setCountdownModalOpen(true);
      },
      onError(errorRes) {
        const { graphQLErrors } = errorRes;
        if (
          graphQLErrors[0]?.extensions?.code ===
          'SUBSCRIPTION_CANCELLATION_RESTRICTED'
        ) {
          setCancelPlanRestrictionModal(true);
        }
        setLoadingPlanId(false);
        isPlansInfoLoading(false);
      },
    },
  );

  const [purchaseSubscription] = useMutation(PURCHASE_SUBSCRIPTION, {
    onCompleted(res) {
      const { transactionId } = res?.purchaseSubscription?.data || {};
      triggerPaddlePayment(
        transactionId,
        (event) => {
          googleAnalyticsEvents(GA_EVENT?.PURCHASE_PLAN, {
            label: GA_LABEL?.PURCHASE_PLAN,
            member_id: currentUser?.id,
            purchase_plan_name_type: event?.data?.items?.[0]?.price_name,
            amount_subtotal: event?.data?.items?.[0]?.totals?.subtotal,
            amount_tax: event?.data?.items?.[0]?.totals?.tax,
            amount_total: event?.data?.items?.[0]?.totals?.total,
          });
          setLoadingPlanId(false);
          // resolved callback
          paymentFeedback('success');
        },
        (status) => {
          if (status === 'closed') {
            setLoadingPlanId(false);
            return;
          }
          // reject callback
          paymentFeedback('failed');
        },
      );
    },
    onError() {},
  });

  const onHandlePlanPurchase = (purchaseId) => {
    if (
      isFreePlan ||
      teamSubscription?.subscriptionPlan?.name === PLANS?.LEGACY ||
      teamSubscription?.name === PLANS?.LEGACY
    ) {
      purchaseSubscription({
        variables: {
          data: {
            id: purchaseId,
            teamId,
          },
        },
      });
    } else {
      upgradeDowngradeSubscription({
        variables: {
          data: {
            teamId,
          },
          where: {
            id: purchaseId,
          },
        },
      });
    }
  };

  const onChange = (key) => {
    setPlanType(key);
  };

  const handleContactClick = () => {
    googleAnalyticsEvents(GA_EVENT?.CONTACT_US, {
      label: GA_LABEL?.CONTACT_US,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    const email = 'support@appsonair.com';
    const subject = 'Enterprise inquire for custom plan';
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    // eslint-disable-next-line no-undef
    window.open(mailtoUrl, '_blank');
  };

  const renderPlanButton = (item) => {
    const isSamePlan =
      (item?.label === teamSubscription?.subscriptionPlan?.label ||
        item?.label === teamSubscription?.label) &&
      (item?.type === teamSubscription?.subscriptionPlan?.type ||
        item?.type === teamSubscription?.type);

    if (isSamePlan) {
      return null;
    }

    // added for future used
    // eslint-disable-next-line no-unused-vars
    const isUpgrade =
      item?.amount > teamSubscription?.subscriptionPlan?.amount ||
      item.amount > teamSubscription?.amount;
    const isDowngrade =
      item?.amount < teamSubscription?.subscriptionPlan?.amount ||
      item?.amount < teamSubscription?.amount;

    if (isDowngrade) {
      return (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setSelectedPlanId(item?.id);
            if (item?.name === PLANS?.FREE) {
              handleVisible(true);
            } else {
              setDowngradeSubscription(true);
            }
          }}
        >
          Downgrade Now
        </Button>
      );
    }

    return (
      <Button
        loading={loadingPlanId === item?.id}
        type="primary"
        size="large"
        onClick={() => {
          googleAnalyticsEvents(GA_EVENT?.SELECT_PLAN, {
            label: GA_LABEL?.SELECT_PLAN,
            member_id: currentUser?.id,
            plan_name: item?.name,
            plan_type: item?.type,
            current_plan_name: teamSubscription?.subscriptionPlan?.name,
            current_plan_type: teamSubscription?.subscriptionPlan?.type,
          });
          setLoadingPlanId(item?.id);
          if (isSubscriptionPlanExists) {
            setPurchaseAgainModal(true);
          } else {
            onHandlePlanPurchase(item?.id);
          }
        }}
      >
        Upgrade Now
      </Button>
    );
  };

  const renderPlanCard = (item) => (
    <div
      key={item?.id}
      className={`free-plan ${item?.isProClass ? 'pro' : ''}`}
    >
      {item?.subscriptionFeatures?.IS_POPULAR && (
        <div className="popular-ribbon">
          <PopularBadge /> <p>Popular</p>
        </div>
      )}
      {(item?.label === teamSubscription?.subscriptionPlan?.label ||
        item?.label === teamSubscription?.label) &&
        (item?.type === teamSubscription?.subscriptionPlan?.type ||
          item?.type === teamSubscription?.type) && (
          <div className="active-plan-ribbon">
            <ActivePlanDot /> <p>Active Plan</p>
          </div>
        )}
      <h3 className="title">{item?.label}</h3>
      <h2 className="price">
        {planType === 'Yearly billing'
          ? `$${item?.amount}`
          : `$${item?.amount}`}
        {item?.name !== PLANS?.FREE && (
          <span className="small-text">
            {item?.type === 'ANNUAL' ? '/ year' : '/ month'}
          </span>
        )}
        {item?.type === 'ANNUAL' && planType === 'Yearly billing' && (
          <span className="underline">
            ({item?.subscriptionFeatures?.ORIGINAL_PRICE})
          </span>
        )}
      </h2>
      <p className="description">{item?.description}</p>
      <Divider />
      <div className="list">
        <ul>
          {item?.features?.map((feature) => (
            <li key={feature}>
              {item?.isProClass ? <TickBlue /> : <TickLightBlue />}
              {feature}
            </li>
          ))}
        </ul>
      </div>
      {renderPlanButton(item)}
    </div>
  );

  useEffect(() => {
    if (!isFreePlan && teamSubscription?.subscriptionPlan?.type) {
      setPlanType(
        teamSubscription?.subscriptionPlan?.type === 'MONTHLY'
          ? 'Monthly billing'
          : 'Yearly billing',
      );
    }
  }, [teamSubscription]);

  return (
    <>
      <div className="header">
        {teamSubscription?.name !== PLANS?.FREE ? (
          <h4 className="title">Pricing</h4>
        ) : (
          <>
            <h4 className="title">Discover Your Perfect Plan</h4>
            <p className="description">
              Elevate your experience with the perfect pricing option for you
            </p>
          </>
        )}
      </div>
      <div className="plans" id="plans-section">
        <div className="tabs">
          <div className="tabs-block">
            <Segmented
              value={planType}
              options={['Monthly billing', 'Yearly billing']}
              onChange={onChange}
            />
            {planType === 'Yearly billing' && isFreePlan && (
              <div className="save-yearly">
                <SaveArrow /> <span className="save">Save 15%</span>
              </div>
            )}
          </div>
        </div>
        <div className="plans-cards">
          {planType === 'Monthly billing'
            ? monthlyPlans?.map((item, index) =>
                renderPlanCard({ ...item, isProClass: index % 2 !== 0 }),
              )
            : yearlyPlans?.map((item, index) =>
                renderPlanCard({ ...item, isProClass: index % 2 !== 0 }),
              )}
        </div>
        <div className="support-box">
          <div>
            <h3 className="title">Enterprise</h3>
            <h2 className="price">Custom</h2>
            <p className="description">
              Request custom features and experience a plan with no limitations.
            </p>
          </div>
          <Button size="large" type="primary" onClick={handleContactClick}>
            Contact Us
          </Button>
        </div>
        {downgradeSubscription && (
          <DowngradeSubscriptionModal
            visible={downgradeSubscription}
            handleVisible={setDowngradeSubscription}
            isPlansInfoLoading={isPlansInfoLoading}
            handleFinish={() =>
              upgradeDowngradeSubscription({
                variables: {
                  data: {
                    teamId,
                  },
                  where: {
                    id: selectedPlanId,
                  },
                },
              })
            }
          />
        )}
        {purchaseAgainModal && (
          <PurchasePlanAgainModal
            visible={purchaseAgainModal}
            setVisible={setPurchaseAgainModal}
            setLoadingPlanId={setLoadingPlanId}
            handleAgainPurchase={() => {
              isPlansInfoLoading(true);
              onHandlePlanPurchase(loadingPlanId);
            }}
          />
        )}
      </div>
    </>
  );
}

export default PricingOfPlans;
