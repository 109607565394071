import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popover, Progress, Row } from 'antd';
import React from 'react';
import { InfiniteIcon } from '../../../../assets/svg';
import { PLANS } from '../../../../common/constants';
import {
  calculatePercentageForProgress,
  convertSizeToGBOrMB,
  formatPurchaseDate,
} from '../../../../common/utils';
import { usePlans } from '../../../../context/PlanProvider';

function PlansInfo({ handleCancelPlan, handleManageBilling }) {
  const { teamSubscription, teamPlanUsage, teamPlanTotalUsage } = usePlans();

  const content = (
    <div>
      <p className="m-0">
        The storage encompasses the app's storage requirements.
      </p>
    </div>
  );

  return (
    <div className="current-plan-details">
      <Row gutter={24}>
        <Col span={8}>
          <Card title="Current Plan">
            <div className="blue-text d-flex justify-between align-center">
              {teamSubscription?.subscriptionPlan?.label ||
                teamSubscription?.label ||
                '-'}
              {teamSubscription?.name !== PLANS?.FREE && (
                <Button onClick={handleCancelPlan} danger>
                  Cancel Plan
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Current Billing">
            <div className="blue-text d-flex justify-between align-center">
              $
              {teamSubscription?.subscriptionPlan?.amount ||
                teamSubscription?.amount ||
                0}
              {teamSubscription?.name !== PLANS?.FREE && (
                <Button onClick={handleManageBilling} type="primary">
                  Manage Billing
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <div className="d-flex justify-between">Next Payment Due</div>
            }
          >
            <div className="blue-text">
              {teamSubscription?.subscriptionRenewalDate
                ? formatPurchaseDate(teamSubscription?.subscriptionRenewalDate)
                : '-'}
            </div>
          </Card>
        </Col>
        <Col span={24} className="mt-32">
          <Card title="Account Usage" className="account-usage">
            <ul>
              <li>
                <span>Team Members</span>
                <span className="usage d-flex align-center justify-end gap-4">
                  {teamPlanUsage?.teamMembers} /{' '}
                  {teamPlanTotalUsage?.TEAM_MEMBERS === '-' ? (
                    <InfiniteIcon className="infinite-icon" />
                  ) : (
                    teamPlanTotalUsage?.TEAM_MEMBERS
                  )}
                </span>
              </li>
              <li>
                <span>Apps</span>
                <span className="usage">
                  {teamPlanUsage?.app || 0} / {teamPlanTotalUsage?.APP || 0}
                </span>
              </li>
              <li>
                <div>
                  <span>Storage</span>
                  <Popover content={content} title="Storage Includes">
                    <InfoCircleOutlined className="ml-6" />
                  </Popover>
                </div>
                <span className="usage">
                  <Progress
                    status={
                      calculatePercentageForProgress(
                        teamPlanUsage?.storage,
                        teamPlanTotalUsage?.STORAGE,
                      ) > 90
                        ? 'exception'
                        : 'normal'
                    }
                    percent={calculatePercentageForProgress(
                      teamPlanUsage?.storage,
                      teamPlanTotalUsage?.STORAGE,
                    )}
                    format={() =>
                      `${convertSizeToGBOrMB(teamPlanUsage?.storage)} /
                    ${teamPlanTotalUsage?.STORAGE || 0}`
                    }
                  />
                </span>
              </li>
              <li>
                <div>
                  <span>CodePush Bundle Downloads</span>
                </div>
                <span className="usage">
                  <Progress
                    status={
                      (teamPlanUsage?.codePushDownload /
                        teamPlanTotalUsage?.CODE_PUSH_BUNDLE) *
                        100 >
                      90
                        ? 'exception'
                        : 'normal'
                    }
                    percent={
                      (teamPlanUsage?.codePushDownload /
                        teamPlanTotalUsage?.CODE_PUSH_BUNDLE) *
                      100
                    }
                    format={() =>
                      `${teamPlanUsage?.codePushDownload || 0} /
                    ${teamPlanTotalUsage?.CODE_PUSH_BUNDLE || 0}`
                    }
                  />
                </span>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PlansInfo;
