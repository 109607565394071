import { Modal } from 'antd';
import React from 'react';
import {
  Failed,
  GitAccessFeatureIcon,
  GitHubAuthenticationIcon,
} from '../../../../assets/svg';
import { MODAL_WIDTH_LARGE, ROLE_KEYS } from '../../../../common/constants';

const CodePushAuthenticationModal = ({
  visible,
  setVisible,
  currentRole,
  success = null,
}) => {
  const handleCancel = () => setVisible(false);

  const isOwner = currentRole === ROLE_KEYS?.OWNER;

  return (
    <Modal
      width={MODAL_WIDTH_LARGE}
      centered
      title={false}
      footer={null}
      open={visible}
      onCancel={handleCancel}
      rootClassName="authentication-success"
    >
      <div className="content">
        {!isOwner && !success && (
          <>
            <GitAccessFeatureIcon />
            <h2 className="success-text">Feature Access Requirement</h2>
            <p className="desc text-center">
              To access this feature, the workspace owner needs to enable it
              first. Please contact them for more details.
            </p>
          </>
        )}

        {success === 'true' && (
          <>
            <GitHubAuthenticationIcon />
            <h2 className="success-text">
              Your account has been successfully authenticated and connected.
            </h2>
          </>
        )}

        {success === 'false' && (
          <>
            <Failed />
            <h2 className="success-text">
              Your account has been failed to authenticated.
            </h2>
            <h4>Please try again later.</h4>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CodePushAuthenticationModal;
