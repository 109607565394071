import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Row,
  Switch,
  Upload,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import camera from '../../assets/images/camera.png';
import {
  ALL_IMAGE_UPLOAD,
  DEVELOPER_GUIDELINES_URL,
} from '../../common/constants';
import { formValidatorRules, getBase64 } from '../../common/utils';
import { GET_SIGNED_URL } from '../onboard/graphql/mutation';
import ColorPickerWithTextInput from './components/ColorPicker';
import { MAINTENANCE_APPLICATION } from './graphql/mutations';
import './maintenance-app.less';

const { TextArea } = Input;

const MaintenanceApplication = ({
  setMaintenanceConfig,
  maintenanceCollapse,
  setMaintenanceCollapse,
  setPreviewImage,
  previewImage,
  setInitialValues,
  initialValues,
  fetchData,
}) => {
  const { appId } = useParams();
  const { required, color } = formValidatorRules;
  const [form] = Form?.useForm();

  const [currentFile, setCurrentFile] = useState(null);
  const [imageUpload, setImageUpload] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [initMaintenanceCollapse, setInitMaintenanceCollapse] = useState(
    maintenanceCollapse,
  );

  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });

  const { selectedImage } = state;

  const [maintenanceApplication, { error }] = useMutation(
    MAINTENANCE_APPLICATION,
    {
      onCompleted: async () => {
        setInitMaintenanceCollapse(maintenanceCollapse);
        fetchData();
      },
      onError() {
        setBtnLoading(false);
        return error;
      },
    },
  );

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const handleUpload = async (values) => {
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true,
    });
    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type,
          },
        },
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read',
        },
      };

      await axios?.put(res?.data?.getSignedUrl?.signedURL, currentFile, config);

      await maintenanceApplication({
        variables: {
          data: {
            ...values,
            appId,
            maintenanceImage: res?.data?.getSignedUrl?.fileUrl,
            isMaintenance: maintenanceCollapse === 1 ? true : null,
          },
        },
      });
    } else {
      maintenanceApplication({
        variables: {
          data: {
            ...values,
            appId,
            isMaintenance: maintenanceCollapse === 1 ? true : null,
          },
        },
      });
      setInitialValues({
        maintenanceTitle: 'This app is under maintenance',
        maintenanceDescription: '',
        maintenanceImage: null,
        textColorCode: '#585e75',
        backgroundColorCode: '#f2f4f7',
      });
    }
    setBtnLoading(false);
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);

      const preview = await getBase64(info?.fileList?.[0]?.originFileObj);
      setPreviewImage(preview);
    } else {
      setCurrentFile(null);
      setImageUpload([]);
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleChange = (changedValues, allValues) => {
    setMaintenanceConfig(allValues);
  };

  useEffect(() => {
    form?.setFieldsValue({
      maintenanceTitle: initialValues?.maintenanceTitle,
      maintenanceDescription: initialValues?.maintenanceDescription,
      textColorCode: initialValues?.textColorCode,
      backgroundColorCode: initialValues?.backgroundColorCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleRemoveImage = () => {
    setImageUpload([]);
    setPreviewImage(null);
    setCurrentFile(null);
    setInitialValues((prev) => ({ ...prev, maintenanceImage: null }));
    form?.setFieldValue('maintenanceImage', null);
  };

  const readyImageUrl = useMemo(
    () => previewImage || initialValues?.maintenanceImage,
    [initialValues?.maintenanceImage, previewImage],
  );

  const items = [
    {
      key: '1',
      label: (
        <>
          <Row className="panel-row mb-4">
            <Col>
              <span className="maintenance-heading font-16">
                Maintenance mode
              </span>
            </Col>
            <Col>
              {initMaintenanceCollapse === 1 && maintenanceCollapse === 1 ? (
                <Popconfirm
                  title="This will disable the functionality, Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    setMaintenanceCollapse(null);
                    form?.submit();
                  }}
                  overlayClassName="custom-popconfirm"
                  getPopupContainer={(trigger) => trigger?.parentNode}
                >
                  <Switch checked={maintenanceCollapse} size="small" />
                </Popconfirm>
              ) : (
                <Switch
                  onChange={() =>
                    setMaintenanceCollapse(
                      maintenanceCollapse === null ? 1 : null,
                    )
                  }
                  checked={maintenanceCollapse}
                  size="small"
                />
              )}
            </Col>
          </Row>
          <Row className="main-panel">
            <Col className="d-flex">
              <InfoCircleOutlined className="line-30" />
              <span className="fix-content font-14 ml-4">
                By enabling Maintenance mode, users won’t be able to access the
                app and will be noted as the app is under Maintenance mode.
              </span>
            </Col>
          </Row>
        </>
      ),
      showArrow: false,
      children: (
        <>
          <Form.Item name="maintenanceImage">
            <div className="upload-content d-flex align-center">
              <Upload
                maxCount={1}
                onChange={onChangeImageUpload}
                fileList={imageUpload}
                beforeUpload={() => false}
                showUploadList={false}
              >
                {readyImageUrl ? (
                  <img
                    src={readyImageUrl}
                    alt="appIcon"
                    className="upload-image pointer"
                  />
                ) : (
                  <img src={camera} alt="camera-icon" className="pointer" />
                )}
              </Upload>
              {readyImageUrl && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={handleRemoveImage}
                  className={`remove-image-button ${
                    readyImageUrl ? 'hover-uploader' : ''
                  }`}
                  type="text"
                />
              )}
              <span className="maintenance-text-upload font-14">
                {currentFile?.name ? currentFile?.name : 'Set an image'}
              </span>
            </div>
          </Form.Item>
          <Row>
            <Col className="maintain-col">
              <Form.Item
                name="maintenanceTitle"
                label="Reason for maintenance"
                rules={[
                  {
                    ...required,
                    message: 'Enter valid reason for maintenance',
                  },
                ]}
              >
                <Input maxLength={60} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="maintain-col">
              <Form.Item name="maintenanceDescription" label="Message">
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  maxLength={500}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="maintain-row" gutter={10}>
            <Col className="maintain-col-content" md={24} lg={12}>
              <Form.Item
                name="backgroundColorCode"
                label="Background color code"
                required
                rules={[color]}
              >
                <ColorPickerWithTextInput name="backgroundColorCode" />
              </Form.Item>
            </Col>
            <Col md={24} lg={12}>
              <Form.Item
                name="textColorCode"
                label="Text color code"
                required
                rules={[color]}
              >
                <ColorPickerWithTextInput name="textColorCode" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <span className="s-content font-12">
          To use this feature, you have to integrate our app SDK. View{' '}
          <u>
            <a
              href={DEVELOPER_GUIDELINES_URL}
              target="_blank"
              rel="noreferrer"
              className="link font-500"
            >
              Developer guidelines
            </a>
          </u>{' '}
          to know more.
        </span>
      </Row>

      <Row className="maintenance-card-content">
        <Col span={24}>
          <Form
            name="maintenance-mode"
            initialValues={initialValues}
            layout="vertical"
            form={form}
            onValuesChange={handleChange}
            onFinish={handleUpload}
          >
            <Collapse
              activeKey={maintenanceCollapse}
              collapsible="header"
              expandIcon={null}
              bordered
              expandIconPosition="end"
              ghost
              items={items}
            />
          </Form>
        </Col>
      </Row>
      {maintenanceCollapse && (
        <Row className="main-card-button">
          <Button
            type="primary"
            htmlType="submit"
            className="save-btn"
            onClick={form?.submit}
            loading={btnLoading}
          >
            Save
          </Button>
        </Row>
      )}
    </div>
  );
};

export default MaintenanceApplication;
