import { Button, Modal } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { messageContext } from '../../../../app/components/AppContextHolder';
import { MODAL_WIDTH } from '../../../../common/constants';
import { usePlans } from '../../../../context/PlanProvider';
import '../plan-and-payment.less';

const CancelSubsCriptionModal = ({
  visible,
  handleVisible,
  cancelSubscription,
  isPlansInfoLoading,
}) => {
  const { teamSubscription, teamPlanAddOns } = usePlans();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    const isCodePushAddOnIncludes = teamPlanAddOns?.data.some(
      (sub) => sub?.subscriptionPlan?.name === 'CODE_PUSH_BUNDLE',
    );
    if (!isCodePushAddOnIncludes) {
      setLoading(true);
      isPlansInfoLoading(true);
      await cancelSubscription({
        variables: {
          data: {
            id: teamSubscription?.id,
          },
        },
      });
    } else {
      messageContext?.error(
        'You need to cancel the CodePush add-on before downgrading to the Free Plan.',
      );
    }
    setLoading(false);
    handleVisible(false);
  };

  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="cancel-add-ons"
        title="Cancel subscription"
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        open={visible}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={handleOk}
              type="primary"
              danger
              loading={loading}
            >
              Okay
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <div>
            {`Are you sure you want to cancel your `}
            <strong>
              {capitalize(
                teamSubscription?.subscriptionPlan?.label ||
                  teamSubscription?.label,
              )}
            </strong>
            {` subscription?`}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelSubsCriptionModal;
