import { Avatar, Button, Card, Col, Descriptions, Row, Tag } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import defaultIcon from '../../assets/svg/default-app.svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import { covertAppSizeIntoKbOrMB } from '../../common/utils';

const AppOverviewCard = ({ appData, appStorageUrl, downloadBtn }) => (
  <Card
    bordered
    title="App Overview"
    extra={
      <>
        {appData?.appVersion && (
          <Tag>
            <span className="extra-text font-14">
              Version: {appData?.appVersion}
            </span>
          </Tag>
        )}
        {appData?.appBuild && (
          <Tag>
            <span className="extra-text font-14">
              Build: {appData?.appBuild}
            </span>
          </Tag>
        )}
      </>
    }
    actions={[
      <div key="download">
        <a
          href={appStorageUrl}
          onClick={downloadBtn}
          className="d-flex justify-start"
        >
          <Button key="download" type="primary" className="desktop-download">
            Download Application
          </Button>
        </a>
      </div>,
    ]}
  >
    <Avatar src={appData?.appLogo || defaultIcon} size={48} className="mr-12" />
    <span className="app-name-text font-20">{appData?.appName}</span>
  </Card>
);

const QRCodeCard = ({ link, subLinkId, copyUniqueId }) => (
  <div className="app-detail-qr">
    <span className="public-content">Public URL</span>
    <div className="d-flex justify-center">
      <div className="qr-content">
        <QRCode size={245} value={link} />
        <div className="d-flex flex-horizontal justify-center qr-content-row line-49">
          <img
            src={documentCopy}
            alt="copy"
            onClick={copyUniqueId}
            className="mr-8 pointer"
            width={20}
          />
          <span className="copy-id">{subLinkId}</span>
        </div>
      </div>
    </div>
  </div>
);

const BuildDetailsCard = ({ appData, appSizeInUnit, deviceType }) => (
  <Card bordered title="Build Details">
    <Descriptions layout="vertical" colon={false} column={4}>
      <Descriptions.Item label="Bundle Identifier" span={4}>
        {appData?.appPackageName || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Minimum OS Version" span={2}>
        {deviceType === 'ios'
          ? appData?.appMetadata?.minimumOSVersion
          : appData?.appMetadata?.minSdkVersion || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Target OS Version" span={2}>
        {appData?.appMetadata?.targetSdkVersion || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Device Capabilities" span={2}>
        {appData?.appMetadata?.uiRequiredDeviceCapabilities?.join(', ') || '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Size">{appSizeInUnit}</Descriptions.Item>
    </Descriptions>
  </Card>
);

const PermissionCard = ({ appData, deviceType }) => (
  <Card
    bordered
    title={
      <div className="d-flex align-center">
        <span className="mr-8">
          {deviceType === 'ios' ? 'Provisional Devices' : 'Permissions'}
        </span>
        <div className="count">
          {deviceType === 'ios'
            ? appData?.appMetadata?.provisionedDevices?.length
            : appData?.appMetadata?.permissions?.length || 0}
        </div>
      </div>
    }
  >
    <div className="permission-content">
      {(deviceType === 'ios'
        ? appData?.appMetadata?.provisionedDevices
        : appData?.appMetadata?.permissions || []
      )?.map((detail) => (
        <span className="single-detail" key={detail}>
          {detail}
        </span>
      ))}
    </div>
  </Card>
);

const DesktopDetailPage = ({
  data = {},
  link = '',
  copyUniqueId,
  subLinkId = '',
  appSize = 0,
  appStorageUrl = '',
  downloadBtn,
}) => {
  const { appData = {} } = data?.getApp || {};
  const deviceType = appData?.appMetadata?.provisionedDevices
    ? 'ios'
    : 'android';
  const appSizeInUnit = covertAppSizeIntoKbOrMB(appSize);

  return (
    <div className="app-detail-container">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} lg={12}>
          <div className="item">
            <AppOverviewCard
              appData={appData}
              appStorageUrl={appStorageUrl}
              downloadBtn={downloadBtn}
            />
          </div>
          <div className="item">
            <QRCodeCard
              link={link}
              subLinkId={subLinkId}
              copyUniqueId={copyUniqueId}
            />
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          {appData?.releaseNote && (
            <div className="item">
              <Card bordered title="Release Note">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: appData.releaseNote }}
                />
              </Card>
            </div>
          )}
          <div className="item">
            <BuildDetailsCard
              appData={appData}
              appSizeInUnit={appSizeInUnit}
              deviceType={deviceType}
            />
          </div>
          <div className="item">
            <PermissionCard appData={appData} deviceType={deviceType} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DesktopDetailPage;
