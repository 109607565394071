import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import LoaderComponent from '../../components/LoaderComponent';
import { usePlans } from '../../context/PlanProvider';
import { GET_TEAM } from '../teams/graphql/queries';
import ApiKey from './Apikey';
import AppDetails from './AppDetails';
import AppSettings from './AppSettings';
import AppShare from './AppShare';
import AppSidebar from './AppSidebar';
import MaintenanceServices from './MaintenanceServices';
import UpdateServices from './UpdateServices';
import UploadRelease from './UploadRelease';
import CodePushWrapper from './code-push';
import DynamicLinkWrapper from './dynamic-link';
import Feedbacks from './feedbacks/Feedbacks';

const InsideApp = () => {
  const { teamId, isFreePlan } = usePlans();
  const [teamData, setTeamData] = useState();
  const [loading, setLoading] = useState(true);
  const isDynamicLinkEnable =
    process.env.REACT_APP_ENABLE_DYNAMIC_LINK === 'true';

  const [getTeam] = useLazyQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setTeamData(res?.getTeam?.teamDetails);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (teamId) {
      getTeam({
        variables: { teamId },
      });
    }
  }, [teamId]);

  if (!teamData) return <LoaderComponent setHeight="100" />;

  return (
    <div className="apps-details-content">
      <div className="app-details-inner">
        <AppSidebar teamData={teamData} />
        <div className="app-content">
          {loading ? (
            <LoaderComponent setHeight="150" />
          ) : (
            <Switch>
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId`}
                component={AppDetails}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.NEW_RELEASE}`}
                render={(props) => (
                  <AccessControl
                    allowedPermissions={ACTIONS?.APP_RELEASE}
                    showNoAccess
                  >
                    <UploadRelease {...props} />
                  </AccessControl>
                )}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/:appId${ROUTES?.CREATE_RELEASE}`}
                render={(props) => (
                  <AccessControl
                    allowedPermissions={ACTIONS?.APP_RELEASE}
                    showNoAccess
                  >
                    <UploadRelease {...props} />
                  </AccessControl>
                )}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/:appId`}
                component={AppShare}
              />
              {!isFreePlan && isDynamicLinkEnable && (
                <Route
                  path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId`}
                  component={DynamicLinkWrapper}
                />
              )}
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/:appId${ROUTES?.UPDATE}`}
                render={(props) => (
                  <AccessControl
                    allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
                    showNoAccess
                  >
                    <UpdateServices {...props} />
                  </AccessControl>
                )}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/:appId${ROUTES?.MAINTENANCE}`}
                render={(props) => (
                  <AccessControl
                    allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
                    showNoAccess
                  >
                    <MaintenanceServices {...props} />
                  </AccessControl>
                )}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/:appId${ROUTES?.API_KEY}`}
                render={(props) => (
                  <AccessControl
                    allowedPermissions={ACTIONS?.UPDATE_APP_SERVICE}
                    showNoAccess
                  >
                    <ApiKey {...props} />
                  </AccessControl>
                )}
              />
              {teamData?.allowFeedbacks && (
                <Route
                  exact
                  path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.FEEDBACKS}/:appId`}
                  component={Feedbacks}
                />
              )}
              <Route
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/:appId`}
                component={CodePushWrapper}
              />
              <Route
                exact
                path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/:appId`}
                component={AppSettings}
              />
              <Route path="*" component={Error404} />
            </Switch>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsideApp;
