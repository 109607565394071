import { Button, Modal } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { MODAL_WIDTH } from '../../../../common/constants';
import { usePlans } from '../../../../context/PlanProvider';
import '../plan-and-payment.less';

const DowngradeSubscriptionModal = ({
  visible,
  handleVisible,
  isPlansInfoLoading,
  handleFinish,
}) => {
  const { teamSubscription } = usePlans();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    isPlansInfoLoading(true);
    setLoading(false);
    handleVisible(false);
    handleFinish();
  };

  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="cancel-add-ons"
        title="Downgrade subscription"
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        open={visible}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={handleOk}
              type="primary"
              danger
              loading={loading}
            >
              Okay
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <div>
            {`Are you sure you want to downgrade your `}
            <strong>
              {capitalize(
                teamSubscription?.subscriptionPlan?.label ||
                  teamSubscription?.label,
              )}
            </strong>
            {` subscription?`}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DowngradeSubscriptionModal;
