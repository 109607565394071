import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Divider, Row, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import defaultIcon from '../../../assets/svg/default-app.svg';
import { ROUTES } from '../../../common/constants';

function AppCard({ app = null }) {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);

  return (
    <Col sm={24} md={12} lg={12} xl={8} xxl={6}>
      <div key={app?.id}>
        <Card
          className="app-list-card"
          bordered
          key={app?.id}
          onClick={() => {
            history?.push(
              `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${app?.id}`,
            );
            dispatch({
              type: 'SET_APPLICATION_ID',
              data: app?.id,
            });
            dispatch({
              type: 'SET_APPLICATION_NAME',
              data: app?.appName,
            });
          }}
        >
          <Meta
            avatar={<Avatar src={app?.appLogo || defaultIcon} />}
            title={<span title={app?.appName}>{app?.appName}</span>}
            description={
              <>
                <div className="app-list-info">
                  <span>
                    Last Updated{' '}
                    {moment(
                      app?.dashboard?.latestReleaseDate || app?.updatedAt,
                    )?.fromNow()}
                  </span>
                  <span>
                    {app?.dashboard?.remainingDays <= 7 &&
                      app?.dashboard?.remainingDays > 0 && (
                        <Tooltip
                          title={`Share link for latest build expires in ${app?.dashboard?.remainingDays} days`}
                        >
                          <InfoCircleOutlined className="app-list-info-icon" />
                        </Tooltip>
                      )}
                  </span>
                </div>
              </>
            }
          />
          <Divider />
          <Row>
            <Col span={12} className="line-20">
              Releases
            </Col>
            <Col span={12} className="line-20">
              Downloads
            </Col>
          </Row>
          <Row className="card-content-row">
            <Col className="card-content-col line-24" span={12}>
              {app?.dashboard?.totalUsersUpload}
            </Col>
            <Col className="card-content-col line-24" span={12}>
              {app?.dashboard?.totalAppsDownload}
            </Col>
          </Row>
        </Card>
      </div>
    </Col>
  );
}

export default AppCard;
