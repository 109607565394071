import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Layout } from 'antd';
import { isIOS } from 'mobile-device-detect';
import QRcode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import {
  AppStoreDownload,
  DocumentCopy,
  Logo,
  MobileAppStoreDownload,
  MobilePlayStoreDownload,
  PlayStoreDownload,
  UdidError,
  UdidErrorMobile,
  UdidStart,
  UdidSuccess,
} from '../../assets/svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import {
  APPSONAIR_MAIN_LINK,
  DOWNLOAD_APPSTORE_URL,
  DOWNLOAD_PLAYSTORE_URL,
  ROUTES,
} from '../../common/constants';
import { copyToClipboard, removeQueryParamsFromUrl } from '../../common/utils';
import AdsComponent from '../../components/AdsComponent';
import CommonFooter from '../../components/CommonFooter';
import './detail.less';

const { Header, Content } = Layout;

const IosDeviceUdid = () => {
  const location = useLocation();
  const history = useHistory();
  const [udid, setUdid] = useState(null);
  const [product, setProduct] = useState(null);
  const [version, setVersion] = useState(null);
  const linkRef = useRef(null);

  const faqData = [
    {
      id: 1,
      question: 'Can I change my UDID?',
      answer:
        'No, your UDID (Unique Device Identifier) is a permanent identifier assigned to your device by Apple. Once assigned, it cannot be changed.',
    },
    {
      id: 2,
      question: 'Is sharing my UDID with others safe?',
      answer:
        'While sharing your UDID with trusted developers or services is generally safe, it is important to be cautious. Your UDID is a personal identifier; sharing it with unknown parties can compromise your privacy and security. Always share it only with trusted sources.',
    },
    {
      id: 3,
      question: 'Can I use my UDID for multiple apps or developers?',
      answer:
        'Yes, your UDID can be used for multiple apps or developers. Developers need your UDID to install and test apps on your device, especially for beta testing or development builds. However, each developer must register your UDID in their Apple Developer account for testing purposes.',
    },
    {
      id: 4,
      question: 'What happens if I delete my UDID profile from my device?',
      answer:
        'If you delete the UDID profile from your device, you will no longer be able to install or test apps associated with that profile. This could prevent you from accessing beta or developer versions of apps until you reinstall the appropriate profile.',
    },
    {
      id: 5,
      question: 'Do I need my UDID for public app releases?',
      answer:
        'No, you do not need to provide your UDID for public app releases. UDID is mainly used for beta testing and development purposes, not for the final public version of apps available on the App Store.',
    },
    {
      id: 6,
      question: 'Do I need an Apple Developer Account to get my UDID?',
      answer:
        'No, you don’t need an Apple Developer Account to get your UDID. However, developers may need your UDID if they are creating or testing apps on your device, and they will require a Developer Account to register your UDID for testing.',
    },
    {
      id: 7,
      question: 'How long is my UDID valid for?',
      answer:
        'Your UDID does not expire; it remains valid as long as your device is active. However, if you remove your device from a developer’s profile or your device’s registration expires due to changes in the developer’s account, you may lose access to certain apps or updates.',
    },
    {
      id: 8,
      question: 'Is my UDID linked to my Apple ID?',
      answer:
        'No, your UDID is not directly linked to your Apple ID. The UDID is a unique identifier for your device, whereas your Apple ID is associated with your Apple services and purchases. They are separate but can be used together in specific situations.',
    },
    {
      id: 9,
      question: 'What happens if I delete my UDID profile from my device?',
      answer:
        'If you delete your UDID profile from your device, any apps installed for testing or development purposes will stop working. You may need to re-register your UDID with the developer or re-install the profile to restore functionality.',
    },
  ];

  // eslint-disable-next-line no-undef
  const currentURL = window?.location?.href;
  // eslint-disable-next-line no-undef
  const isMobileScreen = window?.innerWidth <= 575;
  // eslint-disable-next-line no-undef
  const isTabletScreen = window?.innerWidth <= 768;
  // eslint-disable-next-line no-undef
  const isLessThan300 = window?.innerWidth <= 350;
  // eslint-disable-next-line no-undef
  const isAdsForMobile = window?.innerWidth <= 1400;

  const triggerClickAfterOneSecond = () => {
    setTimeout(() => {
      linkRef?.current?.click();
    }, 1000);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    setUdid(queryParams?.get('udid'));
    setProduct(queryParams?.get('product'));
    setVersion(queryParams?.get('version'));

    const timeout = setTimeout(removeQueryParamsFromUrl, 5000);

    return () => clearTimeout(timeout);
  }, [location?.search]);

  const copyContent = (content, successMessage) => {
    copyToClipboard(content);
    messageContext?.success(successMessage);
  };

  const getUrlQrCode = () => {
    if (isMobileScreen) return isLessThan300 ? 100 : 115;
    return isTabletScreen ? 110 : 120;
  };

  const IsIOSTrue = () =>
    udid && product && version ? (
      <div className="udid-success">
        <div className="udid-image">
          <UdidSuccess />
        </div>
        <Card title="Device UDID Details" className="m-auto udid-card">
          <p className="d-flex align-center justify-center">
            <b>UDID:</b>
            <span className="word-break-word ml-4">{udid}</span>
            <img
              src={documentCopy}
              alt="copy"
              onClick={() => copyContent(udid, 'UDID copied to clipboard')}
              className="ml-8 pointer"
              width={20}
            />
          </p>
          <p>
            <b>Product:</b>
            <span className="word-break-word ml-4">{product}</span>
          </p>
          <p>
            <b>Version:</b>
            <span className="word-break-word ml-4">{version}</span>
          </p>
        </Card>
      </div>
    ) : (
      <div className="udid-start">
        <a
          ref={linkRef}
          href="App-prefs://prefs:root=General&path=ManagedConfigurationList"
          className="d-none"
        >
          Click me!
        </a>
        <div className="udid-image">
          <UdidStart />
        </div>
        <h1 className="mb-20">Get your Device ID (UDID)</h1>
        <p>
          Tap the download button to install the temporary profile and iOS will
          take care of the rest.
        </p>
        <p>
          After downloading the profile you will need to go to{' '}
          <b>Settings -&gt; Profile Downloaded</b>
        </p>
        <a href={`${process.env.REACT_APP_SERVER_REST_URL}/v1/udid`} download>
          <Button type="primary" onClick={triggerClickAfterOneSecond}>
            Download
          </Button>
        </a>
      </div>
    );

  return (
    <Layout className="gx-app-layout">
      <Helmet>
        <title>AppsOnAir - Get UDID</title>
      </Helmet>
      <Header>
        <a href={APPSONAIR_MAIN_LINK} rel="noreferrer" className="logo">
          <Logo />
        </a>
        <div className="d-flex align-center">
          <a href={DOWNLOAD_APPSTORE_URL} target="_blank" rel="noreferrer">
            {isTabletScreen ? (
              <MobileAppStoreDownload height={35} width={50} />
            ) : (
              <AppStoreDownload height={40} width={150} />
            )}
          </a>
          {!isIOS && (
            <a href={DOWNLOAD_PLAYSTORE_URL} target="_blank" rel="noreferrer">
              {isTabletScreen ? (
                <MobilePlayStoreDownload height={35} width={50} />
              ) : (
                <PlayStoreDownload height={40} width={150} />
              )}
            </a>
          )}
        </div>
      </Header>
      <Content className="gx-layout-content">
        {isAdsForMobile && (
          <div className="google-ad-banner">
            <AdsComponent
              dataAdSlot="5599605877"
              width={isMobileScreen ? 300 : 500}
              height={90}
            />
          </div>
        )}
        <div className="gx-main-content-wrapper">
          <div className="apps-content public-page ios">
            <div className="main-content ios-udid">
              {isIOS ? (
                IsIOSTrue()
              ) : (
                <div>
                  <div className="udid-error">
                    <h1>Get Your UDID</h1>
                    <p className="sub-text">
                      AppsOnAir simplifies the process of retrieving the UDID.
                    </p>
                    <div className="udid-image">
                      {isMobileScreen ? (
                        <UdidErrorMobile className="udid-mobile" />
                      ) : (
                        <UdidError />
                      )}
                      <QRcode size={getUrlQrCode()} value={currentURL} />
                    </div>
                    <p className="scan-text">
                      Scan this from your iPhone or iPad
                    </p>
                    <p className="or">Or</p>
                    <p className="scan-text">
                      You can copy this URL and paste it in your iPhone or iPad
                      Safari Browser!
                    </p>
                    <div className="mb-8 copy-url">
                      <a href={currentURL}>{currentURL}</a>{' '}
                      <DocumentCopy
                        onClick={() =>
                          copyContent(currentURL, 'URL copied to clipboard')
                        }
                        className="ml-8 pointer"
                      />
                    </div>
                  </div>
                </div>
              )}
              {!isAdsForMobile && (
                <>
                  <div className="left-google-ad">
                    <AdsComponent dataAdSlot="3115003212" />
                  </div>
                  <div className="right-google-ad">
                    <AdsComponent dataAdSlot="5549594866" />
                  </div>
                </>
              )}
            </div>
          </div>
          <section className="border-top">
            <div className="udid-container">
              <h2>How It Works?</h2>
              <div className="steps">
                <p>
                  <strong>Step 1:</strong> Scan the above QR code from your
                  iPhone or iPad to access it from the Safari browser.
                </p>
                <p className="mb-16">
                  <strong>Step 2:</strong> Follow the on-screen instructions to
                  retrieve your UDID securely.
                </p>
                <div className="important-notice">
                  <p className="heading">📌 Important Note:</p>
                  <p>
                    Due to Apple's Stolen Device Protection and security
                    measures, you might encounter a Security Delay message when
                    installing the necessary profile to retrieve your UDID.
                  </p>
                  <ul>
                    <li>
                      The delay typically lasts 1 hour and ensures your device's
                      safety.
                    </li>
                    <li>
                      During the delay, you can still use your iPhone normally,
                      but the sensitive action (like installing the profile)
                      won't proceed until the time has passed.
                    </li>
                    <li>
                      Avoid restarting your device, as it will reset the timer.
                    </li>
                  </ul>
                </div>
                <p className="mt-32 mb-0">
                  <strong>Step 3:</strong> Copy your UDID and share it wherever
                  needed, like registering for app beta testing or development
                  purposes.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="udid-container">
              <h2>What is UDID?</h2>
              <div>
                <p>
                  The UDID (Unique Device Identifier) is a unique, 40-character
                  string that is assigned to every Apple device (iPhone, iPad,
                  iPod Touch, or Apple TV).
                </p>
                <p>
                  It serves as an identifier that distinguishes your device from
                  all other devices. The UDID is generated by Apple and is used
                  for a variety of purposes, primarily related to app
                  development and testing.
                </p>
                <p>
                  The UDID is a key element in the device’s internal system
                  which allows Apple’s software and apps to track and interact
                  with individual devices.
                </p>
                <p>
                  It’s mainly used for internal app development and testing and
                  is not typically exposed to the end user unless specifically
                  needed by a developer.
                </p>
                <p>
                  This identifier plays an important role when it comes to beta
                  testing apps or using development tools, as developers often
                  need to register devices using their UDIDs to install custom
                  apps and updates.
                </p>
              </div>
              <h4 className="mt-32">Key Characteristics of UDID</h4>
              <p>
                <strong>Unique:</strong> No two Apple devices share the same
                UDID. Each device has its own distinct identifier.
              </p>
              <p>
                <strong>Permanent:</strong> Once assigned, the UDID cannot be
                changed, and it stays the same throughout the device’s lifespan.
              </p>
              <p>
                <strong>Non-transferable:</strong> The UDID is linked only to
                the specific hardware (i.e., your Apple device). You cannot
                change it or transfer it between different devices.
              </p>
            </div>
          </section>
          <section>
            <div className="udid-container">
              <h2>Why do You Need a UDID?</h2>
              <div className="testing-steps">
                <h4>1. App Beta Testing</h4>
                <p>
                  When developers create apps, they typically use the UDID to
                  register devices that will be used for testing.
                </p>
                <p>
                  Since Apple’s ecosystem restricts the installation of apps
                  outside of the App Store, the UDID allows developers to push
                  apps to specific devices for testing and feedback.
                </p>
                <p>
                  A UDID registration ensures that only selected testers (with
                  their registered UDIDs) can install and run the app.
                </p>
                <p>
                  <strong>Example:</strong> If you're a beta tester for an iOS
                  app, you will need to provide your UDID to the app developer
                  so they can add your device to their testing list. Without
                  this, you wouldn’t be able to download and test the app.
                </p>
                <h4>2. Development Builds & App Deployment</h4>
                <p>
                  For developers, the UDID is critical when deploying custom
                  development builds on devices.
                </p>
                <p>
                  It helps the developer ensure that the app is installed on the
                  correct devices and allows them to troubleshoot and optimize
                  the app for the specific device model or version.
                </p>
                <p>
                  <strong>Example:</strong> A developer building a custom
                  enterprise app for a business will need to register the UDID
                  of each employee’s device to allow the app to be installed and
                  tested.
                </p>
                <h4>3. Device-Specific Features & Permissions</h4>
                <p>
                  Some apps or services may require a device to be linked to a
                  particular UDID to function properly.
                </p>
                <p>
                  The UDID is used for managing features such as app
                  permissions, restrictions, or in-app purchases that may be
                  specific to a device.
                </p>
                <p>
                  <strong>Example:</strong> Some mobile device management (MDM)
                  systems require the UDID to assign certain enterprise apps to
                  specific devices.
                </p>
                <h4>4. App Store Approval Process (for Developers)</h4>
                <p>
                  While public apps on the App Store don’t require the UDID,
                  developers often use it in the development and approval
                  stages.
                </p>
                <p>
                  When an app is being reviewed by Apple, the UDID may be used
                  internally to track the testing devices.
                </p>
                <h4>5. Accessing Private or Restricted Content</h4>
                <p>
                  Certain apps or services that provide exclusive or premium
                  content (like beta software, developer tools, or restricted
                  game builds) may require you to provide your UDID to gain
                  access to these resources.
                </p>
                <p>
                  <strong>Example:</strong> Some game developers release early
                  access versions of their games only to a select number of
                  devices. To access this, you must provide your UDID to the
                  developer, who will then register it for you.
                </p>
                <h4>
                  6. Device Registration for Testing (In Certain Industries)
                </h4>
                <p>
                  In some industries, such as mobile app development for
                  healthcare or financial institutions, registering a device’s
                  UDID might be part of a broader compliance or quality control
                  process.
                </p>
                <p>
                  <strong>Example:</strong> If you're testing a
                  healthcare-related app that integrates with wearable devices,
                  you may be asked to register your UDID to ensure that
                  sensitive data is accessed only by authorized devices.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="udid-container">
              <div className="features">
                <div>
                  <h3>
                    Upload your IPA or APK files and share them securely in just
                    a few clicks.
                  </h3>
                  <p>✅ Save Hours Every Week</p>
                  <p>✅ Distribute Builds with Ease</p>
                  <p>✅ Deliver Apps Faster to Your Team</p>
                  <Button onClick={() => history?.push(ROUTES?.LOGIN)}>
                    Try AppsOnAir for Free
                  </Button>
                </div>
                <div className="image">
                  <UdidStart />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="udid-container">
              <h2 className="ml-24">FAQ’s</h2>
              <div className="faq-list">
                {faqData?.map((item) => (
                  <Collapse
                    key={item?.id}
                    expandIcon={({ isActive }) => (
                      <div className="expand-icon">
                        {isActive ? <MinusOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                    expandIconPosition="end"
                    size="large"
                    items={[
                      {
                        key: item?.id,
                        label: item?.question,
                        children: (
                          <div // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: item?.answer }}
                          />
                        ),
                      },
                    ]}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
        {isAdsForMobile && <AdsComponent dataAdSlot="4947815866" />}
        <CommonFooter />
      </Content>
    </Layout>
  );
};

export default IosDeviceUdid;
