import React, { createContext, useReducer, useState } from 'react';
import client from './apollo';
import api from './common/api';
import {
  APPLICATION_ID,
  APPLICATION_NAME,
  CODE_PUSH_APPLICATION_ID_LOGIN,
  CODE_PUSH_LIMIT_EXHAUST,
  CURRENT_EMAIL,
  LEGACY_USER_FREE,
  NEW_FEATURE_POPUP,
  REFRESH_TOKEN,
  SMART_BANNER_OPEN,
  TEAM_KEY,
  TOKEN,
  USER,
} from './common/constants';

const initialState = {
  currentUser: null,
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  // eslint-disable-next-line no-undef
  teamId: localStorage?.getItem(TEAM_KEY),
  // eslint-disable-next-line no-undef
  applicationName: localStorage?.getItem(APPLICATION_NAME),
  // eslint-disable-next-line no-undef
  applicationId: localStorage?.getItem(APPLICATION_ID),
  // eslint-disable-next-line no-undef
  userEmail: localStorage?.getItem(CURRENT_EMAIL),
  releaseDefaultLink: false,
  // eslint-disable-next-line no-undef
  smartBanner: !localStorage?.getItem(SMART_BANNER_OPEN),
  codePushApplicationId:
    // eslint-disable-next-line no-undef
    localStorage?.getItem(CODE_PUSH_APPLICATION_ID_LOGIN) ?? null,
  codePushLimitExhaustNotification:
    // eslint-disable-next-line no-undef
    localStorage?.getItem(CODE_PUSH_LIMIT_EXHAUST) ?? null,
  // eslint-disable-next-line no-undef
  newFeaturePopup: localStorage?.getItem(NEW_FEATURE_POPUP),
  // eslint-disable-next-line no-undef
  legacyUserFree: JSON?.parse(localStorage?.getItem(LEGACY_USER_FREE)),
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};
      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      // eslint-disable-next-line no-undef
      window?.location?.reload();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
        teamId: null,
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_APP_RELEASE_DEFAULT_LINK':
      return { ...state, releaseDefaultLink: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(REFRESH_TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_TEAM':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TEAM_KEY, action?.data);
      return { ...state, teamId: action?.data };
    case 'SET_APPLICATION_ID':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(APPLICATION_ID, action?.data);
      return { ...state, applicationId: action?.data };
    case 'SET_APPLICATION_NAME':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(APPLICATION_NAME, action?.data);
      return { ...state, applicationName: action?.data };
    case 'SET_CODE_PUSH_LIMIT_EXHAUST':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(CODE_PUSH_LIMIT_EXHAUST, action?.data);
      return { ...state, codePushLimitExhaustNotification: action?.data };
    case 'SET_EMAIL':
      return { ...state, userEmail: action?.data };
    case 'SET_PROFILE_LOGO':
      return { ...state, profileLogo: action?.data };
    case 'SET_LOADING':
      return { ...state, loading: action?.data };
    case 'SET_NEW_FEATURE_MODAL':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(NEW_FEATURE_POPUP, action?.data);
      return { ...state, newFeaturePopup: action?.data };
    case 'SET_LEGACY_USER_FREE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(LEGACY_USER_FREE, JSON?.stringify(action?.data));
      return { ...state, legacyUserFree: action?.data };
    case 'SET_SMART_BANNER':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(SMART_BANNER_OPEN, action?.data);
      return { ...state, smartBanner: action?.data };
    case 'SET_CODE_PUSH_APPLICATION_ID_LOGIN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(CODE_PUSH_APPLICATION_ID_LOGIN, action?.data);
      return { ...state, codePushApplicationId: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN) || null;
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, userData, userRefreshToken) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refreshToken = userRefreshToken || getRefreshToken();
    const workspace = user?.defaultWorkspace;
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refreshToken });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      dispatch({ type: 'SET_TEAM', data: workspace });
    }
  };

  const setCurrentUser = (data) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      data,
    });
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUser,
    setCurrentUser,
    loading,
    appLoading: setLoading,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
