import { gql } from '@apollo/client';

export const AUTHENTICATE_CODE_PUSH = gql`
  query getCodePushAuthUrl($data: CodePushPlatform!) {
    getCodePushAuthUrl(data: $data) {
      url
    }
  }
`;

// export const CODE_PUSH_APP = gql`
//   query getCodePushApp($data: CodePushPlatform!) {
//     getCodePushApp(data: $data) {
//       url
//     }
//   }
// `;

export const APP_DEPLOYMENT_KEY = gql`
  query getAppDeploymentKey($data: GetAppDeploymentInput!) {
    getAppDeploymentKey(data: $data) {
      key
      type
    }
  }
`;

export const APP_DEPLOYMENT_HISTORY = gql`
  query getAppDeploymentHistory($data: GetAppDeploymentInput!) {
    getAppDeploymentHistory(data: $data) {
      data {
        label
        description
        isMandatory
        isDisabled
        rollout
        appVersion
        blobUrl
        size
        uploadTime
        releaseMethod
        releasedBy
      }
      count
    }
  }
`;

export const APP_DEPLOYMENT_METRICS = gql`
  query getAppDeploymentMetrics($data: GetAppDeploymentMetricsInput!) {
    getAppDeploymentMetrics(data: $data) {
      count
      metrics {
        active
        downloaded
        failed
        installed
      }
      totalInstalled
    }
  }
`;
