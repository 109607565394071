import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import noAppsImg from '../../assets/images/no-apps-img.png';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { checkTesterAccess, googleAnalyticsEvents } from '../../common/utils';
import TesterAccessModal from '../../components/TesterAccessModal';
import { usePlans } from '../../context/PlanProvider';
import './style/no-apps.less';

const NoApps = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription, currentRole } = usePlans();
  const history = useHistory();
  const [testerModal, setTesterModal] = useState(false);

  const handleClick = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.CREATE_APP)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_ON_CREATE_APP, {
        label: GA_LABEL?.CLICK_ON_CREATE_APP,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      history?.push(ROUTES?.CREATE_APP);
    } else {
      setTesterModal(true);
    }
  };
  return (
    <div className="no-apps-div">
      <div className="no-apps-img">
        <img src={noAppsImg} alt="no-apps" />
      </div>
      <div className="no-apps-content">
        <h1>No apps in your workspace</h1>
        <div className="no-apps-release-content">
          <p>Create an app and add releases in it</p>
        </div>

        <div className="no-apps-btn">
          <Button
            type="primary"
            onClick={handleClick}
            className="create-app-btn"
          >
            Create App
          </Button>
        </div>
        {testerModal && (
          <TesterAccessModal
            visible={testerModal}
            setVisible={setTesterModal}
          />
        )}
      </div>
    </div>
  );
};

export default NoApps;
