import React from 'react';

const CodePushBanner = () => (
  <div className="codepush-banner">
    <div className="description-section">
      <h2>
        CodePush is only available for "React Native" apps, allowing developers
        to update their apps instantly without needing a full app store release.
        It is not supported on other platforms.
      </h2>
    </div>
  </div>
);

export default CodePushBanner;
