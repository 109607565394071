import { Button, Carousel, Col, Row, Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import { AppContext } from '../../AppContext';
import { DocumentCopy, PlanCoverApiKey, UpgradeCrown } from '../../assets/svg';
import { DEVELOPER_GUIDELINES_URL, ROUTES } from '../../common/constants';
import { copyToClipboard, upgradeToProEvent } from '../../common/utils';
import { usePlans } from '../../context/PlanProvider';
import './api-key.less';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';

const ApiKey = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamId, isFreePlan, teamSubscription } = usePlans();
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const history = useHistory();
  const { appId } = useParams();

  const copy = () => {
    copyToClipboard(appId);
    messageContext?.success('API key copied to clipboard');
  };

  useEffect(() => {
    if (isFreePlan) {
      setProFeatureModalOpen(true);
    }
  }, []);

  return (
    <div className="services-header">
      {isFreePlan && (
        <div className="app-service-pro-banner">
          <div className="description-section">
            <h2>Get the API Key feature</h2>
            <p>Upgrade to enable updates to your apps.</p>
          </div>
          <div className="button-section">
            <Button
              className="mr-16"
              onClick={() => {
                setProFeatureModalOpen(true);
              }}
            >
              See what you get
            </Button>
            <Button
              type="primary"
              className="upgrade-button"
              onClick={() => {
                upgradeToProEvent(currentUser, teamSubscription);
                history?.push(
                  `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                );
              }}
            >
              <UpgradeCrown />
              Upgrade to pro
            </Button>
          </div>
        </div>
      )}
      <Row className={isFreePlan ? 'fade-feature' : ''}>
        <Col span={18}>
          <Row>
            <div className="s-content font-12 mb-16">
              To use this feature, you have to integrate our app SDK. View&nbsp;
              <u>
                <a
                  href={DEVELOPER_GUIDELINES_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="link font-500"
                >
                  Developer guidelines
                </a>
              </u>{' '}
              to know more.
            </div>
          </Row>
          <div className="api-key-content">
            <Row className="row-content font-16">
              <span>App ID</span>
            </Row>
            <div className="api-key-copy">
              <p className="col-content">
                {!isFreePlan ? (
                  <div>{appId}</div>
                ) : (
                  <Skeleton
                    className="api-key-skeleton"
                    title={false}
                    paragraph={{
                      rows: 1,
                    }}
                  />
                )}
              </p>
              {!isFreePlan && (
                <p className="col-content">
                  <DocumentCopy onClick={copy} />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {proFeatureModalOpen && (
        <AppServiceProFeatureModal
          title="API key"
          visible={proFeatureModalOpen}
          setVisible={setProFeatureModalOpen}
          footer={
            <div className="text-center">
              <Button
                type="primary"
                className="upgrade-button"
                onClick={() => {
                  upgradeToProEvent(currentUser, teamSubscription);
                  history?.push(
                    `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                  );
                }}
              >
                <UpgradeCrown />
                Upgrade to pro
              </Button>
            </div>
          }
          content={
            <Carousel autoplay draggable>
              <div className="app-service-carousel">
                <PlanCoverApiKey />
                <div className="text">
                  <p>Copy and share your API key.</p>
                </div>
              </div>
            </Carousel>
          }
        />
      )}
    </div>
  );
};

export default ApiKey;
