import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import PrivateRoute from '../PrivateRoute';
import { ACTIONS, ROUTES } from '../common/constants';
import AccessControl from '../components/AccessControl';
import UnauthorizedPage from '../components/UnauthorizedPage';
import AppWrapper from '../modules/apps';
import CodePushCLILogin from '../modules/apps/code-push/CodePushCLILogin';
import ProfileSettings from '../modules/auth/ProfileSettings';
import Integrations from '../modules/integrations/Integrations';
import MainWrapper from '../modules/main';
import OnboardWrapper from '../modules/onboard';
import TeamWrapper from '../modules/teams';
import WorkspaceWrapper from '../modules/workspace';

const ContentRoutes = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.MAIN}
      render={() => <Redirect to={ROUTES?.APPS} />}
    />
    <Route path={ROUTES?.ONBOARD} component={OnboardWrapper} />
    <Route
      exact
      path={ROUTES?.CREATE_APP}
      render={(props) => (
        <AccessControl allowedPermissions={ACTIONS?.CREATE_APP} showNoAccess>
          <MainWrapper {...props} />
        </AccessControl>
      )}
    />
    <Route path={ROUTES?.APPS} component={AppWrapper} />
    <Route path={ROUTES?.TEAMS} component={TeamWrapper} />
    <Route path={ROUTES?.INTEGRATIONS} component={Integrations} />
    <Route
      path={ROUTES?.CODE_PUSH_AUTHENTICATION}
      component={CodePushCLILogin}
    />
    <PrivateRoute
      exact
      path={ROUTES?.PROFILE_SETTINGS}
      component={ProfileSettings}
    />
    <PrivateRoute
      path={`${ROUTES?.WORKSPACE_DETAILS}`}
      component={WorkspaceWrapper}
    />
    <PrivateRoute
      exact
      path={ROUTES?.UNAUTHORIZED}
      component={UnauthorizedPage}
    />
    <Route exact path="*" component={Error404} />
  </Switch>
);

export default ContentRoutes;
