import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Card, Carousel, Empty, Input, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import addMember from '../../../assets/images/add-member.png';
import { PlanCoverMember, UpgradeCrown } from '../../../assets/svg';
import {
  ACTIONS,
  GA_EVENT,
  GA_LABEL,
  LIMIT,
  ROUTES,
} from '../../../common/constants';
import {
  googleAnalyticsEvents,
  upgradeToProEvent,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { usePlans } from '../../../context/PlanProvider';
import AppServiceProFeatureModal from '../../apps/components/AppServiceProFeatureModal';
import { GET_MEMBER_APPS_LIST } from '../graphql/queries';
import MembersModal from './MembersModal';
import TeamMember from './TeamMember';

let debounceScroll;
let searchDebounce = null;

const MembersList = ({ updateTeamsComp = false, setUpdateTeamsComp }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { isFreePlan, teamId, teamSubscription } = usePlans();
  const history = useHistory();
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchLoader, setSearchLoader] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [searchData, setSearchData] = useState();

  const handleComplete = () => {
    setLoader(false);
    setSearchLoader(false);
    setUpdateTeamsComp(false);
  };

  const [memberLists, { data }] = useLazyQuery(GET_MEMBER_APPS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const appsDetails = res?.getMemberAppsList?.appsDetails || [];

      setMemberData((prevData) =>
        scrollFlag ? [...(prevData || []), ...appsDetails] : appsDetails,
      );

      setScrollFlag(false);
      setIsEnd(appsDetails?.length < LIMIT);
      handleComplete();
    },
    onError: handleComplete,
  });

  useEffect(() => {
    memberLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, [teamId]);

  useEffect(() => {
    if (updateTeamsComp) {
      setLoader(true);
      setIsEnd(false);
      memberLists({
        variables: {
          data: {
            teamId,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, [updateTeamsComp]);

  const handleAppSearch = (value) => {
    setSearchLoader(true);
    memberLists({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: LIMIT,
          search: value,
        },
      },
    });
  };

  const handleChange = ({ target: { value } }) => {
    setSearchData(value);
    setIsEnd(false);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;
    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 20;
      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        memberLists({
          variables: {
            data: {
              teamId,
              skip: memberData?.length,
              limit: LIMIT,
              search: searchData,
            },
          },
        });
      }
    }, 500);
    debounceScroll();
  };

  const editUser = (editRecord) => {
    setRecord(editRecord);
    setMemberModal(true);
    setIsEdit(true);
  };

  return (
    <div className="members-list-wrap responsive-cards">
      <Card
        title="Members"
        className="card-body"
        extra={<div className="count">{data?.getMemberAppsList?.count}</div>}
      >
        {loader ? (
          <LoaderComponent setHeight="500" />
        ) : (
          <>
            <div className="search-member d-flex justify-between align-center">
              <Input
                prefix={<SearchOutlined className="font-12 search-color" />}
                placeholder="Search"
                onChange={handleChange}
                value={searchData}
                allowClear
              />
              <AccessControl allowedPermissions={ACTIONS?.CREATE_TEAM}>
                <div className="add-member">
                  <Avatar
                    size={40}
                    shape="circle"
                    className="avatar-add-icon"
                    onClick={() => {
                      googleAnalyticsEvents(GA_EVENT?.ADD_NEW_MEMBER, {
                        label: GA_LABEL?.ADD_NEW_MEMBER,
                        member_id: currentUser?.id,
                        current_plan_name:
                          teamSubscription?.subscriptionPlan?.name,
                        current_plan_type:
                          teamSubscription?.subscriptionPlan?.type,
                      });
                      setMemberModal(true);
                    }}
                    src={addMember}
                  />
                </div>
              </AccessControl>
            </div>
            <div className="member-list-title d-flex justify-between align-center">
              <span>Members</span>
              <div className="add-member">
                <span>Shared Apps</span>
              </div>
            </div>
            {memberData?.length === 0 ? (
              <div className="no-data-found">
                <Empty />
              </div>
            ) : (
              <>
                {searchLoader ? (
                  <div className="ant-loader">
                    <LoaderComponent setHeight="500" spinning={searchLoader} />
                  </div>
                ) : (
                  <div
                    className="member-list custom-scrollbar"
                    onScroll={handleScroll}
                  >
                    {memberData?.map((details) => (
                      <TeamMember
                        key={details?.id}
                        details={details}
                        editUser={editUser}
                      />
                    ))}
                    {scrollFlag && (
                      <div className="ant-loader">
                        <Spin spinning={scrollFlag} />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
        <MembersModal
          memberModal={memberModal}
          handleVisible={setMemberModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setProFeatureModalOpen={setProFeatureModalOpen}
          record={record}
          setRecord={setRecord}
          setSearchData={setSearchData}
          memberLists={memberLists}
          setListLoader={setLoader}
          setIsListEnd={setIsEnd}
        />
      </Card>
      {proFeatureModalOpen && (
        <AppServiceProFeatureModal
          title="Add new members"
          visible={proFeatureModalOpen}
          setVisible={setProFeatureModalOpen}
          footer={
            <div className="d-flex justify-center gap-24">
              {isFreePlan && (
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    upgradeToProEvent(currentUser, teamSubscription);
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade to pro
                </Button>
              )}
              <Button
                className="add-ons-upgrade-button"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/${teamId}`,
                  );
                }}
              >
                Purchase add-ons
              </Button>
            </div>
          }
          content={
            <Carousel autoplay draggable>
              <div className="app-service-carousel">
                <PlanCoverMember />
                <div className="text">
                  {isFreePlan ? (
                    <p>
                      Upgrade to Pro for Enhanced Team Collaboration or else you
                      can purchase add ons also!
                    </p>
                  ) : (
                    <p>
                      Want more team members? Add new members to your current
                      workspace.
                    </p>
                  )}
                </div>
              </div>
            </Carousel>
          }
        />
      )}
    </div>
  );
};

export default MembersList;
