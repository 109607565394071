import { Avatar, Col, Row, Tag, Tooltip } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import defaultIcon from '../../../assets/svg/default-app.svg';
import { avatarColor } from '../../../common/utils';

const TeamMember = ({ details = null, editUser }) => (
  <div className="member-list-row">
    <Row className="member-lists" onClick={() => editUser(details)}>
      <Col span={3}>
        <Avatar
          size={38}
          style={
            !details?.profileImage && {
              backgroundColor: avatarColor(
                details?.firstName || details?.invitedUserEmail,
              ),
            }
          }
          className="no-hover"
          src={details?.profileImage}
        >
          <span className="font-500">
            {details?.firstName?.charAt(0)?.toUpperCase() ||
              details?.invitedUserEmail?.charAt(0)?.toUpperCase()}
          </span>
        </Avatar>
      </Col>
      <Col span={11}>
        <div className="member-tag">
          {details?.userRegisterStatus === 'REGISTERED' ? (
            <div className="name-tags line-22">
              {trim(`${details?.firstName || ''} ${details?.lastName || ''}`)}
            </div>
          ) : (
            <Tag className="tag-bg">Invited</Tag>
          )}
          <span title={details?.invitedUserEmail} className="user-email">
            {details?.invitedUserEmail}
          </span>
        </div>
      </Col>
      <Col span={6}>
        <Tag className="member-tag-role">{details?.role}</Tag>
      </Col>
      <Col span={4} className="d-flex justify-end">
        <Avatar.Group
          maxCount={2}
          maxStyle={{
            color: '#585E75',
            backgroundColor: '#F2F4F7',
            height: '24px',
            width: '24px',
            lineHeight: '24px',
            fontSize: '12px',
          }}
        >
          {details?.applicationList?.map((value) => (
            <Tooltip title={value?.appName} key={value?.id}>
              <Avatar
                size={24}
                key={value?.id}
                src={value?.appLogo || defaultIcon}
                alt="logo"
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      </Col>
    </Row>
  </div>
);

export default TeamMember;
