import { AndroidFilled, AppleFilled, LeftOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  ArrowsClockwise,
  BoxArrowUp,
  Code,
  GearSix,
  Link as LinkIcon,
  ListStar,
  UserGear,
  UserList,
  WebhooksLogo,
  Wrench,
} from '@phosphor-icons/react';
import { Avatar, Menu } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import defaultIcon from '../../assets/svg/default-app.svg';
import { ROUTES } from '../../common/constants';
import { usePlans } from '../../context/PlanProvider';
import { GET_APP } from './graphql/queries';
import './style/app-name-header.less';

const AppSidebar = ({ teamData }) => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const { isFreePlan } = usePlans();
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const { appId } = useParams();
  const isDynamicLinkEnable =
    process.env.REACT_APP_ENABLE_DYNAMIC_LINK === 'true';

  const [getAppDetails, { data }] = useLazyQuery(GET_APP, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_APPLICATION_NAME',
        data: res?.getApplication?.appName,
      });
    },
    onError() {},
  });

  const items = [
    {
      key: 'releases',
      label: (
        <Link
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${appId}`}
        >
          Releases
        </Link>
      ),
      icon: <BoxArrowUp />,
    },
    {
      key: 'apps-services',
      label: 'App Services',
      icon: <Wrench />,
      children: [
        {
          key: 'update',
          label: (
            <Link
              to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/${appId}${ROUTES?.UPDATE}`}
            >
              Update
            </Link>
          ),
          icon: <ArrowsClockwise />,
        },
        {
          key: 'maintenance',
          label: (
            <Link
              to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/${appId}${ROUTES?.MAINTENANCE}`}
            >
              Maintenance Mode
            </Link>
          ),
          icon: <UserGear />,
        },
        {
          key: 'api-key',
          label: (
            <Link
              to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}/${appId}${ROUTES?.API_KEY}`}
            >
              API Key
            </Link>
          ),
          icon: <WebhooksLogo />,
        },
      ],
    },
    {
      key: 'app-share',
      label: (
        <Link to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/${appId}`}>
          Members
        </Link>
      ),
      icon: <UserList />,
    },
    !isFreePlan &&
      isDynamicLinkEnable && {
        key: 'dynamic-link',
        label: (
          <Link
            to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/${appId}`}
          >
            Dynamic Link
          </Link>
        ),
        icon: <LinkIcon />,
      },
    teamData?.allowFeedbacks && {
      key: 'feedbacks',
      label: (
        <Link
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.FEEDBACKS}/${appId}`}
        >
          Feedbacks
        </Link>
      ),
      icon: <ListStar />,
    },
    ...[
      currentUser?.isCodePushConnect && data?.getApplication?.isCodePushEnabled
        ? {
            key: 'codepush',
            label: 'CodePush',
            icon: <Code />,
            children: [
              {
                key: 'android',
                label: (
                  <Link
                    to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${appId}${ROUTES?.ANDROID}`}
                  >
                    Android
                  </Link>
                ),
                icon: <AndroidFilled className="font-16" />,
              },
              {
                key: 'ios',
                label: (
                  <Link
                    to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${appId}${ROUTES?.IOS}`}
                  >
                    IOS
                  </Link>
                ),
                icon: <AppleFilled className="font-16" />,
              },
            ],
          }
        : {
            key: 'codepush',
            label: (
              <Link
                to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${appId}`}
              >
                CodePush
              </Link>
            ),
            icon: <Code />,
          },
    ],
    {
      key: 'app-settings',
      label: (
        <Link
          className="d-flex align-center"
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/${appId}`}
        >
          App Settings
        </Link>
      ),
      icon: <GearSix />,
    },
  ];

  useEffect(() => {
    getAppDetails({
      variables: {
        appId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BackToApps = () => {
    history?.push(ROUTES?.APPS);
  };

  return (
    <div className="sidebar-content">
      <div className="app-name-header">
        <LeftOutlined className="mr-16" onClick={BackToApps} />
        <Avatar
          src={data?.getApplication?.appLogo || defaultIcon}
          alt="application-img"
          className="avatar-width"
          size={28}
        />
        <span
          className="header-app-name font-16"
          title={data?.getApplication?.appName}
        >
          {data?.getApplication?.appName}
        </span>
      </div>
      <Menu
        selectedKeys={[setLocation?.[3], setLocation?.[5]]}
        mode="inline"
        items={items}
        className="app-menu"
      />
    </div>
  );
};

export default AppSidebar;
