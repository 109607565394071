import { Button, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  MODAL_WIDTH,
  PLAN_NAMES,
} from '../../../../common/constants';
import { googleAnalyticsEvents } from '../../../../common/utils';
import { usePlans } from '../../../../context/PlanProvider';
import '../manage-addon.less';

const CancelAddOnModal = ({
  visible,
  setVisible,
  selectedAddOn,
  setSelectedAddOn,
  cancelAddOn,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription } = usePlans();
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    googleAnalyticsEvents(GA_EVENT?.CANCEL_ADDON, {
      label: GA_LABEL?.CANCEL_ADDON,
      member_id: currentUser?.id,
      addon_name: selectedAddOn?.subscriptionPlan?.name,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    setLoading(true);
    await cancelAddOn({
      variables: {
        data: {
          id: selectedAddOn?.id,
        },
      },
    });
    setLoading(false);
    setVisible(false);
  };

  const handleCancel = () => {
    setSelectedAddOn([]);
    setVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        className="cancel-add-ons"
        title={`Cancel ${
          PLAN_NAMES?.[selectedAddOn?.subscriptionPlan?.name]
        } Add-ons`}
        width={MODAL_WIDTH}
        onCancel={handleCancel}
        open={visible}
        onOk={handleOk}
        footer={[
          <div key="footer-delete-release">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={handleOk}
              type="primary"
              danger
              loading={loading}
            >
              Okay
            </Button>
          </div>,
        ]}
      >
        <div className="modal-content">
          <div>
            Are you sure you want to cancel
            <b> {PLAN_NAMES?.[selectedAddOn?.subscriptionPlan?.name]}</b>{' '}
            Add-ons?
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelAddOnModal;
