import { LeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Radio, Space, Steps } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { CREATE_DYNAMIC_LINK, UPDATE_DYNAMIC_LINK } from '../graphql/mutations';
import {
  CHECK_LINK_ALIAS,
  GET_DYNAMIC_LINK,
  GET_DYNAMIC_LINK_CONFIG,
} from '../graphql/queries';
import './dynamicLink.less';

const { Step } = Steps;

const CreateNewDynamicLink = () => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { required, url } = formValidatorRules;
  const {
    location: { state },
  } = history;
  const { appId } = useParams();
  const { Item } = Form;
  const [dynamicLinkInfo, setDynamicLinkInfo] = useState(null);
  const [dynamicLinkConfigInfo, setDynamicLinkConfigInfo] = useState(null);
  const [current, setCurrent] = useState(0);
  const [linkBehaviorApple, setLinkBehaviorApple] = useState('apple_browser');
  const [linkBehaviorAndroid, setLinkBehaviorAndroid] = useState(
    'android_browser',
  );
  const [appleStoreUrl, setAppleStoreUrl] = useState('apple_store_url');
  const [androidStoreUrl, setAndroidStoreUrl] = useState('android_store_url');
  const [appleCustomURLValue, setAppleCustomURLValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [androidCustomURLValue, setAndroidCustomURLValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const BackToDynamicLinks = () => {
    history?.push(
      `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/${appId}`,
    );
  };

  const [createDynamicLink] = useMutation(CREATE_DYNAMIC_LINK, {
    onCompleted: BackToDynamicLinks,
    onError() {},
  });

  const [updateDynamicLink] = useMutation(UPDATE_DYNAMIC_LINK, {
    onCompleted: BackToDynamicLinks,
    onError() {},
  });

  const [getDynamicLink, { data: dynamicLinkData }] = useLazyQuery(
    GET_DYNAMIC_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setLoading(false);
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const [getDynamicLinkConfig, { data: dynamicLinkConfigData }] = useLazyQuery(
    GET_DYNAMIC_LINK_CONFIG,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          applicationId: appId,
        },
      },
      onCompleted() {
        setLoading(false);
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const [checkLinkAlias] = useLazyQuery(CHECK_LINK_ALIAS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (state?.dynamicLinkId) {
      setIsEdit(true);
      getDynamicLink({
        variables: {
          where: {
            id: state?.dynamicLinkId,
          },
        },
      });
    }
  }, [state?.dynamicLinkId]);

  useEffect(() => {
    getDynamicLinkConfig();
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dynamicLink = dynamicLinkData?.dynamicLink?.data;
      setDynamicLinkInfo(dynamicLink);
      const {
        shortId,
        name,
        link,
        iosFallbackUrl,
        androidFallbackUrl,
        socialMetaTags,
      } = dynamicLink;
      const formValues = {
        shortId,
        name,
        link,
        'custom-url': iosFallbackUrl,
        'android-custom-url': androidFallbackUrl,
        title: socialMetaTags?.title,
        imageUrl: socialMetaTags?.imageUrl,
        description: socialMetaTags?.description,
      };
      form.setFieldsValue(formValues);
      setLinkBehaviorApple(
        dynamicLink?.isIosApp && dynamicLink?.iosFallbackUrl
          ? 'url_apple_app'
          : 'apple_browser',
      );
      setAppleStoreUrl(
        dynamicLink?.isIosCustomUrl
          ? 'custom_apple_app_url'
          : 'apple_store_url',
      );
      setAppleCustomURLValue(dynamicLink?.iosFallbackUrl);
      setLinkBehaviorAndroid(
        dynamicLink?.isAndroidApp ? 'url_android_app' : 'android_browser',
      );
      setAndroidStoreUrl(
        dynamicLink?.androidFallbackUrl || dynamicLink?.isAndroidCustomUrl
          ? 'custom_android_app_url'
          : 'android_store_url',
      );
      setAndroidCustomURLValue(dynamicLink?.androidFallbackUrl);
    } else {
      const dynamicLinkConfigs = dynamicLinkConfigData?.dynamicLinkConfigs?.[0];
      setDynamicLinkConfigInfo(dynamicLinkConfigs);
      setLinkBehaviorApple(
        dynamicLinkConfigs?.isIosApp ? 'url_apple_app' : 'apple_browser',
      );
      setAppleStoreUrl(
        dynamicLinkConfigs?.isIosCustomUrl
          ? 'custom_apple_app_url'
          : 'apple_store_url',
      );
      setAppleCustomURLValue(dynamicLinkConfigs?.iosFallbackUrl);
      setLinkBehaviorAndroid(
        dynamicLinkConfigs?.isAndroidApp
          ? 'url_android_app'
          : 'android_browser',
      );
      setAndroidStoreUrl(
        dynamicLinkConfigs?.isAndroidCustomUrl
          ? 'custom_android_app_url'
          : 'android_store_url',
      );
      setAndroidCustomURLValue(dynamicLinkConfigs?.androidFallbackUrl);
    }
  }, [dynamicLinkData, dynamicLinkConfigData]);

  const onChangeLinkBehaviorApple = (e) => {
    setLinkBehaviorApple(e.target.value);
  };

  const onChangeLinkBehaviorAndroid = (e) => {
    setLinkBehaviorAndroid(e.target.value);
  };

  const onChangeAppleStoreUrl = (e) => {
    setAppleCustomURLValue(null);
    form.setFieldValue('custom-url', null);
    setAppleStoreUrl(e.target.value);
  };

  const onChangeAndroidStoreUrl = (e) => {
    setAndroidCustomURLValue(null);
    form.setFieldValue('android-custom-url', null);
    setAndroidStoreUrl(e.target.value);
  };

  const onFinish = () => {
    const data = {
      shortId: dynamicLinkInfo?.shortId,
      name: dynamicLinkInfo?.name,
      link: dynamicLinkInfo?.link,
      isOpenInBrowserApple: linkBehaviorApple === 'apple_browser',
      isOpenInBrowserAndroid: linkBehaviorAndroid === 'android_browser',
      isOpenInAndroidApp:
        androidStoreUrl === 'android_store_url' ||
        androidStoreUrl === 'custom_android_app_url',
      customUrlForAndroid:
        androidStoreUrl === 'custom_android_app_url'
          ? androidCustomURLValue
          : null,
      isOpenInIosApp:
        appleStoreUrl === 'apple_store_url' ||
        appleStoreUrl === 'custom_apple_app_url',
      customUrlForIos:
        appleStoreUrl === 'custom_apple_app_url' ? appleCustomURLValue : null,
      socialMetaTags: dynamicLinkInfo?.socialMetaTags,
    };
    if (isEdit) {
      updateDynamicLink({
        variables: {
          where: {
            dynamicLinkId: state?.dynamicLinkId,
          },
          data,
        },
      });
    } else {
      createDynamicLink({
        variables: {
          where: {
            dynamicLinkConfigId: dynamicLinkConfigInfo?.id,
          },
          data,
        },
      });
    }
  };

  const debouncedCheckLinkAlias = useCallback(
    debounce(async (value, callback) => {
      const data = await checkLinkAlias({
        variables: {
          where: {
            dynamicLinkId: state?.dynamicLinkId,
            shortId: value,
          },
        },
      });
      callback(data?.data?.checkLinkAlias?.isAvailable);
    }, 500),
    [],
  );

  const checkUrlSuffix = (value) =>
    new Promise((resolve) => {
      debouncedCheckLinkAlias(value, resolve);
    });

  const checkAliasAvailability = async (_, value) => {
    if (!value) {
      return Promise?.reject(new Error('Please enter the url suffix'));
    }

    const isAvailable = await checkUrlSuffix(value);

    if (!isAvailable) {
      return Promise?.reject(
        new Error('Short URL is invalid or already in use!!'),
      );
    }

    return Promise?.resolve();
  };

  const steps = [
    {
      key: 'STEP_1',
      title: 'Step 1',
      content: (
        <div className="step-1">
          <div className="text-title mb-4">Setup your short URL link</div>
          <p className="text-description m-0">
            Customize your short URL link to make it look more professional.
          </p>
          <div className="d-flex align-center justify-between mt-24">
            <div>
              <Item label="URL prefix" className="mb-4">
                <Input
                  className="url-prefix"
                  value={
                    dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.urlPrefix
                  }
                  type="text"
                  disabled
                />
              </Item>
            </div>
            <div>
              <Item
                label="URL suffix"
                name="shortId"
                className="mb-4"
                rules={[
                  {
                    validator: checkAliasAvailability,
                  },
                ]}
                onChange={({ target }) =>
                  setDynamicLinkInfo({
                    ...dynamicLinkInfo,
                    shortId: target?.value,
                  })
                }
              >
                <Input
                  className="url-suffix"
                  placeholder="Enter url suffix"
                  type="text"
                />
              </Item>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'STEP_2',
      title: 'Step 2',
      content: (
        <div className="step-2">
          <div className="text-title mb-4">Setup your dynamic link</div>
          <p className="text-description m-0">
            A Dynamic Link is a deep link into your app that works whether or
            not your app is installed. On desktop it will go to the deep link
            url.
          </p>
          <div className="mt-24">
            <div>
              <Item
                name="name"
                label="Dynamic link name"
                className="mb-4"
                rules={[
                  {
                    required,
                    message: 'Please enter a link name',
                  },
                ]}
                onChange={({ target }) => {
                  setDynamicLinkInfo({
                    ...dynamicLinkInfo,
                    name: target?.value,
                  });
                }}
              >
                <Input
                  className="url-prefix"
                  placeholder="e.g. Demo-app link"
                  type="text"
                />
              </Item>
            </div>
            <div className="mt-24">
              <Item
                name="link"
                className="mb-4"
                label="Deep link URL"
                rules={[{ required: true }, url()]}
                onChange={({ target }) => {
                  setDynamicLinkInfo({
                    ...dynamicLinkInfo,
                    link: target?.value,
                  });
                }}
              >
                <Input
                  className="url-suffix"
                  placeholder="e.g. http://yourappname.com/home"
                  type="text"
                />
              </Item>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'STEP_3',
      title: 'Step 3',
      content: (
        <div className="step-3">
          <div className="text-title">Define link behavior for Apple</div>
          <div className="mt-24">
            <Radio.Group
              onChange={onChangeLinkBehaviorApple}
              value={linkBehaviorApple}
              disabled={
                isEdit
                  ? !dynamicLinkInfo?.isIosApp
                  : !dynamicLinkConfigInfo?.isIosApp
              }
            >
              <Space direction="vertical" size={16}>
                <Radio value="apple_browser">
                  Open deep link URL in browser
                </Radio>
                {linkBehaviorApple !== 'url_apple_app' && (
                  <Input
                    className="appname"
                    disabled
                    value={
                      isEdit
                        ? dynamicLinkInfo?.defaultWebUrl
                        : dynamicLinkConfigInfo?.defaultWebUrl
                    }
                  />
                )}
                <Radio value="url_apple_app">
                  Open deep link URL in your Apple app
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          {linkBehaviorApple === 'url_apple_app' && (
            <div className="mt-24">
              <Input
                className="appname"
                placeholder="Appname"
                disabled
                value={
                  isEdit
                    ? dynamicLinkInfo?.appstoreUrl
                    : dynamicLinkConfigInfo?.appstoreUrl
                }
              />
              <div className="mt-32">
                <div className="text-title">
                  If your app is not installed, send the user to
                </div>
                <div className="mt-24">
                  <Radio.Group
                    onChange={onChangeAppleStoreUrl}
                    value={appleStoreUrl}
                  >
                    <Space direction="vertical" size={16}>
                      <Radio value="apple_store_url">
                        App store page for your app
                      </Radio>
                      <Radio value="custom_apple_app_url">Custom URL</Radio>
                    </Space>
                  </Radio.Group>
                </div>
                {appleStoreUrl === 'custom_apple_app_url' && (
                  <Item
                    name="custom-url"
                    className="ml-24"
                    rules={[
                      { required, message: 'Please enter Custom URL' },
                      url(),
                    ]}
                  >
                    <Input
                      className="mt-8 appname"
                      placeholder="e.g. https//yoururl.com"
                      value={appleCustomURLValue}
                      onChange={(e) => {
                        setAppleCustomURLValue(e?.target?.value);
                      }}
                    />
                  </Item>
                )}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'STEP_4',
      title: 'Step 4',
      content: (
        <div className="step-4">
          <div className="text-title">Define link behavior for Android</div>
          <div className="mt-24">
            <Radio.Group
              disabled={
                isEdit
                  ? !dynamicLinkInfo?.isAndroidApp
                  : !dynamicLinkConfigInfo?.isAndroidApp
              }
              onChange={onChangeLinkBehaviorAndroid}
              value={linkBehaviorAndroid}
            >
              <Space direction="vertical" size={16}>
                <Radio value="android_browser">
                  Open deep link URL in browser
                </Radio>
                {linkBehaviorAndroid !== 'url_android_app' && (
                  <Input
                    className="appname"
                    disabled
                    value={
                      isEdit
                        ? dynamicLinkInfo?.defaultWebUrl
                        : dynamicLinkConfigInfo?.defaultWebUrl
                    }
                  />
                )}
                <Radio value="url_android_app">
                  Open deep link URL in your Android app
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          {linkBehaviorAndroid === 'url_android_app' && (
            <div className="mt-24">
              <Input
                className="appname"
                placeholder="Appname"
                disabled
                value={
                  isEdit
                    ? dynamicLinkInfo?.playstoreUrl
                    : dynamicLinkConfigInfo?.playstoreUrl
                }
              />
              <div className="mt-32">
                <div className="text-title">
                  If your app is not installed, send the user to
                </div>
                <div className="mt-24">
                  <Radio.Group
                    onChange={onChangeAndroidStoreUrl}
                    value={androidStoreUrl}
                  >
                    <Space direction="vertical" size={16}>
                      <Radio value="android_store_url">
                        Google Play page for your app
                      </Radio>
                      <Radio value="custom_android_app_url">Custom URL</Radio>
                    </Space>
                  </Radio.Group>
                </div>
                {androidStoreUrl === 'custom_android_app_url' && (
                  <Item
                    name="android-custom-url"
                    className="ml-24"
                    rules={[
                      { required, message: 'Please enter Custom URL' },
                      url(),
                    ]}
                  >
                    <Input
                      className="mt-8 appname"
                      placeholder="e.g. https//yoururl.com"
                      value={androidCustomURLValue}
                      onChange={(e) => {
                        setAndroidCustomURLValue(e?.target?.value);
                      }}
                    />
                  </Item>
                )}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'STEP_5',
      title: 'Step 5',
      content: (
        <div className="step-5">
          <div className="text-title mb-4">Add social meta tags</div>
          <p className="text-description m-0">
            When you share a link on social media, it usually displays a preview
            of title, description, and an image.
          </p>
          <div className="d-flex align-center justify-between mt-24 input-1">
            <div>
              <Item
                className="mb-4"
                name="title"
                label="Title"
                rules={[{ required }]}
                onChange={({ target }) => {
                  setDynamicLinkInfo({
                    ...dynamicLinkInfo,
                    socialMetaTags: {
                      ...dynamicLinkInfo?.socialMetaTags,
                      title: target?.value,
                    },
                  });
                }}
              >
                <Input
                  className="url-prefix"
                  placeholder="e.g. Digital Dreams"
                  type="text"
                />
              </Item>
            </div>
            <div>
              <Item
                label="Image URL"
                className="mb-4"
                name="imageUrl"
                rules={[{ required }, url()]}
                onChange={({ target }) => {
                  setDynamicLinkInfo({
                    ...dynamicLinkInfo,
                    socialMetaTags: {
                      ...dynamicLinkInfo?.socialMetaTags,
                      imageUrl: target?.value,
                    },
                  });
                }}
              >
                <Input
                  className="url-suffix"
                  placeholder="e.g. https//website.com/digitaldreams.jpg"
                  type="text"
                />
              </Item>
            </div>
          </div>
          <div className="mt-24">
            <Item
              className="mb-4"
              label="Description"
              name="description"
              rules={[{ required }]}
              onChange={({ target }) => {
                setDynamicLinkInfo({
                  ...dynamicLinkInfo,
                  socialMetaTags: {
                    ...dynamicLinkInfo?.socialMetaTags,
                    description: target?.value,
                  },
                });
              }}
            >
              <Input
                className="url-prefix"
                placeholder="e.g. Embark on a journey of creativity and innovation at Digital Dreams 2023."
                type="text"
              />
            </Item>
          </div>
        </div>
      ),
    },
  ];

  const next = () => {
    if (current === 0) {
      form
        ?.validateFields(['shortId'])
        ?.then(() => setCurrent(current + 1))
        // eslint-disable-next-line no-console
        ?.catch((error) => console?.error('error =>', error));
    } else {
      form
        ?.validateFields(['name', 'link', 'custom-url', 'android-custom-url'])
        ?.then(() => {
          setCurrent(current + 1);
        })
        // eslint-disable-next-line no-console
        ?.catch((error) => console?.error('error =>', error));
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      {loading ? (
        <LoaderComponent />
      ) : (
        <div className="create-dynamic-link-content">
          <div className="apps-content">
            <div className="app-details-inner">
              <div className="back-to-dynamic">
                <LeftOutlined onClick={BackToDynamicLinks} />
                <h1>
                  {state?.dynamicLinkId ? 'Edit link' : 'Create new link'}
                </h1>
              </div>
            </div>
          </div>
          <div className="apps-details-content">
            <div className="preview-link">
              <p>Link preview :</p>
              <Input
                value={`${
                  dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.urlPrefix
                }/${dynamicLinkInfo?.shortId ?? ''}`}
                disabled
              />
            </div>
            <div className="app-details-inner">
              <div className="create-card">
                <Form
                  layout="vertical"
                  initialValues={{
                    ...dynamicLinkInfo,
                    title: dynamicLinkInfo?.socialMetaTags?.title,
                    description: dynamicLinkInfo?.socialMetaTags?.description,
                    imageUrl: dynamicLinkInfo?.socialMetaTags?.imageUrl,
                  }}
                  onFinish={onFinish}
                  form={form}
                >
                  <div className="header dynamic-link-steps">
                    <Steps
                      labelPlacement="vertical"
                      size="small"
                      className="step-bar"
                      current={current}
                    >
                      {steps?.map((item) => (
                        <Step key={item?.key} title={item?.title} />
                      ))}
                    </Steps>
                  </div>
                  <div className="body">{steps?.[current]?.content}</div>
                  <div className="footer">
                    <Space size="16" direction="horizontal" className="m-0">
                      <Item shouldUpdate className="m-0">
                        {() => (
                          <>
                            {current > 0 && (
                              <Button
                                className="previous"
                                onClick={() => prev()}
                                disabled={
                                  form
                                    ?.getFieldsError()
                                    ?.filter(({ errors }) => errors?.length)
                                    ?.length > 0
                                }
                              >
                                Previous
                              </Button>
                            )}
                          </>
                        )}
                      </Item>
                      <Item shouldUpdate className="m-0">
                        {() => (
                          <>
                            {current < steps?.length - 1 && (
                              <Button
                                type="primary"
                                className="next"
                                onClick={() => next()}
                                disabled={
                                  form
                                    ?.getFieldsError()
                                    ?.filter(({ errors }) => errors?.length)
                                    ?.length > 0
                                }
                              >
                                Next
                              </Button>
                            )}
                          </>
                        )}
                      </Item>
                      <Item className="m-0">
                        {current === steps?.length - 1 && (
                          <Button
                            type="primary"
                            className="next"
                            htmlType="submit"
                          >
                            {isEdit ? 'Update' : 'Create'}
                          </Button>
                        )}
                      </Item>
                    </Space>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNewDynamicLink;
