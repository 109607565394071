import { useMutation, useQuery } from '@apollo/client';
import { Button, Result } from 'antd';
import { isMobile, isSafari } from 'mobile-device-detect';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import noLink from '../../assets/svg/no-link.svg';
import {
  DOWNLOAD_IOS_APP_URL,
  GA_EVENT,
  GA_LABEL,
} from '../../common/constants';
import {
  copyToClipboard,
  googleAnalyticsEvents,
  handleGraphQLError,
} from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { UPDATE_DOWNLOAD_COUNT } from '../apps/graphql/mutations';
import DesktopDetailPage from './DesktopDetailPage';
import MobileDetailPage from './MobileDetailPage';
import { GET_APP_DETAIL } from './graphql/queries';

const Detail = ({ password }) => {
  const { subLinkId } = useParams();
  const [linkExpired, setLinkExpired] = useState(false);
  const [errorType, setErrorType] = useState('');

  // eslint-disable-next-line no-undef
  const isMobileScreen = useMemo(() => window?.innerWidth <= 768, []);
  // eslint-disable-next-line no-undef
  const link = useMemo(() => window?.location?.href, []);

  const getAppInput = useMemo(() => ({ subLinkId, password }), [
    subLinkId,
    password,
  ]);

  const { data, loading } = useQuery(GET_APP_DETAIL, {
    fetchPolicy: 'network-only',
    variables: { getAppInput },
    onError(error) {
      handleGraphQLError(error, (state) => {
        if (state.linkExpired) {
          setLinkExpired(true);
        } else {
          setErrorType(state.type);
        }
      });
    },
  });

  const [downloadCounter] = useMutation(UPDATE_DOWNLOAD_COUNT, {
    onError() {},
  });

  const downloadBtn = () => {
    googleAnalyticsEvents(GA_EVENT?.SHARE_APPLICATION_DOWNLOAD, {
      label: GA_LABEL?.SHARE_APPLICATION_DOWNLOAD,
    });
    setTimeout(() => {
      downloadCounter({ variables: { subLinkId } });
    }, 500);
  };

  const copyUniqueId = () => {
    copyToClipboard(link);
    messageContext?.success('Link copied to clipboard');
  };

  const appSize = useMemo(
    () => parseFloat(data?.getApp?.appData?.appSize)?.toFixed(2) || '0.00',
    [data],
  );
  const appStorageUrls = useMemo(() => {
    const { plistStorageUrl, appStorageUrl } = data?.getApp?.appData || {};
    if (isSafari && isMobile && plistStorageUrl) {
      return `${DOWNLOAD_IOS_APP_URL}${plistStorageUrl}`;
    }
    return appStorageUrl;
  }, [data]);

  if (loading) return <LoaderComponent />;

  if (linkExpired) {
    return (
      <div className="empty-no-link-image d-flex justify-center">
        <img src={noLink} alt="no-link" className="empty-image" />
      </div>
    );
  }

  if (errorType === 'LINK_NOT_FOUND') {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    );
  }

  if (errorType === 'SOMETHING_WENT_WRONG') {
    return (
      <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle="Click on reload page button to try again."
        extra={
          // eslint-disable-next-line no-undef
          <Button type="primary" onClick={() => window?.location?.reload()}>
            Reload Page
          </Button>
        }
      />
    );
  }

  const DetailPage = isMobileScreen ? MobileDetailPage : DesktopDetailPage;

  return (
    <DetailPage
      data={data}
      link={link}
      subLinkId={subLinkId}
      copyUniqueId={copyUniqueId}
      appSize={appSize}
      appStorageUrl={appStorageUrls}
      downloadBtn={downloadBtn}
    />
  );
};

export default Detail;
