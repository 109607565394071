import { useLazyQuery } from '@apollo/client';
import { PencilLine, X } from '@phosphor-icons/react';
import MDEditor from '@uiw/react-md-editor';
import { Button, Col, Divider, Drawer, Progress, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { ACTIONS } from '../../../../common/constants';
import {
  convertBytesToMB,
  convertTimeToLocalWithYear,
} from '../../../../common/utils';
import AccessControl from '../../../../components/AccessControl';
import LoaderComponent from '../../../../components/LoaderComponent';
import { APP_DEPLOYMENT_METRICS } from '../graphql/queries';
import EditRelease from './EditRelease';

const ReleasePreview = ({ open, setOpen, selectedRelease, type }) => {
  const {
    state: { applicationName },
  } = useContext(AppContext);
  const [editModal, setEditModal] = useState(false);

  const [getAppDeploymentMetrics, { data, loading }] = useLazyQuery(
    APP_DEPLOYMENT_METRICS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedRelease)
      getAppDeploymentMetrics({
        variables: {
          data: { label: selectedRelease?.label, name: applicationName, type },
        },
      });
  }, [selectedRelease]);

  return (
    <>
      <Drawer
        width="calc(100% - 220px)"
        title={
          <div className="title-block">
            <div>
              <h4 className="release">{selectedRelease?.label}</h4>
              <span className="email">{selectedRelease?.releasedBy}</span>
            </div>
            <div className="d-flex justify-between align-center gap-16">
              <Button type="primary" href={selectedRelease?.blobUrl}>
                Download Bundle
              </Button>
              <AccessControl
                allowedPermissions={ACTIONS?.CODE_PUSH_RELEASE_EDIT}
              >
                <div className="edit-icon" onClick={() => setEditModal(true)}>
                  <PencilLine size={24} />
                </div>
              </AccessControl>
              <div className="close-icon" onClick={onClose}>
                <X size={32} />
              </div>
            </div>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
      >
        {loading ? (
          <LoaderComponent setHeight="300" />
        ) : (
          <Row gutter={[16, 16]}>
            <Col lg={24} xl={6} xxl={7}>
              <div className="details">
                <div className="block">
                  <h4 className="label">Deployment</h4>
                  <p className="text">Production</p>
                </div>
                <div className="block">
                  <h4 className="label">Versions</h4>
                  <p className="text">{selectedRelease?.appVersion}</p>
                </div>
                <div className="block">
                  <h4 className="label">Mandatory</h4>
                  <p className="text">
                    {selectedRelease?.isMandatory ? 'Yes' : 'No'}
                  </p>
                </div>
                <div className="block">
                  <h4 className="label">Released Method</h4>
                  <p className="text">{selectedRelease?.releaseMethod}</p>
                </div>
                <div className="block">
                  <h4 className="label">Size</h4>
                  <p className="text">
                    {convertBytesToMB(selectedRelease?.size)}
                  </p>
                </div>
                <div className="block">
                  <h4 className="label">Status</h4>
                  <p className="text">
                    {selectedRelease?.isDisabled ? 'Disable' : 'Enable'}
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={24} xl={18} xxl={17} className="progress-area">
              <div className="progress-wrapper">
                <div>
                  <h4 className="label mb-16">ROLLOUT</h4>
                  <Progress
                    strokeLinecap="butt"
                    type="circle"
                    percent={selectedRelease?.rollout ?? 100}
                    format={(percent) => `${percent}%`}
                    strokeWidth={10}
                    size={180}
                    strokeColor="#1A1D40"
                  />
                  <div className="totals">
                    <div>
                      <h4 className="label">Roolbacks</h4>
                      <p className="text">
                        {data?.getAppDeploymentMetrics?.metrics?.failed
                          ? data?.getAppDeploymentMetrics?.metrics?.failed
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="label mb-16">ACTIVE</h4>
                  <Progress
                    strokeLinecap="butt"
                    type="circle"
                    percent={
                      (data?.getAppDeploymentMetrics?.metrics?.installed /
                        data?.getAppDeploymentMetrics?.totalInstalled) *
                      100
                    }
                    format={(percent) => {
                      const formattedPercent =
                        Number(percent) % 1 === 0
                          ? Number(percent)
                          : Number(percent).toFixed(2);
                      return (
                        <div>
                          <h4 className="m-0">{formattedPercent}%</h4>
                          {data?.getAppDeploymentMetrics?.metrics?.installed >
                            0 && (
                            <p className="mb-0 mt-8">
                              {
                                data?.getAppDeploymentMetrics?.metrics
                                  ?.installed
                              }
                              /{data?.getAppDeploymentMetrics?.totalInstalled}
                            </p>
                          )}
                        </div>
                      );
                    }}
                    strokeWidth={10}
                    size={180}
                    strokeColor="#1A1D40"
                  />
                  <div className="totals">
                    <div>
                      <h4 className="label">Installs</h4>
                      <p className="text">
                        {data?.getAppDeploymentMetrics?.metrics?.installed
                          ? data?.getAppDeploymentMetrics?.metrics?.installed
                          : '-'}
                      </p>
                    </div>
                    <div>
                      <h4 className="label">Downloads</h4>
                      <p className="text">
                        {data?.getAppDeploymentMetrics?.metrics?.downloaded
                          ? data?.getAppDeploymentMetrics?.metrics?.downloaded
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="details">
                <h5 className="label">Released On:</h5>
                <p className="text">
                  {convertTimeToLocalWithYear(selectedRelease?.uploadTime)}
                </p>
                <h5 className="label mt-16">Released By:</h5>
                <p className="text">{selectedRelease?.releasedBy}</p>
              </div>
              <Divider />
              <div className="details">
                <h5 className="label">Description</h5>
                {selectedRelease?.description ? (
                  <div className="markdown-text">
                    <MDEditor.Markdown
                      source={selectedRelease?.description}
                      style={{ whiteSpace: 'pre-wrap' }}
                    />
                  </div>
                ) : (
                  <p className="text">-</p>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Drawer>
      {editModal && (
        <EditRelease
          visible={editModal}
          setVisible={setEditModal}
          record={selectedRelease}
          type={type}
          handleComplete={() => {
            setOpen(false);
            setEditModal(false);
          }}
        />
      )}
    </>
  );
};

export default ReleasePreview;
