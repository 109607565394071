import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import OnboardingStepper from '../../components/OnboardingStepper';
import Error404 from '../../Error404';
import CreateApp from './CreateApp';
import InviteMembers from './InviteMembers';
import WorkSpace from './WorkSpace';

function OnboardWrapper() {
  return (
    <>
      <OnboardingStepper />

      <Switch>
        <Route
          exact
          path={ROUTES?.ONBOARD}
          render={() => (
            <Redirect to={`${ROUTES?.ONBOARD}${ROUTES?.WORKSPACE}`} />
          )}
        />
        <Route
          path={`${ROUTES?.ONBOARD}${ROUTES?.WORKSPACE}`}
          component={WorkSpace}
        />
        <Route
          path={`${ROUTES?.ONBOARD}${ROUTES?.APP_CREATE}`}
          component={CreateApp}
        />
        <Route
          path={`${ROUTES?.ONBOARD}${ROUTES?.INVITE_MEMBERS}`}
          component={InviteMembers}
        />
        <Route path="*" component={Error404} />
      </Switch>
    </>
  );
}

export default OnboardWrapper;
