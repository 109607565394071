import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import noRelease from '../../../assets/images/no-release.png';
import { SearchIcon } from '../../../assets/svg';
import { LIMIT } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_RELEASE_FEEDBACK } from '../graphql/queries';
import '../release-feedbacks.less';
import FeedbackResolvedModal from './FeedbackResolvedModal';
import VideoThumbnailPreview from './VideoThumbnailPreview';

let searchDebounce = null;
let debounceScroll;

const ReleaseFeedbackModal = ({ releaseRecord, visible, handleVisible }) => {
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [searchData, setSearchData] = useState('');
  const [filterResolved, setFilterResolved] = useState('all');
  const [feedbackTotalCount, setFeedbackTotalCount] = useState(0);
  const [releaseFeedbackData, setReleaseFeedbackData] = useState({});
  const [resolvedFeedbackModal, setResolvedFeedbackModal] = useState(false);
  const [resolvedFeedbackData, setResolvedFeedbackData] = useState({});
  const [infiniteScrollLoading, setInfiniteScrollLoading] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const [getFeedbackRelease] = useLazyQuery(GET_RELEASE_FEEDBACK, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setReleaseFeedbackData(response?.getReleaseFeedback);
      setLoading(false);
      setListLoading(false);
      setInfiniteScrollLoading(false);
      setFeedbackTotalCount(response?.getReleaseFeedback?.feedbackCount);
      if (scrollFlag) {
        setReleaseFeedbackData([
          ...releaseFeedbackData,
          ...response?.getReleaseFeedback?.feedbacks,
        ]);
        setScrollFlag(false);
      } else {
        setReleaseFeedbackData([...response?.getReleaseFeedback?.feedbacks]);
      }
      if (response?.getReleaseFeedback?.feedbacks?.length < LIMIT) {
        setIsEnd(true);
      }
    },
    onError() {},
  });

  useEffect(() => {
    getFeedbackRelease({
      variables: {
        data: {
          appDetailsId: releaseRecord?.id,
          includeResolved: true,
          search: '',
          skip: 0,
          limit: LIMIT,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFeedbackSearch = (value) => {
    setIsEnd(false);
    setSearchData(value);
    setListLoading(true);
    let prepareData = {
      appDetailsId: releaseRecord?.id,
      search: value,
      skip: 0,
      limit: LIMIT,
    };
    if (filterResolved === 'all') {
      prepareData = { ...prepareData, includeResolved: true };
    } else if (filterResolved === 'resolved') {
      prepareData = { ...prepareData, onlyResolved: true };
    } else {
      prepareData = { ...prepareData, includeResolved: false };
    }
    getFeedbackRelease({
      variables: {
        data: prepareData,
      },
    });
  };

  const onFeedbackSearch = ({ target: { value } }) => {
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleFeedbackSearch, 500);
    searchDebounce(value);
  };

  const handleFeedbackStatus = (value) => {
    setIsEnd(false);
    setListLoading(true);
    setFilterResolved(value);
    let prepareData = {
      appDetailsId: releaseRecord?.id,
      search: searchData,
      skip: 0,
      limit: LIMIT,
    };
    if (value === 'all') {
      prepareData = { ...prepareData, includeResolved: true };
    } else if (value === 'resolved') {
      prepareData = { ...prepareData, onlyResolved: true };
    } else {
      prepareData = { ...prepareData, includeResolved: false };
    }
    getFeedbackRelease({
      variables: {
        data: prepareData,
      },
    });
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 15;

      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        setInfiniteScrollLoading(true);
        getFeedbackRelease({
          variables: {
            data: {
              appDetailsId: releaseRecord?.id,
              includeResolved: filterResolved,
              search: searchData,
              skip: releaseFeedbackData?.length,
              limit: LIMIT,
            },
          },
        });
      }
    }, 500);
    debounceScroll();
  };

  const handleCancel = () => {
    handleVisible(false);
  };

  return (
    <div>
      <Modal
        width={1000}
        centered
        title={
          <>
            <span className="feedback-title">Release Feedback</span>
            <span className="device-type">
              {releaseRecord?.appPlatform === 'IOS' ? (
                <AppleFilled size={14} className="ios-color ml-8" />
              ) : (
                <AndroidFilled size={14} className="android-color ml-8" />
              )}
              {`v ${releaseRecord?.appVersion}`}
            </span>
          </>
        }
        footer={null}
        onCancel={handleCancel}
        open={visible}
        destroyOnClose
        wrapClassName="release-feedback-modal"
      >
        <div className="p-10">
          {loading ? (
            <LoaderComponent setHeight={450} />
          ) : (
            <>
              <div className="feedback-content">
                <div className="container mt-6">
                  <div className="d-flex justify-center">
                    <div className="feedbacks">
                      <div className="d-flex justify-between align-center mb-16">
                        <div className="d-flex align-center">
                          <h2 className="m-0 feedback-header">
                            Feedbacks
                            {!listLoading &&
                              feedbackTotalCount > 0 &&
                              ` (${feedbackTotalCount})`}
                          </h2>
                        </div>
                        <div className="d-flex justify-between align-center">
                          <Input
                            placeholder="Search"
                            className="feedback-search-input"
                            allowClear
                            onChange={onFeedbackSearch}
                            suffix={<SearchIcon />}
                          />
                          <div className="filter">
                            <div>Filter by status</div>
                            <Select
                              className="feedback-filter"
                              placeholder="All"
                              name="feedback status"
                              onChange={handleFeedbackStatus}
                              getPopupContainer={(triggerNode) => triggerNode}
                            >
                              <Select.Option value="all">All</Select.Option>
                              <Select.Option value="unresolved">
                                Unresolved
                              </Select.Option>
                              <Select.Option value="resolved">
                                Resolved
                              </Select.Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      {listLoading ? (
                        <LoaderComponent
                          spinning={listLoading}
                          setHeight={300}
                        />
                      ) : (
                        <div
                          className="feedback-card-list"
                          onScroll={handleScroll}
                        >
                          <Row className="feedback-card-row" gutter={[16, 24]}>
                            {releaseFeedbackData?.length !== 0 ? (
                              <>
                                {releaseFeedbackData?.map((item) => (
                                  <Col key={item?.id} span={24}>
                                    <Card className="feedback-card" bordered>
                                      <Row>
                                        <Col span={13}>
                                          <Meta
                                            className="mr-24"
                                            avatar={
                                              <Avatar
                                                size={24}
                                                src={
                                                  item?.creator?.profileImage
                                                }
                                              />
                                            }
                                            title={
                                              <>
                                                <span className="feedback-owner">
                                                  {`${item?.creator?.firstName} ${item?.creator?.lastName}`}
                                                </span>
                                                <span className="time">
                                                  {`${moment(
                                                    item?.createdAt,
                                                  )?.calendar(null, {
                                                    lastDay: '[Yesterday]',
                                                    sameDay: '[Today]',
                                                    lastWeek: '[last] dddd',
                                                    sameElse: 'L',
                                                  })} ${moment(
                                                    item?.createdAt,
                                                  )?.format('LT')}`}
                                                </span>
                                              </>
                                            }
                                            description={
                                              <div className="app-list-info">
                                                {item?.feedback && (
                                                  <span className="mb-8">
                                                    {item?.feedback}
                                                  </span>
                                                )}
                                                <div className="media">
                                                  {item?.feedbackImage && (
                                                    <Image.PreviewGroup>
                                                      <Image
                                                        className="custom-img"
                                                        width={96}
                                                        height={96}
                                                        src={
                                                          item?.feedbackImage
                                                        }
                                                        alt="Feedback Image"
                                                      />
                                                    </Image.PreviewGroup>
                                                  )}
                                                  {item?.feedbackVideo && (
                                                    <VideoThumbnailPreview
                                                      videoUrl={
                                                        item?.feedbackVideo
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            }
                                          />
                                        </Col>
                                        <Col className="text-right" span={11}>
                                          <div className="resolved-by">
                                            {item?.isResolved ? (
                                              <Checkbox
                                                checked={item?.isResolved}
                                                disabled={item?.isResolved}
                                              >
                                                <Tooltip
                                                  title={`Resolved on ${formatDate(
                                                    item?.resolver?.resolvedAt,
                                                  )} by ${
                                                    item?.resolver?.firstName
                                                  } ${
                                                    item?.resolver?.lastName
                                                  }`}
                                                >
                                                  Resolved
                                                </Tooltip>
                                              </Checkbox>
                                            ) : (
                                              <Checkbox
                                                checked={false}
                                                onChange={(e) => {
                                                  if (e?.target?.checked) {
                                                    setResolvedFeedbackData(
                                                      item,
                                                    );
                                                    setResolvedFeedbackModal(
                                                      true,
                                                    );
                                                  }
                                                }}
                                              >
                                                Resolve
                                              </Checkbox>
                                            )}
                                          </div>
                                        </Col>
                                        {item?.isResolved && (
                                          <>
                                            <Divider />
                                            <Row className="feedback-model">
                                              <h2 className="resolved-title">
                                                Resolved comment
                                              </h2>
                                              <Col
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                              >
                                                <Meta
                                                  className="resolver"
                                                  avatar={
                                                    <Avatar
                                                      size={24}
                                                      src={
                                                        item?.resolver
                                                          ?.profileImage
                                                      }
                                                    />
                                                  }
                                                  title={
                                                    <>
                                                      <span className="resolver-title">
                                                        {`${item?.resolver?.firstName} ${item?.resolver?.lastName}`}
                                                      </span>
                                                      <span className="time">
                                                        {`${moment(
                                                          item?.resolver
                                                            ?.resolvedAt,
                                                        )?.calendar(null, {
                                                          lastDay:
                                                            '[Yesterday]',
                                                          sameDay: '[Today]',
                                                          lastWeek:
                                                            '[last] dddd',
                                                          sameElse: 'L',
                                                        })} ${moment(
                                                          item?.resolver
                                                            ?.resolvedAt,
                                                        )?.format('LT')}`}
                                                      </span>
                                                    </>
                                                  }
                                                  description=""
                                                />
                                              </Col>
                                              {item?.isResolved && (
                                                <p className="resolve-comment">
                                                  {
                                                    item?.resolver
                                                      ?.resolveComment
                                                  }
                                                </p>
                                              )}
                                            </Row>
                                          </>
                                        )}
                                      </Row>
                                    </Card>
                                  </Col>
                                ))}
                              </>
                            ) : (
                              <div className="no-release d-flex justify-center align-center mt-35">
                                <div className="no-release-img">
                                  <img
                                    src={noRelease}
                                    alt={noRelease}
                                    width={394}
                                  />
                                  <div className="no-release-content d-flex justify-center flex-vertical align-center">
                                    <Row className="row-app text-center mb-20">
                                      No feedback found.
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            )}
                            <Spin
                              className="feedback-infinite-loading"
                              spinning={infiniteScrollLoading}
                            />
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {resolvedFeedbackModal && (
                <FeedbackResolvedModal
                  resolvedFeedbackData={resolvedFeedbackData}
                  resolvedFeedbackModal={resolvedFeedbackModal}
                  setResolvedFeedbackModal={setResolvedFeedbackModal}
                  onFinish={() => {
                    getFeedbackRelease({
                      variables: {
                        data: {
                          appDetailsId: releaseRecord?.id,
                          includeResolved: false,
                          search: searchData,
                          skip: 0,
                          limit: LIMIT,
                        },
                      },
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ReleaseFeedbackModal;
