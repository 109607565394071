import { useLazyQuery } from '@apollo/client';
import { Button, Carousel, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PlanCoverMaintenance, UpgradeCrown } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import { upgradeToProEvent } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { usePlans } from '../../context/PlanProvider';
import MaintenanceApplication from './MaintenanceApplication';
import MaintenancePreview from './MaintenancePreview';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import { GET_APP_MAINTENANCE_DETAIL } from './graphql/queries';
import './maintenance-services.less';

const MaintenanceServices = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamId, isFreePlan, teamSubscription } = usePlans();
  const history = useHistory();
  const { appId } = useParams();
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [maintenanceCollapse, setMaintenanceCollapse] = useState(null);
  const [maintenanceConfig, setMaintenanceConfig] = useState({});
  const [previewImage, setPreviewImage] = useState();
  const [initialValues, setInitialValues] = useState({
    maintenanceTitle: 'This app is under maintenance',
    maintenanceDescription: '',
    maintenanceImage: null,
    textColorCode: '#585e75',
    backgroundColorCode: '#f2f4f7',
  });
  const [loading, setLoading] = useState(true);

  const [fetchData] = useLazyQuery(GET_APP_MAINTENANCE_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
    },
    onCompleted(res) {
      if (res?.getAppMaintenanceDetail?.isMaintenance) {
        setMaintenanceCollapse(1);
        setInitialValues({
          maintenanceTitle: res?.getAppMaintenanceDetail?.maintenanceTitle,
          maintenanceDescription:
            res?.getAppMaintenanceDetail?.maintenanceDescription,
          maintenanceImage: res?.getAppMaintenanceDetail?.maintenanceImage,
          textColorCode: res?.getAppMaintenanceDetail?.textColorCode,
          backgroundColorCode:
            res?.getAppMaintenanceDetail?.backgroundColorCode,
        });
        setMaintenanceConfig({
          maintenanceTitle: res?.getAppMaintenanceDetail?.maintenanceTitle,
          maintenanceDescription:
            res?.getAppMaintenanceDetail?.maintenanceDescription,
          maintenanceImage: res?.getAppMaintenanceDetail?.maintenanceImage,
          textColorCode: res?.getAppMaintenanceDetail?.textColorCode,
          backgroundColorCode:
            res?.getAppMaintenanceDetail?.backgroundColorCode,
        });
      } else {
        setMaintenanceConfig({
          maintenanceTitle: 'This app is under maintenance',
        });
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    fetchData();
    if (isFreePlan) {
      setProFeatureModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="services-header">
      {loading ? (
        <LoaderComponent spinning setHeight="200" />
      ) : (
        <>
          {isFreePlan && (
            <div className="app-service-pro-banner">
              <div className="description-section">
                <h2>Get the Maintenance mode feature</h2>
                <p>Upgrade to enable updates to your apps.</p>
              </div>
              <div className="button-section">
                <Button
                  className="mr-16"
                  onClick={() => {
                    setProFeatureModalOpen(true);
                  }}
                >
                  See what you get
                </Button>
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    upgradeToProEvent(currentUser, teamSubscription);
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade to pro
                </Button>
              </div>
            </div>
          )}
          <Row wrap={false} className={isFreePlan ? 'fade-feature' : ''}>
            <Col flex="auto" className="maintenance-content-row">
              <MaintenanceApplication
                maintenanceCollapse={maintenanceCollapse}
                setMaintenanceCollapse={setMaintenanceCollapse}
                setMaintenanceConfig={setMaintenanceConfig}
                previewImage={previewImage}
                setPreviewImage={setPreviewImage}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
                fetchData={fetchData}
              />
            </Col>
            <Col>
              <div className="maintenance-content-preview">
                <MaintenancePreview
                  maintenanceCollapse={maintenanceCollapse}
                  maintenanceConfig={maintenanceConfig}
                  previewImage={previewImage}
                  maintenanceImage={initialValues?.maintenanceImage}
                />
              </div>
            </Col>
          </Row>
          {proFeatureModalOpen && (
            <AppServiceProFeatureModal
              title="Enable maintenance mode"
              visible={proFeatureModalOpen}
              setVisible={setProFeatureModalOpen}
              footer={
                <div className="text-center">
                  <Button
                    type="primary"
                    className="upgrade-button"
                    onClick={() => {
                      upgradeToProEvent(currentUser, teamSubscription);
                      history?.push(
                        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                      );
                    }}
                  >
                    <UpgradeCrown />
                    Upgrade to pro
                  </Button>
                </div>
              }
              content={
                <Carousel autoplay draggable>
                  <div className="app-service-carousel">
                    <PlanCoverMaintenance />
                    <div className="text">
                      <p>
                        By enabling Maintenance mode, users won’t be able to
                        access the app and will be noted as the app is under
                        Maintenance mode.
                      </p>
                    </div>
                  </div>
                </Carousel>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default MaintenanceServices;
