import { gql } from '@apollo/client';

export const ADD_CODE_PUSH_APP = gql`
  mutation addCodePushApp($data: AddCodePushAppInput!) {
    addCodePushApp(data: $data) {
      iosAppDeploymentKey
      androidAppDeploymentKey
    }
  }
`;

export const UPDATE_CODE_PUSH_APP = gql`
  mutation updateCodePushApp($data: UpdateCodePushAppInput!) {
    updateCodePushApp(data: $data) {
      message
    }
  }
`;

export const CREATE_APP_DEPLOYMENT_KEY = gql`
  mutation createAppDeploymentKey($data: AppDeploymentKeyInput!) {
    createAppDeploymentKey(data: $data) {
      key
    }
  }
`;

// Will use in future
// export const DELETE_APP_DEPLOYMENT_KEY = gql`
//   mutation deleteAppDeploymentKey($data: AppUpdateInput!) {
//     deleteAppDeploymentKey(data: $data) {
//       data {
//         id
//         appName
//         appLogo
//         createdAt
//       }
//       message
//     }
//   }
// `;

export const APP_COLLABORATORS = gql`
  mutation getAppCollaborators($data: AppUpdateInput!) {
    getAppCollaborators(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;

export const APP_COLLABORATOR_IN_APP = gql`
  mutation addCollaboratorInApp($data: AppUpdateInput!) {
    addCollaboratorInApp(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;

export const TRANSFER_APP_OWNERSHIP = gql`
  mutation transferAppOwnership($data: AppUpdateInput!) {
    transferAppOwnership(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;

export const DELETE_APP_COLLABORATOR_IN_APP = gql`
  mutation deleteCollaboratorInApp($data: AppUpdateInput!) {
    deleteCollaboratorInApp(data: $data) {
      data {
        id
        appName
        appLogo
        createdAt
      }
      message
    }
  }
`;

export const RELEASE_UPDATE_CODE_PUSH_APP = gql`
  mutation releaseUpdateCodePushApp($data: ReleaseUpdateCodePushAppInput!) {
    releaseUpdateCodePushApp(data: $data) {
      message
    }
  }
`;
