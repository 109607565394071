import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import { DocumentCopy } from '../../../assets/svg';
import { copyToClipboard } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_APP } from '../graphql/queries';
import CodePushBanner from './components/CodePushBanner';
import ReleasesTable from './components/ReleasesTable';
import { APP_DEPLOYMENT_HISTORY, APP_DEPLOYMENT_KEY } from './graphql/queries';

const CodePushAndroid = () => {
  const { dispatch } = useContext(AppContext);
  const { appId } = useParams();
  const [loading, setLoading] = useState(true);

  const [getAppDeploymentHistory, { data }] = useLazyQuery(
    APP_DEPLOYMENT_HISTORY,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setLoading(false);
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const [getAppDeploymentKey, { data: manageDeploymentKey }] = useLazyQuery(
    APP_DEPLOYMENT_KEY,
    {
      fetchPolicy: 'network-only',
      onCompleted() {},
      onError() {},
    },
  );

  useQuery(GET_APP, {
    variables: {
      appId,
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_APPLICATION_NAME',
        data: res?.getApplication?.appName,
      });
      getAppDeploymentHistory({
        variables: {
          data: { name: res?.getApplication?.appName, type: 'ANDROID' },
        },
      });
      getAppDeploymentKey({
        variables: {
          data: { name: res?.getApplication?.appName, type: 'ANDROID' },
        },
      });
    },
    onError() {},
  });

  const xmlCode = `
  <string moduleConfig="true" name="CodePushDeploymentKey">DEPLOYMENT_KEY</string>
  <string moduleConfig="true" name="CodePushServerUrl">CODE_PUSH_SERVER_URL</string>
  `;

  const xmlCodeWithKeys = `
  <string moduleConfig="true" name="CodePushDeploymentKey">${manageDeploymentKey?.getAppDeploymentKey?.key}</string>
  <string moduleConfig="true" name="CodePushServerUrl">${process.env.REACT_APP_CODE_PUSH_PROXY_URL}</string>
  `;

  const copy = (code) => {
    copyToClipboard(code);
    messageContext?.success('Code Copied to clipboard');
  };

  if (loading) return <LoaderComponent setHeight="200" />;
  return (
    <div className="code-push-content">
      <CodePushBanner />
      {data?.getAppDeploymentHistory?.data?.length === 0 ? (
        <>
          <div className="connect-github">
            <div>
              <h3 className="mb-16">Android Configuration</h3>
              <p>
                To configure CodePush in your Android project, add the{' '}
                <b>CodePushServerUrl</b> and <b>CodePushDeploymentKey</b> to the{' '}
                <span className="code-span">strings.xml</span> file. This
                ensures that the app can connect to the correct CodePush server
                and deployment environment.
              </p>
            </div>
          </div>
          <div className="configuration">
            <h4 className="mb-16">Steps to Add Configuration:</h4>
            <ol>
              <li>
                Open the file:
                <br />
                <span className="code-span">
                  android/app/src/main/res/values/strings.xml
                </span>
              </li>
              <li>
                Inside the <span className="code-span">{`<resources>`}</span>{' '}
                tag. Add the following lines:
                <div className="code-container">
                  <div className="xml-block">
                    <h6>xml</h6>
                    <DocumentCopy
                      className="pointer"
                      onClick={() => copy(xmlCode)}
                    />
                  </div>
                  <SyntaxHighlighter
                    language="xml"
                    style={atomDark}
                    wrapLongLines
                  >
                    {xmlCode}
                  </SyntaxHighlighter>
                </div>
              </li>
              <li>
                Replace <span className="code-span">DEPLOYMENT_KEY</span> with
                your actual CodePush deployment key.
                <div className="code-container">
                  <div className="xml-block">
                    <h6>xml</h6>
                    <DocumentCopy
                      className="pointer"
                      onClick={() => copy(xmlCodeWithKeys)}
                    />
                  </div>
                  <SyntaxHighlighter
                    language="xml"
                    style={atomDark}
                    wrapLongLines
                  >
                    {xmlCodeWithKeys}
                  </SyntaxHighlighter>
                </div>
              </li>
              <li>Save the file and rebuild the project.</li>
            </ol>
          </div>
        </>
      ) : (
        <ReleasesTable
          data={data?.getAppDeploymentHistory?.data}
          type="ANDROID"
          manageDeploymentKey={manageDeploymentKey?.getAppDeploymentKey?.key}
        />
      )}
    </div>
  );
};

export default CodePushAndroid;
