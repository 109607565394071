import { useMutation } from '@apollo/client';
import {
  Button,
  Carousel,
  Checkbox,
  Form,
  notification,
  Progress,
  Row,
  Upload,
} from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { messageContext } from '../app/components/AppContextHolder';
import androidIcon from '../assets/images/android-icon.png';
import iosIcon from '../assets/images/ios-icon.png';
import {
  PlanCoverAppSize,
  PlanCoverStorage,
  UpgradeCrown,
} from '../assets/svg';
import {
  ALL_APK_UPLOAD,
  ALL_IPA_UPLOAD,
  GA_EVENT,
  GA_LABEL,
  MAX_RELEASE_NOTE_CHARACTER,
  ROUTES,
} from '../common/constants';
import {
  bytesToMB,
  checkStorageUsage,
  googleAnalyticsEvents,
  isFileSizeValidate,
  upgradeToProEvent,
} from '../common/utils';
import { usePlans } from '../context/PlanProvider';
import AppServiceProFeatureModal from '../modules/apps/components/AppServiceProFeatureModal';
import {
  GET_APP_SIGNED_URL,
  RELEASE_UPDATE_JOB_STATUS,
} from '../modules/onboard/graphql/mutation';
import HTMLEditor from './HTMLEditor';
import RouterPrompt from './RouterPrompt';

const { Dragger } = Upload;
const Context = createContext(null);
let temp = axios?.CancelToken?.source();

const CommonUpload = ({ id }) => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const {
    teamSubscription,
    teamPlanUsage,
    teamPlanTotalUsage,
    isFreePlan,
    refetchPlanDetails,
    refetchAddons,
    teamId,
  } = usePlans();
  const [state, setState] = useState({
    selectedFile: {},
    uploading: false,
  });
  const [apkCurrentFile, setApkCurrentFile] = useState(null);
  const [ipaCurrentFile, setIpaCurrentFile] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [defaultShareLink, setDefaultShareLink] = useState(true);
  const [apkFileList, setApkFileList] = useState([]);
  const [ipaFileList, setIpaFileList] = useState([]);
  const [apkPercentCount, setApkPercentCount] = useState(0);
  const [ipaPercentCount, setIpaPercentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [proFeatureStorageModalOpen, setProFeatureStorageModalOpen] = useState(
    false,
  );
  const appUploadSize =
    teamSubscription?.subscriptionPlan?.subscriptionFeatures?.APP_UPLOAD_SIZE ||
    teamSubscription?.subscriptionFeatures?.APP_UPLOAD_SIZE;
  const [api, contextHolder] = notification?.useNotification();

  const openNotification = (placement) => {
    api.error({
      message: `Error`,
      description: `The size exceeds 1 GB!`,
      placement,
    });
  };

  const [generateAppSignedUrl] = useMutation(GET_APP_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const uploadApp = async ({ signedURL, currentFileData, config }) => {
    const uploadedApp = await axios?.put(signedURL, currentFileData, config);
    return uploadedApp;
  };

  const [getJobStatus] = useMutation(RELEASE_UPDATE_JOB_STATUS, {
    onCompleted(data) {
      setState({ ...state, uploading: false });
      if (data?.releaseUpdateJobStatus) {
        setApkPercentCount(100);
        setIpaPercentCount(100);
      }
    },
    onError() {
      setState({ ...state, uploading: false });
    },
  });

  const checkValue = (e) => {
    const isChecked = e?.target?.checked;
    const eventType = isChecked
      ? GA_EVENT?.SELECT_ONE_RELEASE_NOTE
      : GA_EVENT?.DESELECT_ONE_RELEASE_NOTE;
    const label = isChecked
      ? GA_LABEL?.SELECT_ONE_RELEASE_NOTE
      : GA_LABEL?.DESELECT_ONE_RELEASE_NOTE;

    googleAnalyticsEvents(eventType, {
      label,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });

    setCheckboxValue(isChecked);
  };

  const checkDefaultLink = (e) => {
    const isChecked = e?.target?.checked;
    const eventType = isChecked
      ? GA_EVENT?.RESELECT_DEFAULT_SHARE_LINK
      : GA_EVENT?.DESELECT_DEFAULT_SHARE_LINK;
    const label = isChecked
      ? GA_LABEL?.RESELECT_DEFAULT_SHARE_LINK
      : GA_LABEL?.DESELECT_DEFAULT_SHARE_LINK;

    googleAnalyticsEvents(eventType, {
      label,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });

    setDefaultShareLink(isChecked);
  };

  useEffect(() => {
    if (historyLoading) {
      history?.push(
        `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}`,
      );
      setHistoryLoading(false);
    }
  }, [historyLoading]);

  const fileExceeds1GBSize = (file) => {
    const returnValue = isFreePlan && bytesToMB(file) > 1024;
    if (returnValue) {
      openNotification('topRight');
    }
    return returnValue;
  };

  const onChangeApkUpload = (info) => {
    const fileSize = info?.file?.size;
    const fileName = info?.file?.name || '';
    const fileList = info?.fileList || [];
    const fileExt = fileName?.substring(fileName?.lastIndexOf('.') + 1);
    const isApkFile = ALL_APK_UPLOAD?.includes(fileExt);
    const isUrlAbsent = !info?.file?.url;

    if (fileExceeds1GBSize(fileSize)) return;

    if (isFileSizeValidate(fileSize, appUploadSize)) {
      if (
        checkStorageUsage(
          teamPlanUsage?.storage,
          teamPlanTotalUsage?.STORAGE,
          fileSize,
        )
      ) {
        if (isApkFile && isUrlAbsent) {
          setApkFileList([...fileList]);
          setApkCurrentFile(info?.file);
        } else {
          setApkCurrentFile(null);
          messageContext?.destroy();
          messageContext?.error(`${fileName} is not an APK file.`);
        }

        if (fileList?.length === 0) {
          setApkCurrentFile(null);
        }
      } else {
        setProFeatureStorageModalOpen(true);
      }
    } else {
      setProFeatureModalOpen(true);
      messageContext?.error(
        `Chosen file exceeds the maximum size of ${appUploadSize}`,
      );
    }
  };

  const onChangeIpaUpload = (info) => {
    const fileSize = info?.file?.size;
    const fileName = info?.file?.name || '';
    const fileList = info?.fileList || [];
    const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);
    const isIpaFile = ALL_IPA_UPLOAD?.includes(fileExt);
    const isUrlAbsent = !info?.file?.url;

    if (fileExceeds1GBSize(fileSize)) return;

    if (isFileSizeValidate(fileSize, appUploadSize)) {
      if (
        checkStorageUsage(
          teamPlanUsage?.storage,
          teamPlanTotalUsage?.STORAGE,
          fileSize,
        )
      ) {
        if (isIpaFile && isUrlAbsent) {
          setIpaFileList([...fileList]);
          setIpaCurrentFile(info?.file);
        } else {
          setIpaCurrentFile(null);
          messageContext?.destroy();
          messageContext?.error(`${fileName} is not an IPA file.`);
        }

        if (fileList?.length === 0) {
          setIpaCurrentFile(null);
        }
      } else {
        setProFeatureStorageModalOpen(true);
      }
    } else {
      setProFeatureModalOpen(true);
      messageContext?.error(
        `Chosen file exceeds the maximum size of ${appUploadSize}`,
      );
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let apkAppSignedUrlResponse;
      if (apkCurrentFile) {
        apkAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: apkCurrentFile?.name,
              filetype:
                apkCurrentFile?.type ||
                'application/vnd.android.package-archive',
              appId: id,
              fileSize: apkCurrentFile?.size,
            },
          },
        });

        if (apkAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type':
                apkCurrentFile?.type ||
                'application/vnd.android.package-archive',
              'Content-Disposition': `attachment; filename=${apkCurrentFile?.name}`,
              'x-amz-acl': 'public-read',
            },
            cancelToken: temp?.token,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total,
              );
              setApkPercentCount(percentCompleted);
            },
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: apkCurrentFile,
            config,
          });
          googleAnalyticsEvents(GA_EVENT?.APK_RELEASE_UPLOADED, {
            label: GA_LABEL?.APK_RELEASE_UPLOADED,
            member_id: currentUser?.id,
            current_plan_name: teamSubscription?.subscriptionPlan?.name,
            current_plan_type: teamSubscription?.subscriptionPlan?.type,
          });
          if (uploadedAppResponse?.status === 200) {
            await getJobStatus({
              variables: {
                data: {
                  jobId:
                    apkAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId,
                  appId: id,
                  ...(!checkboxValue && {
                    androidNote: values?.androidNote,
                  }),
                  isSpecificNote: !checkboxValue,
                  ...(checkboxValue && {
                    oneNote: values?.oneReleaseNote,
                  }),
                  isDefaultShareableLink: defaultShareLink,
                },
              },
            });
            if (values?.androidNote) {
              googleAnalyticsEvents(GA_EVENT?.APK_RELEASE_NOTE_ADDED, {
                label: GA_LABEL?.APK_RELEASE_NOTE_ADDED,
                member_id: currentUser?.id,
                current_plan_name: teamSubscription?.subscriptionPlan?.name,
                current_plan_type: teamSubscription?.subscriptionPlan?.type,
              });
            }
          }
        }
      }
      let ipaAppSignedUrlResponse;
      if (ipaCurrentFile) {
        let ipaJobId = null;
        ipaAppSignedUrlResponse = await generateAppSignedUrl({
          variables: {
            data: {
              filename: ipaCurrentFile?.name,
              filetype: 'application/octet-stream',
              appId: id,
              fileSize: ipaCurrentFile?.size,
            },
          },
        });

        if (ipaAppSignedUrlResponse?.data) {
          const config = {
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': `attachment; filename=${ipaCurrentFile?.name}`,
              'x-amz-acl': 'public-read',
            },
            cancelToken: temp?.token,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math?.round(
                (progressEvent?.loaded * 100) / progressEvent?.total,
              );
              setIpaPercentCount(percentCompleted);
            },
          };

          const uploadedAppResponse = await uploadApp({
            signedURL:
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.signedURL,
            currentFileData: ipaCurrentFile,
            config,
          });
          googleAnalyticsEvents(GA_EVENT?.IPA_RELEASE_UPLOADED, {
            label: GA_LABEL?.IPA_RELEASE_UPLOADED,
            member_id: currentUser?.id,
            current_plan_name: teamSubscription?.subscriptionPlan?.name,
            current_plan_type: teamSubscription?.subscriptionPlan?.type,
          });
          if (uploadedAppResponse?.status === 200)
            ipaJobId =
              ipaAppSignedUrlResponse?.data?.generateAppSignedUrl?.jobId;

          await getJobStatus({
            variables: {
              data: {
                jobId: ipaJobId,
                appId: id,
                ...(!checkboxValue && {
                  iosNote: values?.iosNote,
                }),
                isSpecificNote: !checkboxValue,
                ...(checkboxValue && {
                  oneNote: values?.oneReleaseNote,
                }),
                isDefaultShareableLink: defaultShareLink,
              },
            },
          });
          if (values?.iosNote) {
            googleAnalyticsEvents(GA_EVENT?.IPA_RELEASE_NOTE_ADDED, {
              label: GA_LABEL?.IPA_RELEASE_NOTE_ADDED,
              member_id: currentUser?.id,
              current_plan_name: teamSubscription?.subscriptionPlan?.name,
              current_plan_type: teamSubscription?.subscriptionPlan?.type,
            });
          }
        }
      }
      if (apkCurrentFile && ipaCurrentFile) {
        googleAnalyticsEvents(GA_EVENT?.BOTH_RELEASE_UPLOADED, {
          label: GA_LABEL?.BOTH_RELEASE_UPLOADED,
          member_id: currentUser?.id,
          current_plan_name: teamSubscription?.subscriptionPlan?.name,
          current_plan_type: teamSubscription?.subscriptionPlan?.type,
        });
      }
      setLoading(false);
      // kept for future use
      // setTeamId(teamId)
      if (apkAppSignedUrlResponse?.data || ipaAppSignedUrlResponse?.data) {
        if (defaultShareLink) {
          dispatch({
            type: 'SET_APP_RELEASE_DEFAULT_LINK',
            data: true,
          });
        }
        setHistoryLoading(true);
      }
      refetchPlanDetails();
      refetchAddons();
    } catch (error) {
      return error;
    }
  };

  const handleUpdateCancel = () => {
    if (temp) {
      temp.cancel();
      temp = axios?.CancelToken?.source();
    }
  };

  return (
    <Context.Provider>
      {contextHolder}
      <Row justify="center" className="onboard-container mb-30">
        <RouterPrompt when={loading} handleUpdateCancel={handleUpdateCancel} />
        <Form form={form} onFinish={handleSubmit}>
          <fieldset className="fieldset" disabled={loading}>
            <Row gutter={15} className="d-flex justify-center align-center">
              <Form.Item>
                <div className="dragger-parent h-158 mr-15">
                  <Dragger
                    accept=".apk"
                    beforeUpload={() => false}
                    onChange={onChangeApkUpload}
                    fileList={apkFileList}
                    maxCount={1}
                    className="dragger-inner p-10 h-158"
                    showUploadList={{ showRemoveIcon: !loading }}
                  >
                    <div className="mb-18">
                      <img src={androidIcon} alt="android-icon" />
                    </div>

                    {apkPercentCount > 0 && (
                      <div className="android-content">
                        {apkPercentCount === 100 ? (
                          <center />
                        ) : (
                          <Progress
                            type="line"
                            size="small"
                            percent={apkPercentCount}
                            className="apk-progress-bar"
                          />
                        )}
                      </div>
                    )}
                    {!apkCurrentFile && (
                      <div>
                        <span className="content-text">
                          Click or Drag & Drop files here (.apk)
                        </span>
                      </div>
                    )}
                  </Dragger>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="dragger-parent h-158">
                  <Dragger
                    accept=".ipa"
                    beforeUpload={() => false}
                    onChange={onChangeIpaUpload}
                    fileList={ipaFileList}
                    maxCount={1}
                    className="dragger-inner h-158 p-10"
                    showUploadList={{ showRemoveIcon: !loading }}
                  >
                    <div className="mb-20">
                      <img src={iosIcon} alt="ios-icon" />
                    </div>

                    {ipaPercentCount > 0 && (
                      <div className="android-content">
                        {ipaPercentCount === 100 ? (
                          <center />
                        ) : (
                          <Progress
                            type="line"
                            size="small"
                            percent={ipaPercentCount}
                          />
                        )}
                      </div>
                    )}
                    {!ipaCurrentFile && (
                      <div className="content-text">
                        <span>Click or Drag & Drop files here (.ipa)</span>
                      </div>
                    )}
                  </Dragger>
                </div>
              </Form.Item>
            </Row>
            <Row gutter={15} className="justify-between">
              <Form.Item className="checkbox">
                <Checkbox onChange={checkValue} name="isSpecificNote">
                  One Release Note
                </Checkbox>
              </Form.Item>
              <Form.Item className="checkbox">
                <Checkbox
                  onChange={checkDefaultLink}
                  name="isDefaultShareableLink"
                  checked={defaultShareLink}
                >
                  Default Share Link
                </Checkbox>
              </Form.Item>
            </Row>
            <Row gutter={15} className="d-flex justify-between align-center">
              {checkboxValue === false ? (
                <>
                  <Form.Item
                    name="androidNote"
                    className="b-8 width-percent-48"
                    rules={[
                      {
                        max: MAX_RELEASE_NOTE_CHARACTER,
                        message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`,
                      },
                    ]}
                  >
                    <HTMLEditor className="html-editor" />
                  </Form.Item>

                  <Form.Item
                    name="iosNote"
                    className="b-8 width-percent-48"
                    rules={[
                      {
                        max: MAX_RELEASE_NOTE_CHARACTER,
                        message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`,
                      },
                    ]}
                  >
                    <HTMLEditor className="html-editor" />
                  </Form.Item>
                </>
              ) : (
                <div className="full-width b-8">
                  <Form.Item
                    name="oneReleaseNote"
                    rules={[
                      {
                        max: MAX_RELEASE_NOTE_CHARACTER,
                        message: `Please Enter Max ${MAX_RELEASE_NOTE_CHARACTER} Characters`,
                      },
                    ]}
                  >
                    <HTMLEditor className="html-editor" />
                  </Form.Item>
                </div>
              )}
            </Row>

            <div className="step-action mt-20 d-flex justify-center">
              <Form.Item shouldUpdate>
                <Button
                  htmlType="submit"
                  className="upload-btn"
                  type="primary"
                  disabled={isEmpty(apkCurrentFile || ipaCurrentFile)}
                  loading={loading}
                >
                  Upload
                </Button>
              </Form.Item>
            </div>
          </fieldset>
        </Form>
        {proFeatureModalOpen && (
          <AppServiceProFeatureModal
            title="App upload size"
            visible={proFeatureModalOpen}
            setVisible={setProFeatureModalOpen}
            footer={
              <div className="text-center">
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    upgradeToProEvent(currentUser, teamSubscription);
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade to pro
                </Button>
              </div>
            }
            content={
              <Carousel autoplay draggable>
                <div className="app-service-carousel">
                  <PlanCoverAppSize />
                  <div className="text">
                    <p>
                      Upgrade to our Pro plan to enjoy larger file sizes beyond
                      the 300MB limit in the Free plan. With the Pro plan,
                      you'll have a generous 1GB limit. Upgrade now to unlock
                      this feature!
                    </p>
                  </div>
                </div>
              </Carousel>
            }
          />
        )}
        {proFeatureStorageModalOpen && (
          <AppServiceProFeatureModal
            title="Storage"
            visible={proFeatureStorageModalOpen}
            setVisible={setProFeatureStorageModalOpen}
            footer={
              <div className="d-flex justify-center gap-24">
                {isFreePlan && (
                  <Button
                    type="primary"
                    className="upgrade-button"
                    onClick={() => {
                      upgradeToProEvent(currentUser, teamSubscription);
                      history?.push(
                        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                      );
                    }}
                  >
                    <UpgradeCrown />
                    Upgrade to pro
                  </Button>
                )}
                <Button
                  className="add-ons-upgrade-button"
                  onClick={() => {
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/${teamId}`,
                    );
                  }}
                >
                  Purchase add-ons
                </Button>
              </div>
            }
            content={
              <Carousel autoplay draggable>
                <div className="app-service-carousel">
                  <PlanCoverStorage />
                  <div className="text">
                    {isFreePlan ? (
                      <p>
                        For storage needs beyond 550 MB, consider upgrading to
                        our Pro plan or purchasing the add-ons. With the Pro
                        plan, you'll have access to a substantial 10 GB storage
                        capacity. Upgrade now to enjoy expanded storage options!
                      </p>
                    ) : (
                      <p>
                        Your storage is exhausted, please purchase an add-ons to
                        expand your storage capacity to 10GB!
                      </p>
                    )}
                  </div>
                </div>
              </Carousel>
            }
          />
        )}
      </Row>
    </Context.Provider>
  );
};

export default CommonUpload;
