import {
  AndroidFilled,
  AppleFilled,
  DeleteOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PlusOutlined,
  ShareAltOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Carousel,
  Checkbox,
  Dropdown,
  Row,
  Select,
  Tooltip,
} from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import ReleaseNotes from '../../assets/images/release-notes.png';
import { EditIcon, PlanCoverLink, UpgradeCrown } from '../../assets/svg';
import documentCopy from '../../assets/svg/document-copy.svg';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import {
  checkTesterAccess,
  copyToClipboard,
  googleAnalyticsEvents,
} from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import TesterAccessModal from '../../components/TesterAccessModal';
import { usePlans } from '../../context/PlanProvider';
import LatestRelease from './LatestRelease';
import NoReleases from './NoRelease';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import BuildShareModal from './components/BuildShareModal';
import DeleteReleaseModal from './components/DeleteReleaseModal';
import ReleaseFeedbackModal from './components/ReleaseFeedbackModal';
import ViewAllShareLinks from './components/ViewAllShareLinks';
import ViewPermissionModal from './components/ViewPermissionModal';
import ViewProvisionalModal from './components/ViewProvisionalModal';
import ViewReleaseNoteModal from './components/ViewReleaseNoteModal';
import {
  DELETE_RELEASE,
  DELETE_SHARABLE_LINK,
  UPDATE_DOWNLOAD_COUNT,
} from './graphql/mutations';
import {
  GET_APP,
  GET_APP_RELEASE_DETAILS,
  GET_SHARABLE_LINKS,
} from './graphql/queries';
import './style/app-details.less';

function getQueryParams() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const AppDetails = () => {
  const { appId } = useParams();
  const history = useHistory();
  const query = getQueryParams();
  const [state, setState] = useState({
    skip: 0,
    limit: 10,
    platform: 'All',
    paginationProp: { total: 0, current: 1 },
    appBuild: null,
    selectedPlatform: '',
    appVersion: null,
    loading: true,
    permissions: '',
  });
  const [releaseModal, setReleaseModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [provisionalModal, setProvisionalModal] = useState(false);
  const [viewShareLinksModal, setViewShareLinksModal] = useState(false);
  const [releaseFeedbackModal, setReleaseFeedbackModal] = useState(false);
  const [deleteAppModal, setDeleteAppModal] = useState(false);
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [editBuildShareModal, setEditBuildShareModal] = useState(false);
  const [buildShareModal, setBuildShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [viewReleaseRecord, setViewReleaseRecord] = useState();
  const [appIsResolve, setAppIsResolve] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState('');
  const [releaseCreatedBy, setReleaseCreatedBy] = useState();
  const [rec, setRec] = useState(null);
  const [response, setResponse] = useState(null);
  const [refetchType, setRefetchType] = useState(null);
  const [testerModal, setTesterModal] = useState(false);

  const {
    dispatch,
    state: { currentUser, releaseDefaultLink },
  } = useContext(AppContext);
  const {
    teamSubscription,
    refetchPlanDetails,
    refetchAddons,
    setTeamId,
    teamId,
    currentRole,
  } = usePlans();
  useEffect(() => {
    if (appId) {
      dispatch({
        type: 'SET_APPLICATION_ID',
        data: appId,
      });
    }
    if (query?.get('teamId')) {
      setTeamId(query?.get('teamId'));
      history?.replace(history?.location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editShareRelease = (editBuildDetail) => {
    setBuildShareModal(true);
    setEditBuildShareModal(true);
    setViewShareLinksModal(false);
    setResponse(editBuildDetail);
    setRec(viewReleaseRecord);
    setRec(viewReleaseRecord);
  };

  const applicationDetails = {
    id: appId,
    skip: state?.skip,
    limit: state?.limit,
    platform: state?.platform,
    includeArchived: appIsResolve,
  };

  const getSharableData = {
    uniqueId: selectedRow,
    skip: state?.skip,
    limit: state?.limit,
  };

  const [
    getSharableLinks,
    { data: shareLinksData, loading: ShareLinksLoading },
  ] = useLazyQuery(GET_SHARABLE_LINKS, {
    fetchPolicy: 'network-only',
    variables: {
      data: getSharableData,
    },
    onError(error) {
      return error;
    },
  });

  const {
    data: getApplicationDetails,
    loading: getApplicationDetailsLoading,
    refetch,
  } = useQuery(GET_APP_RELEASE_DETAILS, {
    variables: {
      data: applicationDetails,
    },
    fetchPolicy: 'network-only',
    onError() {
      setState((prev) => ({ ...prev, loading: false }));
    },
    onCompleted: (res) => {
      if (releaseDefaultLink) {
        const getApplicationsReleaseDetails =
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0];
        setSelectedRow(
          res?.getApplicationsReleaseDetails?.applicationData
            ?.applicationDetails?.[0]?.applicationUniqueId,
        );
        getSharableLinks({
          variables: {
            data: {
              ...getSharableData,
              uniqueId: getApplicationsReleaseDetails?.applicationUniqueId,
            },
          },
        });
        setViewReleaseRecord(getApplicationsReleaseDetails);
        setState((prev) => ({
          ...prev,
          selectedPlatform: getApplicationsReleaseDetails?.appPlatform,
          appVersion: getApplicationsReleaseDetails?.appVersion,
          appBuild: getApplicationsReleaseDetails?.appBuild,
          appPlatform: getApplicationsReleaseDetails?.appPlatform,
        }));
        setViewShareLinksModal(true);
        dispatch({
          type: 'SET_APP_RELEASE_DEFAULT_LINK',
          data: false,
        });
      }
      const pagination = {
        ...state?.paginationProp,
        total:
          state?.platform === 'All'
            ? res?.getApplicationsReleaseDetails?.totalReleasesCount
            : res?.length,
      };
      setState((prev) => ({
        ...prev,
        loading: false,
        paginationProp: pagination,
      }));
    },
  });

  const shareRelease = (buildDetail) => {
    if (checkTesterAccess(currentRole, ACTIONS?.APP_SHARE)) {
      googleAnalyticsEvents(GA_EVENT?.RELEASE_SHARE, {
        label: GA_LABEL?.RELEASE_SHARE,
        member_id: currentUser?.id,
        application_id:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.id,
        application_name:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.appName,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      setBuildShareModal(true);
      setRec(buildDetail);
    } else {
      setTesterModal(true);
    }
  };

  const newRelease = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.APP_RELEASE)) {
      googleAnalyticsEvents(GA_EVENT?.NEW_RELEASE, {
        label: GA_LABEL?.NEW_RELEASE,
        member_id: currentUser?.id,
        application_id:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.id,
        application_name:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.appName,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      const path = `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${appId}${ROUTES?.NEW_RELEASE}`;
      history?.push(path);
    } else {
      setTesterModal(true);
    }
  };

  const [updateDownloadCount] = useMutation(UPDATE_DOWNLOAD_COUNT, {
    onCompleted: () => {
      if (refetch) {
        refetch();
      }
    },
    onError(error) {
      return error;
    },
  });

  const [deleteSharableLink] = useMutation(DELETE_SHARABLE_LINK, {
    onError(error) {
      return error;
    },
  });

  const [deleteRelease] = useMutation(DELETE_RELEASE, {
    variables: {
      uniqueId: selectedRow,
    },
    onCompleted: () => {
      googleAnalyticsEvents(GA_EVENT?.RELEASE_DELETE, {
        label: GA_LABEL?.RELEASE_DELETE,
        member_id: currentUser?.id,
        application_id:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.id,
        application_name:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.appName,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      if (refetch) {
        refetch();
        setRefetchType(state?.selectedPlatform);
      }

      refetchPlanDetails();
      refetchAddons();
    },
    onError(error) {
      return error;
    },
    refetchQueries: [
      {
        query: GET_APP_RELEASE_DETAILS,
        variables: {
          data: applicationDetails,
        },
      },
    ],
  });

  const { data: getAppDetails, loading: getAppDetailsLoading } = useQuery(
    GET_APP,
    {
      fetchPolicy: 'network-only',
      variables: {
        appId,
      },
      onError(error) {
        return error;
      },
    },
  );

  const deleteSharable = (linkId) => {
    deleteSharableLink({
      variables: {
        uniqueId: selectedRow,
        subLinkId: linkId,
      },
      refetchQueries: [
        {
          query: GET_SHARABLE_LINKS,
          variables: {
            data: getSharableData,
          },
        },
      ],
    });
  };

  const copyLink = (link) => {
    googleAnalyticsEvents(GA_EVENT?.RELEASE_SHARE_LINK_COPIED, {
      label: GA_LABEL?.RELEASE_SHARE_LINK_COPIED,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    copyToClipboard(link);
    messageContext?.success('Link Copied to Clipboard');
  };

  const modalColumns = [
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      className: 'link',
      width: '50%',
      render: (recordId, record) => {
        const isPrivate = record?.isPrivate ? <LockOutlined /> : '';
        const link = record?.link;
        return (
          <div className="link-details d-flex justify-center align-center flex-horizontal">
            {isPrivate}
            <div className="link-text">{link}</div>
            <img
              src={documentCopy}
              alt="copy-icon"
              onClick={() => copyLink(link)}
              className="pointer"
            />
          </div>
        );
      },
    },
    {
      title: 'Expiry',
      dataIndex: 'remainingDays',
      key: 'remainingDays',
      className: 'expiry',
      width: '20%',
      render: (recordId, record) => {
        const remainingDays = record?.remainingDays;

        const start = moment();
        const end = moment()?.add(remainingDays, 'days');

        const months = end?.diff(start, 'months');
        start?.add(months, 'months');

        const days = end?.diff(start, 'days');

        const tooltip = (
          <div className="tooltip">
            {record?.remainingDays <= 7 && record?.remainingDays > 0 && (
              <Tooltip
                title={`Share link expires in ${record?.remainingDays} days`}
              >
                <InfoCircleOutlined className="ml-8" />
              </Tooltip>
            )}
          </div>
        );

        return (
          <div className="d-flex flex-start align-center expiry-details">
            <span>
              {months >= 1 && days >= 0 ? (
                <>
                  {days === 0 && <span>{months} months</span>}
                  {days !== 0 && (
                    <span>
                      {months} months {days} days
                    </span>
                  )}
                </>
              ) : (
                <span>{days} days</span>
              )}
            </span>
            <span className="tooltip">{tooltip}</span>
          </div>
        );
      },
    },
    ...(AccessControl({ allowedPermissions: ACTIONS?.APP_SHARE })
      ? [
          {
            title: ' ',
            dataIndex: 'action',
            key: 'action',
            className: 'action',
            width: '10%',
            render: (recordId, record) => (
              <div className="action-btn d-flex justify-around align-center">
                <EditIcon
                  className="pointer"
                  onClick={() => editShareRelease(record)}
                />
                <DeleteOutlined
                  className="danger"
                  onClick={() => deleteSharable(record?.subLinkId)}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const handlePagination = (pagination) => {
    const { current } = pagination;
    const skipNumber = (current - 1) * pagination?.pageSize;

    if (!_isEmpty(pagination)) {
      setState((prev) => ({
        ...prev,
        skip: skipNumber,
        limit: pagination?.pageSize,
        paginationProp: { ...state?.paginationProp, ...pagination },
      }));
    }
    setState((prev) => ({
      ...prev,
      skip: skipNumber,
      paginationProp: { ...state?.paginationProp, ...pagination },
    }));
  };

  const handleFilterChange = (key) => {
    const platformMap = {
      ANDROID: {
        platformName: 'ANDROID',
        event: GA_EVENT?.ANDROID_FILTER_USED,
        label: GA_LABEL?.ANDROID_FILTER_USED,
      },
      IOS: {
        platformName: 'IOS',
        event: GA_EVENT?.IOS_FILTER_USED,
        label: GA_LABEL?.IOS_FILTER_USED,
      },
      DEFAULT: {
        platformName: 'All',
        event: GA_EVENT?.ALL_FILTER_USED,
        label: GA_LABEL?.ALL_FILTER_USED,
      },
    };

    const { platformName, event, label } =
      platformMap?.[key] || platformMap?.DEFAULT;

    setState((prev) => ({ ...prev, skip: 0, platform: platformName }));
    googleAnalyticsEvents(event, {
      label,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
  };

  const handleFilterIsResolve = ({ target: { checked } }) => {
    if (checked) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_SHOW_ARCHIVED, {
        label: GA_LABEL?.CLICK_SHOW_ARCHIVED,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
    }
    setAppIsResolve(checked);
    setState((prev) => ({
      ...prev,
      skip: 0,
    }));
  };

  const updateDownloadCountFunc = (record) => {
    try {
      googleAnalyticsEvents(GA_EVENT?.RELEASE_DOWNLOAD, {
        label: GA_LABEL?.RELEASE_DOWNLOAD,
        member_id: currentUser?.id,
        application_id:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.id,
        application_name:
          getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
            ?.appName,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      setTimeout(() => {
        updateDownloadCount({
          variables: { applicationDetailId: record?.id },
        });
        if (record?.appPlatform === 'ANDROID') {
          setRefetchType('ANDROID');
        } else {
          setRefetchType('IOS');
        }
      }, 500);
    } catch (error) {
      return error;
    }
  };

  const items = [
    {
      key: 'release',
      label: (
        <div className="menu-releases d-flex align-center">
          <img alt="release-notes" src={ReleaseNotes} />
          &nbsp; View release note
        </div>
      ),
      onClick: () => {
        googleAnalyticsEvents(GA_EVENT?.RELEASE_VIEW_RELEASE_NOTE, {
          label: GA_LABEL?.RELEASE_VIEW_RELEASE_NOTE,
          member_id: currentUser?.id,
          application_id:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.id,
          application_name:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.appName,
          current_plan_name: teamSubscription?.subscriptionPlan?.name,
          current_plan_type: teamSubscription?.subscriptionPlan?.type,
        });
        setReleaseModal(true);
      },
    },
    {
      key: 'divider-1',
      type: 'divider',
      className: 'ellipsis-menu-divider',
    },
    state?.selectedPlatform === 'ANDROID'
      ? {
          key: 'permissions',
          label: 'Permissions',
          onClick: () => {
            googleAnalyticsEvents(GA_EVENT?.RELEASE_PERMISSION, {
              label: GA_LABEL?.RELEASE_PERMISSION,
              member_id: currentUser?.id,
              application_id:
                getApplicationDetails?.getApplicationsReleaseDetails
                  ?.applicationData?.id,
              application_name:
                getApplicationDetails?.getApplicationsReleaseDetails
                  ?.applicationData?.appName,
              current_plan_name: teamSubscription?.subscriptionPlan?.name,
              current_plan_type: teamSubscription?.subscriptionPlan?.type,
            });
            setPermissionModal(true);
          },
        }
      : {
          key: 'provisional',
          label: 'Provisional devices',
          onClick: () => {
            setPermissionModal(true);
          },
        },
    {
      key: 'shareLinks',
      label: 'View all share links',
      onClick: () => {
        googleAnalyticsEvents(GA_EVENT?.RELEASE_VIEW_ALL_SHARE_LINKS, {
          label: GA_LABEL?.RELEASE_VIEW_ALL_SHARE_LINKS,
          member_id: currentUser?.id,
          application_id:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.id,
          application_name:
            getApplicationDetails?.getApplicationsReleaseDetails
              ?.applicationData?.appName,
          current_plan_name: teamSubscription?.subscriptionPlan?.name,
          current_plan_type: teamSubscription?.subscriptionPlan?.type,
        });
        getSharableLinks();
        setViewShareLinksModal(true);
      },
    },
    ...(AccessControl({ allowedPermissions: ACTIONS?.DELETE_RELEASE }) ||
    currentUser?.id === releaseCreatedBy
      ? [
          {
            key: 'divider-2',
            type: 'divider',
            className: 'ellipsis-menu-divider',
          },
          {
            key: 'deleteApp',
            label: (
              <>
                <DeleteOutlined className="mr-8" /> <span>Delete release</span>
              </>
            ),
            onClick: () => {
              googleAnalyticsEvents(GA_EVENT?.RELEASE_DELETE_INITIATED, {
                label: GA_LABEL?.RELEASE_DELETE_INITIATED,
                member_id: currentUser?.id,
                application_id:
                  getApplicationDetails?.getApplicationsReleaseDetails
                    ?.applicationData?.id,
                application_name:
                  getApplicationDetails?.getApplicationsReleaseDetails
                    ?.applicationData?.appName,
                current_plan_name: teamSubscription?.subscriptionPlan?.name,
                current_plan_type: teamSubscription?.subscriptionPlan?.type,
              });
              setDeleteAppModal(true);
            },
            className: 'delete',
            danger: true,
          },
        ]
      : []),
  ];

  const handleDropdownClick = (record) => {
    setViewReleaseRecord(record);
    setState((prev) => ({
      ...prev,
      selectedPlatform: record?.appPlatform,
      appVersion: record?.appVersion,
      appBuild: record?.appBuild,
      permissions:
        record?.appPlatform === 'ANDROID'
          ? record?.appMetadata?.permissions
          : record?.appMetadata?.provisionedDevices,
    }));
    setReleaseCreatedBy(record?.createdBy);
    setSelectedRow(record?.applicationUniqueId);
    if (!record?.isSpecificNote) {
      setReleaseNotes(record?.oneReleaseNote);
    } else {
      setReleaseNotes(
        record?.appPlatform === 'ANDROID'
          ? record?.androidReleaseNote
          : record?.iosReleaseNote,
      );
    }
  };

  const columns = [
    {
      title: ' ',
      dataIndex: 'icon',
      key: 'appPlatform',
      className: 'app-platform',
      render: (recordId, record) => {
        const icon =
          record?.appPlatform === 'ANDROID' ? (
            <AndroidFilled className="android-color" />
          ) : (
            <AppleFilled className="ios-color" />
          );
        return icon;
      },
    },
    {
      title: 'Version',
      dataIndex: 'appVersion',
      key: 'appVersion',
      className: 'version',
      render: (recordId, record) => {
        const lock = record?.isPrivate && <LockOutlined />;
        const app = record?.appVersion;

        return (
          <div>
            <span>{app}</span>
            {lock && (
              <span>
                <Tooltip title="Private">{lock}</Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Build',
      dataIndex: 'appBuild',
      key: 'appBuild',
    },
    {
      title: 'Downloads',
      dataIndex: 'downloads',
      key: 'downloads',
    },
    {
      title: 'Uploaded on',
      key: 'createdAt',
      render: (record) => (
        <div>
          <span>{record?.createdAt?.split('T')?.[0]}</span>
        </div>
      ),
    },
    {
      title: ' ',
      dataIndex: 'id',
      key: 'id',
      className: 'action',

      render: (recordId, record) => (
        <div className="action-col">
          {record?.isArchived ? (
            <Tooltip title="Archived">
              <Button className="archived">
                <LockOutlined />
              </Button>
            </Tooltip>
          ) : (
            <>
              <div className="hover-btn">
                <Tooltip title="Download">
                  <Button
                    className="download-hover-btn"
                    href={record?.appStorageUrl}
                    onClick={() => updateDownloadCountFunc(record)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={record?.linkCheck === 1 ? 'Reshare' : 'Share'}>
                  <Button
                    className="download-hover-btn"
                    onClick={() => {
                      shareRelease(record);
                    }}
                  >
                    <ShareAltOutlined />
                  </Button>
                </Tooltip>
                {process.env.REACT_APP_APPS_FEEDBACKS_SHOW === 'true' && (
                  <Tooltip title="Feedbacks">
                    <Button
                      className="download-hover-btn"
                      onClick={() => {
                        googleAnalyticsEvents(GA_EVENT?.RELEASE_FEEDBACK, {
                          label: GA_LABEL?.RELEASE_FEEDBACK,
                          member_id: currentUser?.id,
                          application_id:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.id,
                          application_name:
                            getApplicationDetails?.getApplicationsReleaseDetails
                              ?.applicationData?.appName,
                          current_plan_name:
                            teamSubscription?.subscriptionPlan?.name,
                          current_plan_type:
                            teamSubscription?.subscriptionPlan?.type,
                        });
                        setViewReleaseRecord(record);
                        setReleaseFeedbackModal(true);
                      }}
                    >
                      <WechatOutlined />
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div className="tooltip">
                <Tooltip
                  title={
                    record?.remainingDays < 1
                      ? 'Share link expired'
                      : `Share link expires in ${record?.remainingDays} days`
                  }
                >
                  {record?.remainingDays <= 7 &&
                    record?.remainingDays !== null && <InfoCircleOutlined />}
                </Tooltip>
              </div>
              <div>
                <Dropdown
                  overlayClassName="dropdown"
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={['click']}
                  getPopupContainer={(triggerNode) => triggerNode}
                  dropdownRender={(menu) => (
                    <div className="ellipsis-menu">
                      {React.cloneElement(menu)}
                    </div>
                  )}
                >
                  <EllipsisOutlined
                    onClick={() => handleDropdownClick(record)}
                    rotate={90}
                  />
                </Dropdown>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  // Commented for now as discussed with Vishal Bhai
  // useEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   copyToClipboard(window.location.href);
  // }, []);

  return (
    <>
      {state?.loading || getAppDetailsLoading ? (
        <LoaderComponent setHeight={200} />
      ) : (
        <>
          {getApplicationDetails?.getApplicationsReleaseDetails
            ?.totalReleasesCount === 0 &&
          !getApplicationDetailsLoading &&
          getApplicationDetails?.getApplicationsReleaseDetails
            ?.releasesArchivedCount === 0 &&
          state?.platform === 'All' ? (
            <NoReleases />
          ) : (
            <>
              <LatestRelease
                refetchTableData={refetch}
                refetchType={refetchType}
                setRefetchType={setRefetchType}
              />
              <Row className="table-row">
                <div className="table-header">
                  <div className="table-header-left">
                    <h2>All Releases</h2>
                  </div>
                  <div className="table-header-right">
                    <Checkbox
                      className="mr-10"
                      onChange={handleFilterIsResolve}
                    >
                      Show Archived
                      <Tooltip title="Release will archive after 30 days if you not share or share link gets expired!">
                        <InfoCircleOutlined className="ml-4" />
                      </Tooltip>
                    </Checkbox>
                    <div className="filter-platform">
                      <span className="mr-8">Filter By Platform</span>
                      <div className="select-platform">
                        <Select
                          placeholder="All"
                          name="platform"
                          onChange={handleFilterChange}
                          getPopupContainer={(triggerNode) => triggerNode}
                          defaultValue="ALL"
                        >
                          <Select.Option value="ALL">All</Select.Option>
                          <Select.Option value="ANDROID">Android</Select.Option>
                          <Select.Option value="IOS">iOS</Select.Option>
                        </Select>
                      </div>
                    </div>
                    <Button
                      type="primary"
                      className="new-release-btn"
                      onClick={newRelease}
                    >
                      <PlusOutlined />
                      New Release
                    </Button>
                  </div>
                </div>
                <div className="table">
                  <CommonTable
                    rowKey={(e) => e?.id}
                    className="table-content"
                    columns={columns}
                    loading={getApplicationDetailsLoading}
                    data={
                      getApplicationDetails?.getApplicationsReleaseDetails
                        ?.applicationData?.applicationDetails
                    }
                    onChange={handlePagination}
                    paginationConfig={state?.paginationProp}
                  />
                </div>
              </Row>
              {releaseModal && (
                <ViewReleaseNoteModal
                  releaseRecord={viewReleaseRecord}
                  ReleaseNotes={releaseNotes}
                  selectedPlatform={state?.selectedPlatform}
                  showReleaseModal={releaseModal}
                  handleVisible={setReleaseModal}
                  data={getAppDetails}
                  version={state?.appVersion}
                  build={state?.appBuild}
                  refetchReleaseDetails={refetch}
                />
              )}
              {permissionModal && (
                <ViewPermissionModal
                  PermissionNotes={state?.permissions}
                  showPermissionModal={permissionModal}
                  handleVisible={setPermissionModal}
                  selectedPlatform={state?.selectedPlatform}
                  data={getAppDetails}
                  version={state?.appVersion}
                  build={state?.appBuild}
                />
              )}
              {provisionalModal && (
                <ViewProvisionalModal
                  ProvisionalNotes={state?.permissions}
                  showProvisionalModal={provisionalModal}
                  handleVisible={setProvisionalModal}
                  data={getAppDetails}
                  version={state?.appVersion}
                  build={state?.appBuild}
                />
              )}
              {viewShareLinksModal && (
                <ViewAllShareLinks
                  showShareLinkModal={viewShareLinksModal}
                  handleVisible={setViewShareLinksModal}
                  data={shareLinksData}
                  version={state?.appVersion}
                  selectedPlatform={state?.selectedPlatform}
                  columns={modalColumns}
                  loading={ShareLinksLoading}
                  refetchTableData={refetch}
                />
              )}
              {releaseFeedbackModal && (
                <ReleaseFeedbackModal
                  visible={releaseFeedbackModal}
                  handleVisible={setReleaseFeedbackModal}
                  releaseRecord={viewReleaseRecord}
                />
              )}
              {deleteAppModal && (
                <DeleteReleaseModal
                  showDeleteReleaseModal={deleteAppModal}
                  handleVisible={setDeleteAppModal}
                  selectedPlatform={state?.selectedPlatform}
                  data={getAppDetails}
                  version={state?.appVersion}
                  build={state?.appBuild}
                  mutation={deleteRelease}
                  uniqueId={selectedRow}
                  applicationDetails={applicationDetails}
                />
              )}
              {buildShareModal && (
                <BuildShareModal
                  setBuildShareModal={setBuildShareModal}
                  buildShareModal={buildShareModal}
                  rec={rec}
                  getApplicationDetails={getApplicationDetails}
                  id={appId}
                  setEditBuildShareModal={setEditBuildShareModal}
                  editBuildShareModal={editBuildShareModal}
                  response={response}
                  setProFeatureModalOpen={setProFeatureModalOpen}
                  getSharableLinks={getSharableLinks}
                  getSharableData={getSharableData}
                  setSelectedRow={setSelectedRow}
                  setResponse={setResponse}
                  refetchTableData={refetch}
                />
              )}
              {proFeatureModalOpen && (
                <AppServiceProFeatureModal
                  title="Build share"
                  visible={proFeatureModalOpen}
                  setVisible={setProFeatureModalOpen}
                  footer={
                    <div className="d-flex justify-around">
                      <Button
                        type="primary"
                        className="upgrade-button"
                        onClick={() => {
                          history?.push(
                            `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                          );
                        }}
                      >
                        <UpgradeCrown />
                        Upgrade to pro
                      </Button>
                    </div>
                  }
                  content={
                    <Carousel autoplay draggable>
                      <div className="app-service-carousel">
                        <PlanCoverLink />
                        <div className="text">
                          <p>
                            Upgrade to Pro for 60 and 90 days of Extended App
                            Share Links!
                          </p>
                        </div>
                      </div>
                    </Carousel>
                  }
                />
              )}
            </>
          )}
          {testerModal && (
            <TesterAccessModal
              visible={testerModal}
              setVisible={setTesterModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default AppDetails;
