import { filter } from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga4';
import {
  ADD_ON,
  AVATAR_COLOR,
  GA_EVENT,
  GA_LABEL,
  MAX_UPLOAD_SIZE_FREE,
  MAX_UPLOAD_SIZE_FREE_RETURN,
  MAX_UPLOAD_SIZE_LIFETIME,
  MAX_UPLOAD_SIZE_LIFETIME_RETURN,
  MAX_UPLOAD_SIZE_PRO,
  MAX_UPLOAD_SIZE_PRO_PLUS,
  MAX_UPLOAD_SIZE_PRO_PLUS_RETURN,
  MAX_UPLOAD_SIZE_PRO_RETURN,
  PERMISSIONS,
  REGEX,
  defaultDateFormat,
} from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const covertAppSizeIntoKbOrMB = (appSize) =>
  // if the appSize is less than 0.97, show the app size in 'KB' unit, otherwise show in 'MB' units.
  appSize < 0.97 ? `${appSize * 1024} KB` : `${appSize} MB`;

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const avatarColor = (name) =>
  AVATAR_COLOR?.[name?.charAt(0)?.charCodeAt(0) % AVATAR_COLOR?.length];

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: {
    pattern: REGEX?.EMAIL,
    message: 'Enter a valid email address',
  },
  checkIOSTeamId: {
    pattern: REGEX?.IOS_TEAM_ID,
    message: 'The IOS Team ID must be exactly 10 characters',
  },
  uriScheme: {
    pattern: REGEX?.URI_SCHEME,
    message: 'The URI Schema must be alphanumeric, with +, ., - and no spaces',
  },
  sha256: {
    pattern: REGEX?.HEX_COLON_SEPARATED,
    message: 'Please enter a valid SHA256',
  },
  passwordLength: () => ({
    validator(rule, value) {
      if (!value?.length) {
        return Promise?.resolve();
      }
      if (!REGEX?.INPUT_LENGTH?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Password length must be between 6 to 16!');
      }
      return Promise?.resolve();
    },
  }),
  checkEmailArray: () => ({
    validator(rule, value) {
      if (!value?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.resolve();
      }
      const data = filter(value, (item) => {
        if (REGEX?.EMAIL?.test(item?.toString())) {
          return true;
        }
        return false;
      });

      return data.length === value.length
        ? Promise?.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise?.reject('The input is not valid E-mail');
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
  color: () => ({
    validator(rule, value) {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Required');
      }
      if (!REGEX?.COLOR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter valid HEX code');
      }
      return Promise?.resolve();
    },
  }),
  urlPlayStore: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_PLAY_STORE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    },
  }),
  urlPlayStoreAppsOnAir: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_PLAY_STORE_AND_APPSONAIR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    },
  }),
  urlAppStore: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_APP_STORE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    },
  }),
  url: (resolve, reject) => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL?.test(value)) {
        if (reject) reject(); // Call reject if provided
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      if (resolve) resolve(); // Call resolve if provided
      return Promise?.resolve(''); // Always resolve if no issues
    },
  }),
  urlAppStoreAppsOnAir: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WEB_URL_APP_STORE_AND_APPSONAIR?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid URL');
      }
      return Promise?.resolve();
    },
  }),
  workspaceAppNameCheck: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WORKSPACE_APP_NAME_REGEX?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Only letters, numbers, and spaces allowed');
      }
      return Promise?.resolve();
    },
  }),
  whiteSpaceWorkspaceName: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WHITE_SPACE_NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid Workspace Name');
      }
      return Promise?.resolve();
    },
  }),
  whiteSpaceAppName: () => ({
    validator(rule, value) {
      if (value && !REGEX?.WHITE_SPACE_NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter a valid App Name');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const copyToClipboard = (str) => {
  // eslint-disable-next-line no-undef
  const el = document?.createElement('textarea');
  el.value = str;
  // eslint-disable-next-line no-undef
  document?.body?.appendChild(el);
  el?.select();
  // eslint-disable-next-line no-undef
  document?.execCommand('copy');
  // eslint-disable-next-line no-undef
  document?.body.removeChild(el);
};

export const checkPermissions = (UserRole = [], action = '') => {
  if (PERMISSIONS?.length === 0) {
    return true;
  }

  return PERMISSIONS?.[action]?.includes(UserRole);
};

const isImageFileType = (type) => type?.indexOf('image/') === 0;

export const previewImage = (file, size = 64) =>
  new Promise((resolve) => {
    if (!file?.type || !isImageFileType(file?.type)) {
      resolve('');
      return;
    }

    // eslint-disable-next-line no-undef
    const canvas = document?.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${size}px; height: ${size}px; z-index: 9999; display: none;`;
    // eslint-disable-next-line no-undef
    document?.body?.appendChild(canvas);
    const ctx = canvas?.getContext('2d');
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;

      let drawWidth = size;
      let drawHeight = size;
      let offsetX = 0;
      let offsetY = 0;

      if (width < height) {
        drawHeight = height * (size / width);
        offsetY = -(drawHeight - drawWidth) / 2;
      } else {
        drawWidth = width * (size / height);
        offsetX = -(drawWidth - drawHeight) / 2;
      }

      ctx?.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      const dataURL = canvas?.toDataURL();
      // eslint-disable-next-line no-undef
      document?.body?.removeChild(canvas);

      resolve(dataURL);
    };
    // eslint-disable-next-line no-undef
    img.src = window?.URL?.createObjectURL(file);
  });

export const googleAnalyticsEvents = (name, params) => {
  ReactGA?.event(name, params);
};

export const isDueWithin7Days = (dueDateStr) => {
  const [day, month, year] = dueDateStr.match(/\d+|[a-zA-Z]+/g);
  const monthIndex = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].indexOf(month);
  const dueDate = new Date(year, monthIndex, day);
  const currentDate = new Date();
  const timeDifference = dueDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference <= 7;
};

export const formatPurchaseDate = (dateString) => {
  const originalDate = new Date(dateString);

  const day = originalDate.getDate();
  const month = originalDate.toLocaleString('en-US', { month: 'long' });
  const year = originalDate.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const isFileSizeValidate = (inputSizeInBytes, maxLimit) => {
  let maxSize = '';
  if (maxLimit === MAX_UPLOAD_SIZE_PRO_PLUS) {
    maxSize = MAX_UPLOAD_SIZE_PRO_PLUS_RETURN;
  } else if (maxLimit === MAX_UPLOAD_SIZE_PRO) {
    maxSize = MAX_UPLOAD_SIZE_PRO_RETURN;
  } else if (maxLimit === MAX_UPLOAD_SIZE_LIFETIME) {
    maxSize = MAX_UPLOAD_SIZE_LIFETIME_RETURN;
  } else if (maxLimit === MAX_UPLOAD_SIZE_FREE) {
    maxSize = MAX_UPLOAD_SIZE_FREE_RETURN;
  }

  return Boolean(Math.floor(inputSizeInBytes / (1024 * 1024)) <= maxSize);
};

const convertToMB = (usage) => {
  const match = usage?.match(/(\d+(\.\d+)?)\s*(\w+)/);
  if (match) {
    const value = parseFloat(match[1]);
    const unit = match[3]?.toUpperCase();
    if (unit === 'MB') {
      return value;
    }
    if (unit === 'GB') {
      return value * 1024;
    }
  }
  return parseFloat(usage);
};

export const calculatePercentageForProgress = (checkUsage, totalUsage) => {
  const checkUsageMB = checkUsage;
  const totalUsageMB = convertToMB(totalUsage);

  if (totalUsageMB === 0) {
    return 0;
  }

  return (checkUsageMB / totalUsageMB) * 100;
};

export const convertSizeToGBOrMB = (value) => {
  if (value >= 1024) {
    const sizeInGB = value / 1024;
    return `${sizeInGB?.toFixed(2)} GB`;
  }
  return `${value || 0} MB`;
};

export const checkStorageUsage = (totalUsed, totalAvailable, newUsage) => {
  const newUsageInMB = (newUsage / (1024 * 1024)).toFixed(2);
  const newTotalUsed = parseFloat(totalUsed) + parseFloat(newUsageInMB);

  return newTotalUsed < convertToMB(totalAvailable);
};

export const findEarliestRenewalDate = (addons) => {
  if (!addons || addons?.length === 0) {
    return null; // Handle the case where the array is empty
  }

  let earliestRenewalDate = addons?.[0]?.subscriptionRenewalDate;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < addons?.length; i++) {
    const currentRenewalDate = addons?.[i]?.subscriptionRenewalDate;
    if (currentRenewalDate < earliestRenewalDate) {
      earliestRenewalDate = currentRenewalDate;
    }
  }

  return earliestRenewalDate;
};

export const currencyFormat = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

export function bytesToMB(bytes) {
  return bytes / (1024 * 1024);
}

export const upgradeToProEvent = (currentUser, teamPlan) => {
  googleAnalyticsEvents(GA_EVENT?.UPGRADE_TO_PRO, {
    label: GA_LABEL?.UPGRADE_TO_PRO,
    member_id: currentUser?.id,
    current_plan_name: teamPlan?.subscriptionPlan?.name,
    current_plan_type: teamPlan?.subscriptionPlan?.type,
  });
};

export const convertTimeToLocal = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
};

export const convertTimeToLocalWithYear = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
};

export const convertBytesToMB = (value) => {
  const mb = (value / (1024 * 1024)).toFixed(2);
  return `${mb} MB`;
};

export const removeQueryParamsFromUrl = () => {
  // eslint-disable-next-line no-undef
  const urlWithoutParams = `${window?.location?.origin}${window?.location?.pathname}`;
  // eslint-disable-next-line no-undef
  window?.history?.replaceState(null, '', urlWithoutParams);
};

export const handleGraphQLError = (error, setErrorState) => {
  const code = error?.graphQLErrors?.[0]?.extensions?.code;
  switch (code) {
    case 'LINK_EXPIRED':
      setErrorState({ type: 'LINK_EXPIRED', linkExpired: true });
      break;
    case 'LINK_NOT_FOUND':
      setErrorState({ type: 'LINK_NOT_FOUND' });
      break;
    default:
      setErrorState({ type: 'SOMETHING_WENT_WRONG' });
  }
};

export const totalText = (name) => {
  switch (name) {
    case ADD_ON?.APP:
      return 'Total apps';
    case ADD_ON?.STORAGE:
      return 'Total storage';
    case ADD_ON?.CODE_PUSH_BUNDLE:
      return 'Total downloads (per month)';
    default:
      return 'Total';
  }
};

export const checkTesterAccess = (currentRole, allowedPermissions) =>
  checkPermissions(currentRole, allowedPermissions);
