import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { usePlans } from '../../context/PlanProvider';
import { LOGOUT_USER } from './graphql/mutations';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const { setTeamId } = usePlans();

  const [logoutUser, { loading }] = useMutation(LOGOUT_USER, {
    onCompleted: (res) => {
      if (res?.logoutUser) {
        setTeamId(null, 'remove');
      }
      dispatch({ type: 'LOGOUT' });
      history?.push(ROUTES.LOGIN);
    },
    onError: () => {
      dispatch({ type: 'LOGOUT' });
      history?.push(ROUTES.LOGIN);
    },
  });

  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  return loading ? <LoaderComponent /> : null;
};

export default Logout;
