import React from 'react';
import NoAccess from '../NoAccess';
import { checkPermissions } from '../common/utils';
import { usePlans } from '../context/PlanProvider';
import LoaderComponent from './LoaderComponent';

const AccessControl = ({
  allowedPermissions = '',
  children,
  showNoAccess,
  renderNoAccess = () => (showNoAccess ? <NoAccess /> : false),
}) => {
  const { currentRole } = usePlans();

  if (!currentRole) {
    return <LoaderComponent setHeight={700} />;
  }

  const permitted = checkPermissions(currentRole, allowedPermissions);

  if (permitted) {
    return children || permitted;
  }

  return renderNoAccess();
};

export default AccessControl;
