import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppInfoForm from '../components/AppInfoForm';
import DomainForm from '../components/DomainForm';
import WebsiteForm from '../components/WebsiteForm';
import {
  CREATE_DYNAMIC_LINK_CONFIG,
  UPDATE_DYNAMIC_LINK_CONFIG,
} from '../graphql/mutations';
import { GET_DYNAMIC_LINK_CONFIG } from '../graphql/queries';
import '../release-feedbacks.less';

const { Step } = Steps;

const DynamicLinkConfigModal = ({
  visible,
  handleVisible,
  isEdit,
  setIsEdit,
  onUpdate,
}) => {
  const { appId } = useParams();
  const [form] = Form?.useForm();
  const [domainForm] = Form?.useForm();
  const [websiteForm] = Form?.useForm();
  const [appInfoForm] = Form?.useForm();
  const [isLastStep, setIsLastStep] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [isIosApp, setIsIosApp] = useState(true);
  const [isAndroidApp, setIsAndroidApp] = useState(true);
  const [isInAppStore, setIsInAppStore] = useState(true);
  const [isInPlayStore, setIsInPlayStore] = useState(true);
  const [customDomainCheckBoxValue, setCustomDomainCheckBoxValue] = useState(
    false,
  );
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({});

  const [
    createDynamicLinkConfig,
    { data: createDynamicLinkConfigResponseData },
  ] = useMutation(CREATE_DYNAMIC_LINK_CONFIG, {
    onCompleted() {
      setIsLastStep(true);
      onUpdate();
    },
    onError() {},
  });

  const [updateDynamicLinkConfig] = useMutation(UPDATE_DYNAMIC_LINK_CONFIG, {
    onCompleted() {
      onUpdate();
      handleVisible(false);
    },
    onError() {},
  });

  const [getDynamicLinkConfig, { data: dynamicLinkConfigData }] = useLazyQuery(
    GET_DYNAMIC_LINK_CONFIG,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setIsButtonDisable(false);
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (isEdit) {
      getDynamicLinkConfig({
        variables: {
          where: {
            applicationId: appId,
          },
        },
      });
    }
  }, [isEdit]);

  useEffect(() => {
    const linkData = dynamicLinkConfigData?.dynamicLinkConfigs?.[0];
    if (linkData) {
      setData({
        ...data,
        playStoreSearch: linkData?.playStoreSearch,
        playstoreUrl: linkData?.playStoreSearch?.url,
        androidPackageName:
          linkData?.playStoreSearch?.appId ?? linkData?.androidPackageName,
        appStoreSearch: linkData?.appStoreSearch,
        appstoreUrl: linkData?.appStoreSearch?.url,
        iosAppId: linkData?.appStoreSearch?.appId,
        androidDefaultFallbackUrl: linkData?.androidDefaultFallbackUrl,
        iosDefaultFallbackUrl: linkData?.iosDefaultFallbackUrl,
      });
      setIsIosApp(linkData?.isIosApp);
      setIsInAppStore(linkData?.isInAppStore);
      setIsAndroidApp(linkData?.isAndroidApp);
      setIsInPlayStore(linkData?.isInPlayStore);
    }
  }, [dynamicLinkConfigData]);

  const steps = [
    {
      key: 'DOMAIN_NAME',
      title: 'Add domain name',
      content: (
        <DomainForm
          domainForm={domainForm}
          setIsButtonDisable={setIsButtonDisable}
          dynamicLinkConfigId={
            dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.id
          }
          initialDomainPrefix={
            dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.domainPrefix
          }
          customDomainCheckBoxValue={customDomainCheckBoxValue}
          setCustomDomainCheckBoxValue={setCustomDomainCheckBoxValue}
          isEdit={isEdit}
        />
      ),
    },
    {
      key: 'WEBSITE_NAME',
      title: 'Add website name',
      content: (
        <WebsiteForm
          websiteForm={websiteForm}
          setIsButtonDisable={setIsButtonDisable}
          initialDefaultWebUrl={
            dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.defaultWebUrl
          }
        />
      ),
    },
    {
      key: 'APP_INFO',
      title: 'Add app info',
      content: (
        <AppInfoForm
          appInfoForm={appInfoForm}
          setData={setData}
          data={data}
          dynamicLinkConfigData={dynamicLinkConfigData?.dynamicLinkConfigs?.[0]}
          setIsButtonDisable={setIsButtonDisable}
          setIsIosApp={setIsIosApp}
          isIosApp={isIosApp}
          setIsAndroidApp={setIsAndroidApp}
          isAndroidApp={isAndroidApp}
          setIsInAppStore={setIsInAppStore}
          isInAppStore={isInAppStore}
          setIsInPlayStore={setIsInPlayStore}
          isInPlayStore={isInPlayStore}
        />
      ),
    },
  ];

  const next = () => {
    setIsButtonDisable(true);
    if (current === 0) {
      domainForm?.submit();
    } else if (current === 1) {
      websiteForm?.submit();
    } else {
      appInfoForm?.submit();
    }
  };

  const prev = () => {
    setCurrent((prevState) => prevState - 1);
  };

  const handleCancel = () => {
    handleVisible(false);
    setIsEdit(false);
  };

  const onFinish = async (name, { values }) => {
    if (name === 'domainForm') {
      setData({ ...data, ...values });
      setCurrent(current + 1);
    }
    if (name === 'websiteForm') {
      setData({ ...data, ...values });
      setCurrent(current + 1);
    }
    if (name === 'appInfoForm') {
      setData({ ...data, ...values });
      const {
        domainPrefix,
        defaultWebUrl,
        customDomain,
        appStoreSearch,
        playStoreSearch,
      } = data;
      const {
        iosCountryCode,
        iosUriSchema,
        iosTeamId,
        androidCountryCode,
        androidUriSchema,
        fingerprints,
      } = values;
      const shaCert = fingerprints
        ? fingerprints.map((item) => item.shaCertFingerprints)
        : [];
      const payload = {
        isAndroidApp,
        isIosApp,
        defaultWebUrl,
        androidCountryCode,
        playStoreSearch,
        playstoreUrl: isInPlayStore ? data?.playstoreUrl : values?.playstoreUrl,
        androidDefaultFallbackUrl: !isAndroidApp
          ? values?.androidDefaultFallbackUrl
          : null,
        androidPackageName: isInPlayStore
          ? data?.androidPackageName
          : values?.androidPackageName,
        androidUriSchema,
        shaCertFingerprints: shaCert,
        customDomain,
        iosCountryCode,
        appStoreSearch,
        appstoreUrl: isInAppStore ? data?.appstoreUrl : values?.appstoreUrl,
        iosDefaultFallbackUrl: !isIosApp ? values?.iosDefaultFallbackUrl : null,
        iosAppId: isInAppStore ? data?.iosAppId : values?.iosAppId,
        iosTeamId,
        iosUriSchema,
        isInPlayStore,
        isInAppStore,
      };
      if (isEdit) {
        updateDynamicLinkConfig({
          variables: {
            where: {
              linkConfigId: dynamicLinkConfigData?.dynamicLinkConfigs?.[0]?.id,
            },
            data: {
              ...payload,
              isEnable: true,
            },
          },
        });
      } else {
        createDynamicLinkConfig({
          variables: {
            where: {
              applicationId: appId,
            },
            data: {
              ...payload,
              isCustomDomain: customDomainCheckBoxValue,
              domainPrefix,
            },
          },
        });
      }
    }
  };

  return (
    <Modal
      width={736}
      centered
      title={
        <>
          {isLastStep ? (
            <h1>Your URL prefix is ready for new users!</h1>
          ) : (
            <Steps
              labelPlacement="vertical"
              size="small"
              className="step-bar"
              current={current}
            >
              {steps?.map((item) => (
                <Step key={item?.key} title={item?.title} />
              ))}
            </Steps>
          )}
        </>
      }
      footer={
        <div>
          {current > 0 && !isLastStep && (
            <Button className="previous" onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length && !isLastStep && (
            <Button
              className="next"
              type="primary"
              onClick={() => next()}
              disabled={isButtonDisable}
            >
              {current === 2 ? 'Done' : 'Next'}
            </Button>
          )}
          {isLastStep && (
            <Button type="primary" onClick={handleCancel}>
              Finish
            </Button>
          )}
        </div>
      }
      onCancel={handleCancel}
      closable={!isLastStep}
      open={visible}
      destroyOnClose
      className={isLastStep && 'final-step'}
      wrapClassName="url-prefix-modal"
    >
      <div className="steps-wrapper">
        <Row justify="center" className="onboard-container">
          <div className="stepper-content">
            {isLastStep ? (
              <div>
                <p>
                  You can review and always change your{' '}
                  <span>configuration</span> at any time.
                </p>
                <Input
                  disabled
                  value={
                    createDynamicLinkConfigResponseData?.createDynamicLinkConfig
                      ?.data?.urlPrefix
                  }
                />
              </div>
            ) : (
              <Form.Provider
                onFormFinish={onFinish}
                className="full-height sticky-action-form"
                form={form}
                layout="vertical"
              >
                {steps?.[current]?.content}
              </Form.Provider>
            )}
          </div>
        </Row>
      </div>
    </Modal>
  );
};

export default DynamicLinkConfigModal;
