import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';
import { usePlans } from '../../context/PlanProvider';

const BannerLegacyUser = ({ handleVisible }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamId, teamSubscription } = usePlans();
  return (
    <div className="legacy-banner">
      <div className="container">
        🎉 <b>Final Chance for Legacy Users: 30%</b> Lifetime Discount Ends On
        March 19, 2025!
        <Button
          size="small"
          type="primary"
          href="https://go.logicwind.com/early_access_discount_website"
          target="_blank"
          className="ml-8"
          onClick={() => {
            googleAnalyticsEvents(GA_EVENT?.LEGACY_CLAIM_NOW, {
              label: GA_LABEL?.LEGACY_CLAIM_NOW,
              member_id: currentUser?.id,
              workspace_id: teamId,
              current_plan_name: teamSubscription?.subscriptionPlan?.name,
              current_plan_type: teamSubscription?.subscriptionPlan?.type,
            });
          }}
        >
          Claim Now!
        </Button>
        <div className="close-icon" onClick={() => handleVisible(false)}>
          <CloseOutlined />
        </div>
      </div>
    </div>
  );
};

export default BannerLegacyUser;
