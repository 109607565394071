import { useLazyQuery } from '@apollo/client';
import { Button, Carousel, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  PlanCoverAppServiceAndroid,
  PlanCoverAppServiceIOS,
  UpgradeCrown,
} from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import { upgradeToProEvent } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { usePlans } from '../../context/PlanProvider';
import UpdateApplication from './UpdateApplication';
import UpdatePreview from './UpdatePreview';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import { GET_APP_UPGRADE_DETAIL } from './graphql/queries';
import './update-services.less';

const UpdateServices = () => {
  const { teamId, isFreePlan, teamSubscription } = usePlans();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const history = useHistory();
  const { appId } = useParams();
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [androidCollapse, setAndroidCollapse] = useState(null);
  const [iosCollapse, setIosCollapse] = useState(null);
  const [androidChecked, setAndroidChecked] = useState(false);
  const [iosChecked, setIosChecked] = useState(false);
  const [androidInitialValues, setAndroidInitialValues] = useState(null);
  const [iosInitialValues, setIosInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);

  const [fetchUpgradeDetails] = useLazyQuery(GET_APP_UPGRADE_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
    },
    onCompleted(res) {
      if (res?.getAppUpgradeDetail?.isAndroidUpdate) {
        setAndroidCollapse(true);
        setAndroidInitialValues({
          androidBuildNumber: res?.getAppUpgradeDetail?.androidBuildNumber,
          androidMinBuildVersion:
            res?.getAppUpgradeDetail?.androidMinBuildVersion,
          androidUpdateLink: res?.getAppUpgradeDetail?.androidUpdateLink,
        });
      }
      if (res?.getAppUpgradeDetail?.isIosUpdate) {
        setIosCollapse(true);
        setIosInitialValues({
          iosBuildNumber: res?.getAppUpgradeDetail?.iosBuildNumber,
          iosMinBuildVersion: res?.getAppUpgradeDetail?.iosMinBuildVersion,
          iosUpdateLink: res?.getAppUpgradeDetail?.iosUpdateLink,
        });
      }
      if (res?.getAppUpgradeDetail?.isAndroidForcedUpdate) {
        setAndroidChecked(true);
      }
      if (res?.getAppUpgradeDetail?.isIosForcedUpdate) {
        setIosChecked(true);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    fetchUpgradeDetails();
    if (isFreePlan) {
      setProFeatureModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="services-header">
      {loading ? (
        <LoaderComponent spinning setHeight="200" />
      ) : (
        <>
          {isFreePlan && (
            <div className="app-service-pro-banner">
              <div className="description-section">
                <h2>Get the update feature</h2>
                <p>Upgrade to enable updates to your apps.</p>
              </div>
              <div className="button-section">
                <Button
                  className="mr-16"
                  onClick={() => {
                    setProFeatureModalOpen(true);
                  }}
                >
                  See what you get
                </Button>
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={() => {
                    upgradeToProEvent(currentUser, teamSubscription);
                    history?.push(
                      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                    );
                  }}
                >
                  <UpgradeCrown />
                  Upgrade to pro
                </Button>
              </div>
            </div>
          )}
          <div
            className={`d-flex justify-between ${
              isFreePlan ? 'fade-feature' : ''
            }`}
          >
            <Row className="update-content-row">
              <Col span={24}>
                <UpdateApplication
                  androidCollapse={androidCollapse}
                  iosCollapse={iosCollapse}
                  setAndroidCollapse={setAndroidCollapse}
                  setIosCollapse={setIosCollapse}
                  androidChecked={androidChecked}
                  setAndroidChecked={setAndroidChecked}
                  iosChecked={iosChecked}
                  setIosChecked={setIosChecked}
                  androidInitialValues={androidInitialValues}
                  iosInitialValues={iosInitialValues}
                  fetchUpgradeDetails={fetchUpgradeDetails}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="update-content-preview">
                  <UpdatePreview
                    androidCollapse={androidCollapse}
                    iosCollapse={iosCollapse}
                    androidChecked={androidChecked}
                    iosChecked={iosChecked}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {proFeatureModalOpen && (
            <AppServiceProFeatureModal
              title="Enable updates on your apps"
              visible={proFeatureModalOpen}
              setVisible={setProFeatureModalOpen}
              footer={
                <div className="text-center">
                  <Button
                    type="primary"
                    className="upgrade-button"
                    onClick={() => {
                      upgradeToProEvent(currentUser, teamSubscription);
                      history?.push(
                        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                      );
                    }}
                  >
                    <UpgradeCrown />
                    Upgrade to pro
                  </Button>
                </div>
              }
              content={
                <Carousel autoplay draggable>
                  <div className="app-service-carousel carousel">
                    <PlanCoverAppServiceAndroid />
                    <div className="text">
                      <p>
                        By enabling Updates, you will be able to see any new
                        releases published in this app.
                      </p>
                    </div>
                  </div>
                  <div className="app-service-carousel carousel">
                    <PlanCoverAppServiceIOS />
                    <div className="text">
                      <p>
                        By enabling force update, users have to install latest
                        release in order to use the app.
                      </p>
                    </div>
                  </div>
                </Carousel>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default UpdateServices;
