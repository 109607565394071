import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Avatar, Button, Modal, Tag } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import defaultIcon from '../../../assets/svg/default-app.svg';
import { GA_EVENT, GA_LABEL, MODAL_WIDTH } from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import { usePlans } from '../../../context/PlanProvider';
import '../style/app-details.less';

const DeleteRelease = ({
  showDeleteReleaseModal,
  handleVisible,
  selectedPlatform,
  data,
  version,
  build,
  mutation,
  getApplicationDetails,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription } = usePlans();

  const handleOk = () => {
    mutation();
    handleVisible(false);
  };

  const handleCancel = () => {
    googleAnalyticsEvents(GA_EVENT?.RELEASE_DELETE_REQ_CANCEL, {
      label: GA_LABEL?.RELEASE_DELETE_REQ_CANCEL,
      member_id: currentUser?.id,
      application_id:
        getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
          ?.id,
      application_name:
        getApplicationDetails?.getApplicationsReleaseDetails?.applicationData
          ?.appName,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    handleVisible(false);
  };

  return (
    <Modal
      centered
      className="delete-release"
      title="Delete Release"
      width={MODAL_WIDTH}
      onCancel={handleCancel}
      open={showDeleteReleaseModal}
      onOk={handleOk}
      footer={
        <div key="footer-delete-release">
          <Button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="delete-btn"
            onClick={handleOk}
            type="primary"
            danger
          >
            Delete
          </Button>
        </div>
      }
    >
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-left d-flex">
            <Avatar
              src={data?.getApplication?.appLogo || defaultIcon}
              alt="release-img"
              className="mr-15 mb-20 release-avatar"
            />
            <h2>{data?.getApplication?.appName}</h2>
          </div>
          <div className="modal-header-right">
            {selectedPlatform === 'IOS' ? (
              <AppleFilled size={18} className="ios-color" />
            ) : (
              <AndroidFilled className="android-color" />
            )}
            <Tag>Version: {version}</Tag>
            <Tag>Build: {build}</Tag>
          </div>
        </div>
        <div>Are you sure you want to delete this release?</div>
      </div>
    </Modal>
  );
};

export default DeleteRelease;
