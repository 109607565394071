import { PlusOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Layout,
  Menu,
  notification,
} from 'antd';
import { filter, findIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import blankIcon from '../../../assets/images/blank-icon.png';
import { Logo, UpgradeCrown } from '../../../assets/svg';
import downArrow from '../../../assets/svg/down-arrow.svg';
import settingsIcon from '../../../assets/svg/settings-icon.svg';
import {
  GA_EVENT,
  GA_LABEL,
  LEGACY_USER_EXPIRED,
  NEW_FEATURE_POPUP,
  PLANS,
  ROUTES,
  SUBSCRIPTION_STATUS,
} from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import NewFeatureModal from '../../../components/NewFeatureModal';
import { usePlans } from '../../../context/PlanProvider';
import PaymentDueModal from '../../../modules/apps/components/PaymentDueModal';
import UserProfile from './UserProfile';
import './app-header.less';

const { Header } = Layout;

const AppHeader = () => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const [api, contextHolder] = notification.useNotification();
  const {
    dispatch,
    state: { currentUser, codePushLimitExhaustNotification, legacyUserFree },
  } = useContext(AppContext);
  const {
    teamId,
    teamSubscription,
    isFreePlan,
    ownerTeam,
    userTeams,
    setTeamId,
    handleWorkspaceFeature,
    teamPlanAddOns,
    teamPlanUsage,
    teamPlanTotalUsage,
    currentWorkspace,
  } = usePlans();
  const [paymentFailedModal, setPaymentFailedModal] = useState(false);
  const [newFeaturePopupModal, setNewFeaturePopupModal] = useState(false);
  const isNewFeaturePopupEnabled =
    process.env.REACT_APP_NEW_FEATURE_POPUP === 'true';
  const isLegacyUser = currentWorkspace?.isLegacy;

  useEffect(() => {
    if (
      location?.pathname?.includes(ROUTES?.PLAN_AND_PAYMENT) ||
      location?.pathname?.includes(ROUTES?.MANAGE_ADD_ONS)
    ) {
      setPaymentFailedModal(false);
    } else if (
      teamSubscription?.subscriptionStatus ===
        SUBSCRIPTION_STATUS?.PAYMENT_DUE.key ||
      teamPlanAddOns?.data?.subscriptionStatus ===
        SUBSCRIPTION_STATUS?.PAYMENT_DUE.key
    ) {
      setPaymentFailedModal(true);
    } else if (
      teamPlanUsage?.codePushDownload >= teamPlanTotalUsage?.CODE_PUSH_BUNDLE &&
      !codePushLimitExhaustNotification
    ) {
      googleAnalyticsEvents(GA_EVENT?.CODE_PUSH_LIMIT_EXHAUST, {
        label: GA_LABEL?.CODE_PUSH_LIMIT_EXHAUST,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      api?.open({
        message: 'CodePush Bundle Downloads Limit Exhaust',
        description:
          'Your CodePush download limit has been fully utilized. Users are unable to download new updates. Please upgrade your plan or purchase an add-on to restore update downloads.',
        duration: 0,
        onClose: () => {
          googleAnalyticsEvents(GA_EVENT?.CODE_PUSH_LIMIT_EXHAUST_CLOSE, {
            label: GA_LABEL?.CODE_PUSH_LIMIT_EXHAUST_CLOSE,
            member_id: currentUser?.id,
            current_plan_name: teamSubscription?.subscriptionPlan?.name,
            current_plan_type: teamSubscription?.subscriptionPlan?.type,
          });
          dispatch({ type: 'SET_CODE_PUSH_LIMIT_EXHAUST', data: true });
          // You can also perform other actions here
        },
      });
    }
  }, [location, teamSubscription, teamPlanAddOns]);

  useEffect(() => {
    if (!legacyUserFree) {
      setNewFeaturePopupModal(true);
    }
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(LEGACY_USER_EXPIRED);
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(NEW_FEATURE_POPUP);
  }, []);

  const handleMenuClick = (team, reload) => {
    googleAnalyticsEvents(GA_EVENT?.CHANGE_WORKSPACE, {
      label: GA_LABEL?.CHANGE_WORKSPACE,
      workspace_id: team?.id,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    const value = filter(userTeams, (val) => val?.id === team?.id);
    setTeamId(team?.id);
    handleWorkspaceFeature({
      currentRole: value?.[0]?.role,
      selectedTeamId: team?.id,
    });
    if (location?.pathname !== ROUTES?.APPS) {
      history?.push(ROUTES?.APPS);
    }
    if (reload) {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };

  const handleChange = (id) => {
    const value = filter(userTeams, (val) => val?.id === id);
    setTeamId(id);
    handleWorkspaceFeature({
      currentRole: value?.[0]?.role,
      selectedTeamId: id,
    });
    history?.push(
      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.WORKSPACE_SETTINGS}/${id}`,
    );
  };

  const handleMenuChange = (key) => {
    switch (key?.key) {
      case 'apps':
        history?.push(ROUTES?.APPS);
        break;
      case 'teams':
        history?.push(ROUTES?.TEAMS);
        break;
      case 'integrations':
        history?.push(ROUTES?.INTEGRATIONS);
        break;
      default:
        history?.push(ROUTES?.APPS);
    }
  };

  const selectedIndex = findIndex(userTeams, (item) => item?.id === teamId);

  const workspaceLogo =
    userTeams?.[selectedIndex >= 0 ? selectedIndex : '']?.teamLogo || null;

  const items = [
    ...(userTeams?.map((team) => ({
      key: team?.id,
      label: (
        <div className="d-flex align-center justify-between">
          <div className="team-name-col">
            <Avatar
              src={team?.teamLogo || blankIcon}
              alt="teamLogo"
              shape="square"
              className="team-avatar mr-4"
              size={18}
            />
            <span className="team-heading font-14" title={team?.teamName}>
              {team?.teamName}
            </span>
          </div>
          <div>
            <img
              src={settingsIcon}
              alt="icon"
              width={18}
              height={18}
              onClick={(e) => {
                e?.stopPropagation();
                handleChange(team?.id);
              }}
            />
          </div>
        </div>
      ),
      onClick: () => handleMenuClick(team),
    })) || []),
  ];

  const paymentDueItems = [
    ...(userTeams?.map((team) => ({
      key: team?.id,
      label: (
        <div className="d-flex align-center justify-between">
          <div className="team-name-col">
            <Avatar
              src={team?.teamLogo || blankIcon}
              alt="teamLogo"
              shape="square"
              className="team-avatar mr-4"
              size={18}
            />
            <span className="team-heading font-14" title={team?.teamName}>
              {team?.teamName}
            </span>
          </div>
        </div>
      ),
      onClick: () => handleMenuClick(team, true),
    })) || []),
  ];

  const redirectLink = `${ROUTES.WORKSPACE_DETAILS}${ROUTES.PLAN_AND_PAYMENT}/${teamId}`;

  const headerItems = [
    { key: 'apps', label: 'Apps' },
    { key: 'teams', label: 'Team' },
    {
      key: 'integrations',
      label: (
        <div className="d-flex align-center gap-8">
          Integrations
          {(isFreePlan ||
            teamSubscription?.subscriptionPlan?.name === PLANS?.LEGACY ||
            teamSubscription?.name === PLANS?.LEGACY) && (
            <Button
              type="primary"
              size="small"
              className="upgrade-button"
              onClick={(e) => {
                e.stopPropagation();
                googleAnalyticsEvents(GA_EVENT?.PRO_RESTRICTION_BUTTON, {
                  label: GA_LABEL?.PRO_RESTRICTION_BUTTON,
                  member_id: currentUser?.id,
                  current_plan_name: teamSubscription?.subscriptionPlan?.name,
                  current_plan_type: teamSubscription?.subscriptionPlan?.type,
                });
                history?.push(redirectLink);
              }}
            >
              <span>Pro</span>
              <UpgradeCrown />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleUpgradeRedirect = () => {
    const isPlanPath = location?.pathname?.includes(ROUTES?.PLAN_AND_PAYMENT);
    googleAnalyticsEvents(GA_EVENT?.UPGRADE_BUTTON, {
      label: GA_LABEL?.UPGRADE_BUTTON,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    if (isPlanPath) {
      // eslint-disable-next-line no-undef
      const element = document.getElementById('plans-section');
      if (element) {
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      history?.push(
        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
      );
    }
  };

  return (
    <Header className="app-header">
      <div className="header-left">
        <div className="logo mr-8">
          <Logo onClick={() => history?.push(ROUTES?.APPS)} />
        </div>
        {userTeams?.length > 0 &&
          !location?.pathname?.includes(ROUTES?.ONBOARD) &&
          !location?.pathname?.includes(ROUTES?.CODE_PUSH_AUTHENTICATION) && (
            <>
              <Divider type="vertical" className="app-header-divider" />
              <div className="header-left-content">
                <Menu
                  mode="horizontal"
                  selectedKeys={setLocation}
                  onClick={handleMenuChange}
                  items={headerItems}
                />
              </div>
            </>
          )}
      </div>
      <div className="header-right">
        {!location?.pathname?.includes(ROUTES?.ONBOARD) &&
          !location?.pathname?.includes(ROUTES?.CODE_PUSH_AUTHENTICATION) && (
            <>
              {isFreePlan && (
                <Button
                  type="primary"
                  className="upgrade-button"
                  onClick={handleUpgradeRedirect}
                >
                  <UpgradeCrown />
                  Upgrade
                </Button>
              )}
              {!location?.pathname?.includes(ROUTES?.CREATE_RELEASE) &&
                !location?.pathname?.includes(ROUTES?.NEW_RELEASE) && (
                  <Dropdown
                    overlayClassName={`dropdown-header ${
                      ownerTeam ? 'owner' : ''
                    }`}
                    menu={{
                      items,
                      selectable: true,
                      selectedKeys: [teamId],
                    }}
                    trigger={['click']}
                    placement="topRight"
                    dropdownRender={(menu) => (
                      <div className="workspace-dropdown">
                        {React.cloneElement(menu)}
                        {!ownerTeam && (
                          <div className="workspace-title sticky-button">
                            <Divider />
                            <Link
                              onClick={() => {
                                googleAnalyticsEvents(
                                  GA_EVENT?.CREATE_NEW_WORKSPACE,
                                  {
                                    label: GA_LABEL?.CREATE_NEW_WORKSPACE,
                                    member_id: currentUser?.id,
                                    current_plan_name:
                                      teamSubscription?.subscriptionPlan?.name,
                                    current_plan_type:
                                      teamSubscription?.subscriptionPlan?.type,
                                  },
                                );
                                dispatch({
                                  type: 'SET_CURRENT_VALUE',
                                  data: 0,
                                });
                              }}
                              to={ROUTES?.ONBOARD}
                            >
                              <div className="create-workspace-content">
                                <PlusOutlined className="mr-8" />
                                <span className="text">Create Workspace</span>
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  >
                    <Button type="text" className="workspace-dropdown">
                      <div className="d-flex align-center">
                        <img
                          className="team-logo"
                          alt="logo"
                          width={18}
                          height={18}
                          src={workspaceLogo || blankIcon}
                        />
                        <span
                          className="add-ellipsis font-600 ml-8 mr-12"
                          title={
                            userTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                              ?.teamName
                          }
                        >
                          {
                            userTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                              ?.teamName
                          }
                        </span>
                      </div>
                      <img
                        src={downArrow}
                        alt="down-arrow"
                        className="down-arrow"
                      />
                    </Button>
                  </Dropdown>
                )}
              <Divider className="header-divider" type="vertical" />
              <div className="header-notification">
                <UserProfile />
              </div>
            </>
          )}
      </div>

      {paymentFailedModal && (
        <PaymentDueModal
          visible={paymentFailedModal}
          items={paymentDueItems}
          data={{ userTeams }}
          workspaceLogo={workspaceLogo}
          selectedIndex={selectedIndex}
        />
      )}
      {contextHolder}
      {isNewFeaturePopupEnabled &&
        newFeaturePopupModal &&
        isLegacyUser &&
        isFreePlan && (
          <NewFeatureModal
            visible={newFeaturePopupModal}
            setVisible={setNewFeaturePopupModal}
          />
        )}
    </Header>
  );
};
export default AppHeader;
