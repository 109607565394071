import { useQuery } from '@apollo/client';
import { filter } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppContext } from '../AppContext';
import { PLANS, ROLE_KEYS, ROUTES, TEAM_KEY } from '../common/constants';
import { GET_USER_TEAM } from '../modules/auth/graphql/queries';
import { MANAGE_ADD_ONS } from '../modules/workspace/manage-addons/graphql/queries';
import { CURRENT_PLAN_FOR_USER } from '../modules/workspace/plans-and-payment/graphql/queries';

const PlanContext = createContext(null);

const PlanProvider = ({ children }) => {
  const {
    state: { authToken },
  } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const teamId = localStorage?.getItem(TEAM_KEY);

  const setTeamId = (id, operation = 'add') => {
    if (operation === 'remove') {
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(TEAM_KEY);
      return;
    }
    // eslint-disable-next-line no-undef
    localStorage?.setItem(TEAM_KEY, id);
  };

  // eslint-disable-next-line no-undef
  const isWorkspace = window?.location?.pathname === ROUTES?.WORKSPACE;
  const [currentRole, setCurrentRole] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [workspaceFeatures, setWorkspaceFeatures] = useState(null);

  // USER TEAM
  const {
    data: { getUserTeams = [] } = {},
    refetch: refetchUserTeams,
  } = useQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    skip: !authToken,
    onError() {},
    onCompleted(res) {
      if (res?.getUserTeams?.length) {
        const currentTeam = res?.getUserTeams?.find(
          (team) => team?.id === teamId,
        );
        setCurrentWorkspace(currentTeam || res?.getUserTeams?.[0]);
        setCurrentRole(currentTeam?.role || res?.getUserTeams?.[0]?.role);
        const prepareFeatureArray =
          currentTeam?.teamConfig?.features ||
          res?.getUserTeams?.[0]?.teamConfig?.features;
        setWorkspaceFeatures(
          prepareFeatureArray
            ?.filter((item) => item?.isActive)
            ?.map((element) => element?.key),
        );
      }
    },
  });

  useEffect(() => {
    if (!teamId && getUserTeams?.length > 0) {
      setTeamId(getUserTeams?.[0]?.id);
    }
  }, [getUserTeams, teamId]);

  const ownerTeam = useMemo(
    () => getUserTeams?.some((teamObj) => teamObj?.role === ROLE_KEYS?.OWNER),
    [getUserTeams],
  );

  const handleWorkspaceFeature = useCallback(
    (arg) => {
      const teamFeatures = filter(
        getUserTeams,
        (val) => val?.id === arg?.selectedTeamId,
      );
      const prepareFeatureArray = teamFeatures?.[0]?.teamConfig?.features;
      setCurrentWorkspace(teamFeatures?.[0]);
      setWorkspaceFeatures(
        prepareFeatureArray
          ?.filter((item) => item?.isActive)
          ?.map((element) => element?.key),
      );
      setCurrentRole(arg?.currentRole);
    },
    [getUserTeams],
  );

  // PLAN
  const {
    data: {
      teamSubscriptionPlan: { data: { request: teamSubscription } = {} } = {},
    } = {},
    refetch,
  } = useQuery(CURRENT_PLAN_FOR_USER, {
    fetchPolicy: 'no-cache',
    skip: !teamId || teamId === 'null' || isWorkspace,
    variables: {
      data: {
        id: teamId,
      },
    },
    onError() {},
  });

  const { team, accountUsage } = teamSubscription || {};
  const { teamConfig } = team || {};
  const isFreePlan = teamSubscription?.name === PLANS?.FREE;

  const teamPlanTotalUsage = useMemo(
    () =>
      teamConfig?.config?.reduce((acc, item) => {
        if (item) {
          acc[item?.key] =
            item?.unit === 'GB' || item?.unit === 'MB'
              ? `${item?.value} ${item?.unit}`
              : item?.value;
        }
        return acc;
      }, {}) || {},
    [teamConfig?.config],
  );

  const refetchPlanDetails = useCallback((id = null) => {
    refetch({
      id: id || teamId,
    });
  }, []);

  // ADDONS
  const { data: { teamAddons } = {}, refetch: $refetchAddons } = useQuery(
    MANAGE_ADD_ONS,
    {
      fetchPolicy: 'no-cache',
      skip: !teamId || teamId === 'null' || isWorkspace,
      variables: {
        data: {
          id: teamId,
        },
      },
    },
  );

  const refetchAddons = (id = null) => {
    $refetchAddons({
      id: id || teamId,
    });
  };

  useEffect(() => {
    if (authToken) {
      refetchUserTeams();
      if (teamId !== 'null') {
        refetchPlanDetails();
        refetchAddons();
      }
    }
  }, [authToken]);

  const value = useMemo(
    () => ({
      teamId,
      teamSubscription,
      teamPlanTotalUsage,
      isFreePlan,
      refetchPlanDetails,
      ownerTeam,
      currentWorkspace,
      workspaceFeatures,
      refetchUserTeams,
      setTeamId,
      refetchAddons,
      teamPlanUsage: accountUsage,
      userTeams: getUserTeams,
      currentRole,
      teamPlanAddOns: teamAddons,
      handleWorkspaceFeature,
      setCurrentRole,
    }),
    [
      teamId,
      teamPlanTotalUsage,
      teamSubscription,
      accountUsage,
      isFreePlan,
      refetchPlanDetails,
      ownerTeam,
      workspaceFeatures,
      getUserTeams,
      refetchUserTeams,
      setTeamId,
      teamAddons,
      refetchAddons,
      handleWorkspaceFeature,
      currentRole,
      setCurrentRole,
    ],
  );
  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

export const usePlans = () => {
  const ctx = useContext(PlanContext);
  if (!ctx) throw new Error('usePlans must be used inside PlanProvider');

  return ctx;
};

export default PlanProvider;
