import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { formValidatorRules } from '../../../common/utils';
import '../release-feedbacks.less';

const { url } = formValidatorRules;

const WebsiteForm = ({
  websiteForm,
  setIsButtonDisable,
  initialDefaultWebUrl,
}) => {
  useEffect(() => {
    if (initialDefaultWebUrl) {
      websiteForm?.setFieldsValue({ domainPrefix: initialDefaultWebUrl });
    }
  }, [initialDefaultWebUrl, websiteForm]);

  return (
    <>
      <Form
        name="websiteForm"
        form={websiteForm}
        layout="vertical"
        initialValues={{ defaultWebUrl: initialDefaultWebUrl }}
      >
        <Form.Item
          label="Website Name"
          name="defaultWebUrl"
          required
          className="mb-4 form-label"
          rules={[
            {
              required: true,
            },
            url(
              () => {
                setIsButtonDisable(false);
              },
              () => {
                setIsButtonDisable(true);
              },
            ),
          ]}
        >
          <Input
            className="domain-input"
            placeholder="e.g. www.appsonair.com"
            type="text"
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default WebsiteForm;
