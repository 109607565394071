import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import { getCodeList } from 'country-list';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import { APP_STORE_SEARCH, PLAY_STORE_SEARCH } from '../graphql/queries';
import '../release-feedbacks.less';

const { url, checkIOSTeamId, uriScheme, sha256 } = formValidatorRules;
const getAndroidUriSchemaField = () => (
  <Form.Item
    label="Android URI Schema"
    name="androidUriSchema"
    className="mb-4 form-label"
    rules={[uriScheme]}
  >
    <Input placeholder="Andorid URI schema" />
  </Form.Item>
);

const getShaCertField = () => (
  <Form.List name="fingerprints">
    {(fields, { add, remove }) => (
      <>
        {fields?.map(({ key, name, ...restField }, index) => (
          <>
            <div className="mb-16 form-list">
              <Form.Item
                {...restField}
                key={key}
                name={[name, 'shaCertFingerprints']}
                label={`Certificate Fingerprint ${index + 1}`}
                className="mb-4 form-label"
                rules={[{ required: true }, sha256]}
                extra={
                  <div className="d-flex align-center gap-16 remove-icon">
                    {fields?.length > 1 ? (
                      <DeleteOutlined onClick={() => remove(name)} />
                    ) : null}
                  </div>
                }
              >
                <Input placeholder="e.g. 00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00" />
              </Form.Item>
            </div>
          </>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => add()}
            className="mt-8"
            icon={<PlusOutlined />}
          >
            Add Fingerprint
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

const AppInfoForm = ({
  appInfoForm,
  setData,
  data,
  dynamicLinkConfigData,
  setIsButtonDisable,
  setIsIosApp,
  isIosApp,
  setIsAndroidApp,
  isAndroidApp,
  setIsInAppStore,
  isInAppStore,
  setIsInPlayStore,
  isInPlayStore,
}) => {
  const countryList = getCodeList();
  const appStoreCountry = Form.useWatch('iosCountryCode', appInfoForm);
  const playStoreCountry = Form.useWatch('androidCountryCode', appInfoForm);

  const [appStoreCountryLoading, setAppStoreCountryLoading] = useState(false);
  const [playStoreCountryLoading, setPlayStoreCountryLoading] = useState(false);
  const [appStoreSearchList, setAppStoreSearchList] = useState([]);
  const [playStoreSearchList, setPlayStoreSearchList] = useState([]);

  const [getPlayStoreApps] = useLazyQuery(PLAY_STORE_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const appArray = res?.playStoreAppSearch?.map((app) => ({
        value: app?.appId,
        label: (
          <div>
            <img src={app?.icon} alt={app?.appId} className="app-select-icon" />
            <span>{app?.title}</span>
          </div>
        ),
        playStoreSearch: app,
        dataUrl: app?.url,
      }));
      setPlayStoreSearchList(appArray);
      setPlayStoreCountryLoading(false);
    },
    onError() {},
  });

  const [getAppStoreApps] = useLazyQuery(APP_STORE_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const appArray = res?.appStoreAppSearch?.map((app) => ({
        value: app?.appId,
        label: (
          <div>
            <img src={app?.icon} alt={app?.appId} className="app-select-icon" />
            <span>{app?.title}</span>
          </div>
        ),
        dataUrl: app?.url,
        appStoreSearch: app,
      }));
      setAppStoreSearchList(appArray);
      setAppStoreCountryLoading(false);
    },
    onError() {},
  });

  useEffect(() => {
    if (dynamicLinkConfigData) {
      if (dynamicLinkConfigData?.iosCountryCode) {
        getAppStoreApps({
          variables: {
            data: {
              appName: dynamicLinkConfigData?.appStoreSearch?.title,
              country: dynamicLinkConfigData?.iosCountryCode,
            },
          },
        });
      }
      if (dynamicLinkConfigData?.androidCountryCode) {
        getPlayStoreApps({
          variables: {
            data: {
              appName: dynamicLinkConfigData?.playStoreSearch?.title,
              country: dynamicLinkConfigData?.androidCountryCode,
            },
          },
        });
      }

      const shaCertificates = dynamicLinkConfigData?.shaCertFingerprints?.map(
        (item) => ({
          shaCertFingerprints: item,
        }),
      );
      const formValues = {
        iosCountryCode: dynamicLinkConfigData?.iosCountryCode,
        appStoreSearch: dynamicLinkConfigData?.appStoreSearch?.appId,
        iosUriSchema: dynamicLinkConfigData?.iosUriSchema,
        iosTeamId: dynamicLinkConfigData?.iosTeamId,
        appstoreUrl: dynamicLinkConfigData?.appstoreUrl,
        androidCountryCode: dynamicLinkConfigData?.androidCountryCode,
        playStoreSearch: dynamicLinkConfigData?.playStoreSearch?.appId,
        androidUriSchema: dynamicLinkConfigData?.androidUriSchema,
        fingerprints: shaCertificates,
        playstoreUrl: dynamicLinkConfigData?.playstoreUrl,
        androidPackageName: !isInPlayStore
          ? dynamicLinkConfigData?.androidPackageName
          : null,
        androidDefaultFallbackUrl: !isAndroidApp
          ? dynamicLinkConfigData?.androidDefaultFallbackUrl
          : null,
        iosDefaultFallbackUrl: !isIosApp
          ? dynamicLinkConfigData?.iosDefaultFallbackUrl
          : null,
      };
      appInfoForm.setFieldsValue(formValues);
    }
  }, [dynamicLinkConfigData, appInfoForm]);

  const countryArray = Object?.entries(countryList)?.map(([value, label]) => ({
    value: value?.toUpperCase(),
    label,
  }));

  const filterOption = (input, option) =>
    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase());

  const onChangeIOS = (e) => {
    appInfoForm?.resetFields([
      'iosCountryCode',
      'appStoreSearch',
      'iosUriSchema',
      'iosTeamId',
      'appstoreUrl',
    ]);
    setData({
      ...data,
      appStoreSearch: null,
      appstoreUrl: null,
      iosAppId: null,
    });
    setIsIosApp(e?.target?.value);
  };

  const onChangeIOSAppStoreCheckBox = (e) => {
    appInfoForm?.resetFields([
      'iosCountryCode',
      'appStoreSearch',
      'iosUriSchema',
      'iosTeamId',
      'appstoreUrl',
    ]);
    setData({
      ...data,
      appStoreSearch: null,
      appstoreUrl: null,
      iosAppId: null,
    });
    setIsInAppStore(e?.target?.value);
  };

  const onChangeAndroid = (e) => {
    appInfoForm?.resetFields([
      'androidCountryCode',
      'playStoreSearch',
      'androidUriSchema',
      'shaCertFingerprints',
      'playstoreUrl',
      'fingerprints',
      'androidPackageName',
    ]);
    setData({
      ...data,
      playStoreSearch: null,
      playstoreUrl: null,
      androidPackageName: null,
    });
    setIsAndroidApp(e?.target?.value);
  };

  const onChangeAndroidPlayStoreCheckBox = (e) => {
    appInfoForm?.resetFields([
      'androidCountryCode',
      'playStoreSearch',
      'androidUriSchema',
      'shaCertFingerprints',
      'playstoreUrl',
      'fingerprints',
      'androidPackageName',
    ]);
    setData({
      ...data,
      playStoreSearch: null,
      playstoreUrl: null,
      androidPackageName: null,
    });
    setIsInPlayStore(e?.target?.value);
  };

  const onSearchAppStore = (value) => {
    if (!isEmpty(value)) {
      setAppStoreCountryLoading(true);
      getAppStoreApps({
        variables: {
          data: {
            appName: value,
            country: appStoreCountry,
          },
        },
      });
    }
  };

  const handleOnSearchAppStore = useCallback(debounce(onSearchAppStore, 500), [
    appStoreCountry,
  ]);

  const onSearchPlayStore = (value) => {
    if (!isEmpty(value)) {
      setPlayStoreCountryLoading(true);
      getPlayStoreApps({
        variables: {
          data: {
            appName: value,
            country: playStoreCountry,
          },
        },
      });
    }
  };

  const handleOnSearchPlayStore = useCallback(
    debounce(onSearchPlayStore, 500),
    [playStoreCountry],
  );

  return (
    <>
      <Form
        name="appInfoForm"
        form={appInfoForm}
        layout="vertical"
        initialValues={{
          fingerprints: [{}],
        }}
        onFinishFailed={() => setIsButtonDisable(false)}
      >
        <div className="d-flex align-center justify-between">
          <p className="title-text m-0">Do you have an IOS app?</p>
          <Radio.Group onChange={onChangeIOS} value={isIosApp}>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        <div className="mt-24">
          {isIosApp ? (
            <div>
              <Divider />
              <div className="d-flex align-center justify-between">
                <p className="title-text m-0 mb-8">Is it in the App store?</p>
                <Radio.Group
                  onChange={onChangeIOSAppStoreCheckBox}
                  value={isInAppStore}
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
              {isInAppStore ? (
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Select Country"
                      name="iosCountryCode"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                          message: 'Please select country',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select country"
                        className="country-select"
                        options={countryArray}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="App Store Search"
                      name="appStoreSearch"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                          message: 'Please select app',
                        },
                      ]}
                    >
                      <Select
                        className="company-app-name-input"
                        placeholder="e.g. com.company.appname"
                        showSearch
                        disabled={isInAppStore && !appStoreCountry}
                        onSearch={handleOnSearchAppStore}
                        options={appStoreSearchList}
                        loading={appStoreCountryLoading}
                        onSelect={(value, option) => {
                          setData({
                            ...data,
                            appStoreSearch: option?.appStoreSearch,
                            appstoreUrl: option?.appStoreSearch?.url,
                            iosAppId: option?.appStoreSearch?.appId,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="IOS URI Schema"
                      name="iosUriSchema"
                      className="mb-4 form-label"
                      rules={[uriScheme]}
                    >
                      <Input placeholder="IOS URI schema" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Team ID"
                      name="iosTeamId"
                      className="mb-4 form-label"
                      rules={[
                        checkIOSTeamId,
                        {
                          required: true,
                          message: 'Please enter Team ID',
                        },
                      ]}
                    >
                      <Input placeholder="Team ID" />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="Enter the link where we should send users if user clicks on
                  iOS"
                      name="appstoreUrl"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                        },
                        url(
                          () => {
                            setIsButtonDisable(false);
                          },
                          () => {
                            setIsButtonDisable(true);
                          },
                        ),
                      ]}
                    >
                      <Input placeholder="e.g. https://apps.apple.com/" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Bundle Identifier"
                      name="iosAppId"
                      className="mb-4 form-label"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="IOS Bundle Identifier" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="IOS URI Schema"
                      name="iosUriSchema"
                      className="mb-4 form-label"
                      rules={[uriScheme]}
                    >
                      <Input placeholder="IOS URI schema" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Team ID"
                      name="iosTeamId"
                      className="mb-4 form-label"
                      rules={[
                        checkIOSTeamId,
                        {
                          required: true,
                          message: 'Please enter Team ID',
                        },
                      ]}
                    >
                      <Input placeholder="Team ID" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Enter the link where we should send users if user clicks on
          iOS"
                  name="iosDefaultFallbackUrl"
                  className="mb-4 form-label"
                  rules={[
                    url(
                      () => {
                        setIsButtonDisable(false);
                      },
                      () => {
                        setIsButtonDisable(true);
                      },
                    ),
                  ]}
                >
                  <Input placeholder="e.g. https://apps.apple.com/" />
                </Form.Item>
                {isEmpty(appInfoForm.getFieldValue('appstoreUrl')) && (
                  <span className="warning-note">
                    If blank, users will be redireted to the website URL:{' '}
                    <a href={data?.defaultWebUrl} target="blank">
                      {data?.defaultWebUrl}
                    </a>
                  </span>
                )}
              </Col>
            </Row>
          )}
        </div>
        <div className="d-flex align-center justify-between mt-32">
          <p className="title-text m-0 mt-8">Do you have an Android app?</p>
          <Radio.Group onChange={onChangeAndroid} value={isAndroidApp}>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        <div className="mt-24">
          {isAndroidApp ? (
            <div>
              <Divider />
              <div className="d-flex align-center justify-between">
                <p className="title-text m-0">Is it in the Play store?</p>
                <Radio.Group
                  onChange={onChangeAndroidPlayStoreCheckBox}
                  value={isInPlayStore}
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
              {isInPlayStore ? (
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Select Country"
                      name="androidCountryCode"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                          message: 'Please select country',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select country"
                        className="country-select"
                        options={countryArray}
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Play Store Search"
                      name="playStoreSearch"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                          message: 'Please select app',
                        },
                      ]}
                    >
                      <Select
                        className="company-app-name-input"
                        placeholder="e.g. com.company.appname"
                        showSearch
                        disabled={isInPlayStore && !playStoreCountry}
                        onSearch={handleOnSearchPlayStore}
                        options={playStoreSearchList}
                        loading={playStoreCountryLoading}
                        onSelect={(value, option) => {
                          setData({
                            ...data,
                            playStoreSearch: option?.playStoreSearch,
                            playstoreUrl: option?.playStoreSearch?.url,
                            androidPackageName: option?.playStoreSearch?.appId,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>{getAndroidUriSchemaField()}</Col>
                  <Col span={24}>{getShaCertField()}</Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="Enter the link where we should send users if user clicks on
                  android"
                      name="playstoreUrl"
                      className="mb-4 form-label"
                      rules={[
                        {
                          required: true,
                        },
                        url(
                          () => {
                            setIsButtonDisable(false);
                          },
                          () => {
                            setIsButtonDisable(true);
                          },
                        ),
                      ]}
                    >
                      <Input placeholder="e.g. https://play.google.com/" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Android Package Name"
                      name="androidPackageName"
                      className="mb-4 form-label"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Android package name" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>{getAndroidUriSchemaField()}</Col>
                  <Col span={24}>{getShaCertField()}</Col>
                </Row>
              )}
            </div>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Enter the link where we should send users if user clicks on
          android"
                  name="androidDefaultFallbackUrl"
                  className="mb-4 form-label"
                  rules={[
                    url(
                      () => {
                        setIsButtonDisable(false);
                      },
                      () => {
                        setIsButtonDisable(true);
                      },
                    ),
                  ]}
                >
                  <Input placeholder="e.g. https://play.google.com/" />
                </Form.Item>
                {isEmpty(appInfoForm.getFieldValue('playstoreUrl')) && (
                  <span className="warning-note">
                    If blank, users will be redireted to the website URL:{' '}
                    <a href={data?.defaultWebUrl} target="blank">
                      {data?.defaultWebUrl}
                    </a>
                  </span>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Form>
    </>
  );
};

export default AppInfoForm;
