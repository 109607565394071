import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
} from 'antd';
import QRcode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import { UpgradeCrown } from '../../../assets/svg';
import defaultIcon from '../../../assets/svg/default-app.svg';
import documentCopy from '../../../assets/svg/document-copy.svg';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../../common/constants';
import {
  copyToClipboard,
  formValidatorRules,
  googleAnalyticsEvents,
} from '../../../common/utils';
import { usePlans } from '../../../context/PlanProvider';
import {
  CREATE_SHAREABLE_LINK,
  UPDATE_SHAREABLE_LINK,
} from '../graphql/mutations';
import '../style/app-details.less';

const { Option } = Select;

const { required, passwordLength } = formValidatorRules;

const BuildShareModal = ({
  setBuildShareModal,
  buildShareModal,
  setProFeatureModalOpen,
  rec,
  getApplicationDetails,
  editBuildShareModal,
  setEditBuildShareModal,
  response,
  getSharableLinks,
  setSelectedRow,
  setResponse,
  refetchTableData,
}) => {
  const history = useHistory();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamId, isFreePlan, teamSubscription } = usePlans();
  const [form] = Form?.useForm();

  const [checked, setChecked] = useState(!!response?.isPrivate);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [storeLink, setStoreLink] = useState(null);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [onChangeValue, setOnValueChange] = useState(false);

  const handleCancel = () => {
    form?.resetFields();
    setOnValueChange(false);
    setChecked(false);
    setResponse(null);
    setShowPopConfirm(false);
    setBuildShareModal(false);
    setEditBuildShareModal(false);
  };

  const uniqueId = rec?.applicationUniqueId;

  const [createShareableLink] = useMutation(CREATE_SHAREABLE_LINK, {
    onCompleted(res) {
      const length = res?.createShareableLink?.link?.split('/')?.length;
      setStoreData(res?.createShareableLink?.link?.split('/')?.[length - 1]);
      setStoreLink(res?.createShareableLink?.link);
      setSelectedRow(rec?.applicationUniqueId);
      getSharableLinks({
        variables: {
          data: {
            uniqueId: rec?.applicationUniqueId,
            skip: 0,
            limit: 10,
          },
        },
      });
    },
    onError(err) {
      return err;
    },
  });

  useEffect(() => {
    if (editBuildShareModal) {
      form?.setFieldsValue({
        expiryTime: response?.linkExpiration,
      });
      if (response?.isPrivate) {
        setChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (buildShareModal) {
      form?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildShareModal]);

  const copyUniqueId = () => {
    googleAnalyticsEvents(GA_EVENT?.RELEASE_SHARE_LINK_COPIED, {
      label: GA_LABEL?.RELEASE_SHARE_LINK_COPIED,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    copyToClipboard(storeLink || response?.link);
    messageContext?.success('Link copied to clipboard');
  };

  const [updateShareableLink] = useMutation(UPDATE_SHAREABLE_LINK, {
    onCompleted() {
      setSelectedRow(rec?.applicationUniqueId || response?.applicationUniqueId);
      getSharableLinks({
        variables: {
          data: {
            uniqueId: rec?.applicationUniqueId || response?.applicationUniqueId,
            skip: 0,
            limit: 10,
          },
        },
      });
    },
    onError() {
      setLoading(false);
    },
  });

  const handleUpdateLink = async (data) => {
    setOnValueChange(false);
    setShowPopConfirm(false);
    setLoading(true);
    try {
      const updateLink = await updateShareableLink({
        variables: {
          data: {
            uniqueId: rec?.applicationUniqueId || response?.applicationUniqueId,
            subLinkId: response?.subLinkId || storeData,
            expiryTime: data?.expiryTime,
            password: data?.password,
          },
        },
      });
      googleAnalyticsEvents(GA_EVENT?.LINK_UPDATE_SUCCESSFUL, {
        label: GA_LABEL?.LINK_UPDATE_SUCCESSFUL,
        member_id: currentUser?.id,
        expiry_duration: data?.expiryTime,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      if (updateLink?.data) {
        form?.resetFields();
        if (refetchTableData) {
          refetchTableData();
        }
        setLoading(false);
        setEditBuildShareModal(false);
        setChecked(false);
        setBuildShareModal(false);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleCreateLink = async (value) => {
    setOnValueChange(false);
    setShowPopConfirm(false);
    setLoading(true);
    try {
      const createLink = await createShareableLink({
        variables: {
          data: {
            expiryTime: value?.expiryTime,
            uniqueId,
            isProtected: checked,
            password: value?.password,
          },
        },
      });
      googleAnalyticsEvents(GA_EVENT?.CLICK_GENERATE_LINK, {
        label: GA_LABEL?.CLICK_GENERATE_LINK,
        member_id: currentUser?.id,
        expiry_duration: value?.expiryTime,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      if (createLink?.data) {
        setLoading(false);
        setEditBuildShareModal(true);
        setChecked(false);
        if (refetchTableData) {
          refetchTableData();
        }
        if (value?.password && checked) {
          googleAnalyticsEvents(GA_EVENT?.PASSWORD_SET, {
            label: GA_LABEL?.PASSWORD_SET,
            member_id: currentUser?.id,
            current_plan_name: teamSubscription?.subscriptionPlan?.name,
            current_plan_type: teamSubscription?.subscriptionPlan?.type,
          });
          setText('');
          setChecked(true);
        }
      }
      if (createLink?.errors) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const initialValue = {
    expiryTime: response?.linkExpiration || '7d',
  };

  return (
    <>
      <Modal
        centered
        destroyOnClose
        width={750}
        title="Share"
        maskClosable={false}
        open={buildShareModal || editBuildShareModal}
        closable={false}
        wrapClassName="share-modal"
        footer={[
          <div key="footer-share">
            <Popconfirm
              key="cancel"
              title="Are you sure you want to discard this changes?"
              onConfirm={handleCancel}
              onCancel={() => {
                setShowPopConfirm(false);
              }}
              okText="Discard Changes"
              okType="danger"
              open={showPopConfirm}
            >
              <Button
                htmlType="submit"
                className="font-500 cancel-btn"
                onClick={() =>
                  onChangeValue ? setShowPopConfirm(true) : handleCancel()
                }
              >
                Cancel
              </Button>
            </Popconfirm>
            <Button
              className="font-500"
              type="primary"
              htmlType="submit"
              onClick={form?.submit}
              loading={loading}
            >
              {editBuildShareModal ? 'Save' : 'Generate Link'}
            </Button>
          </div>,
        ]}
      >
        <div>
          <Form
            form={form}
            preserve={false}
            onFinish={editBuildShareModal ? handleUpdateLink : handleCreateLink}
            layout="vertical"
            onValuesChange={() => setOnValueChange(true)}
            initialValues={initialValue}
          >
            <div className="d-flex flex-horizontal justify-between">
              <div className="build-share-content">
                <div>
                  <Form.Item name="expiryTime" rules={[required]}>
                    <Select
                      popupClassName="expire-dropdown"
                      placeholder="Select expire period"
                      onChange={(item) => {
                        if ((item === '2m' || item === '3m') && isFreePlan) {
                          handleCancel();
                          setProFeatureModalOpen(true);
                        }
                      }}
                    >
                      <Option value="7d">Expires in 7 days</Option>
                      <Option value="15d">Expires in 15 days</Option>
                      <Option value="1m">Expires in 1 months</Option>
                      {['2m', '3m'].map((value) => (
                        <Option
                          key={value}
                          value={value}
                          className={
                            isFreePlan
                              ? 'select-display-flex-team-member-pro'
                              : ''
                          }
                        >
                          {`Expires in ${
                            value === '2m' ? '2 months' : '3 months'
                          }`}
                          {isFreePlan && (
                            <Button
                              type="primary"
                              size="small"
                              className="upgrade-button"
                              onClick={() => {
                                googleAnalyticsEvents(
                                  GA_EVENT?.PRO_RESTRICTION_BUTTON,
                                  {
                                    label: GA_LABEL?.PRO_RESTRICTION_BUTTON,
                                    member_id: currentUser?.id,
                                    current_plan_name:
                                      teamSubscription?.subscriptionPlan?.name,
                                    current_plan_type:
                                      teamSubscription?.subscriptionPlan?.type,
                                  },
                                );
                                history?.push(
                                  `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                                );
                              }}
                            >
                              Pro <UpgradeCrown />
                            </Button>
                          )}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {editBuildShareModal ? (
                    <>
                      <div className="build-qr">
                        <QRcode
                          value={storeLink || response?.link}
                          size={243}
                          className="build-qr"
                        />
                        <Row className="d-flex flex-horizontal justify-center copy-row">
                          <img
                            src={documentCopy}
                            alt="copy"
                            onClick={copyUniqueId}
                            className="document-copy"
                          />
                          <span className="copy-id">
                            {storeData || response?.subLinkId}
                          </span>
                        </Row>
                      </div>
                      <div className="build-share-divider">
                        <Divider />
                      </div>
                      <span className="build-qr-content">
                        Share this URL with everyone
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="build-qr">
                        <div className="build-qr-div">
                          <span className="build-qr-message">
                            Select expiry period before generating the link
                          </span>
                        </div>
                      </div>
                      <div className="build-share-divider">
                        <Divider />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="build-version-content">
                <Row className="app-logo-name-row">
                  <Col span={3}>
                    <Avatar
                      className="build-share-app-logo"
                      src={
                        getApplicationDetails?.getApplicationsReleaseDetails
                          ?.applicationData?.appLogo || defaultIcon
                      }
                      alt="teamLogo"
                    />
                  </Col>
                  <Col span={21}>
                    <span className="build-share-app-name">
                      {
                        getApplicationDetails?.getApplicationsReleaseDetails
                          ?.applicationData?.appName
                      }
                    </span>
                  </Col>
                </Row>
                <Row className="build-version-row">
                  <Col>
                    <span className="build-app-platform">
                      {rec?.appPlatform === 'IOS' ? (
                        <AppleFilled className="ios-color" />
                      ) : (
                        <AndroidFilled className="android-color" />
                      )}
                    </span>
                  </Col>
                  <Col>
                    <span className="build-version">
                      Version: {rec?.appVersion || response?.version}
                    </span>
                  </Col>
                  <Col>
                    <span className="build-number">
                      Build: {rec?.appBuild || response?.build}
                    </span>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="mb-24">
                    <span className="password-protected">
                      Protect With Password
                    </span>
                  </Col>
                  <Col>
                    <Switch
                      className="share-switch"
                      size="small"
                      checked={checked}
                      onChange={() => {
                        const isChecked = !checked;
                        googleAnalyticsEvents(
                          isChecked
                            ? GA_EVENT?.TOGGLE_PASSWORD_PROTECTION_ON
                            : GA_EVENT?.TOGGLE_PASSWORD_PROTECTION_OFF,
                          {
                            label: isChecked
                              ? GA_LABEL?.TOGGLE_PASSWORD_PROTECTION_ON
                              : GA_LABEL?.TOGGLE_PASSWORD_PROTECTION_OFF,
                            member_id: currentUser?.id,
                            current_plan_name:
                              teamSubscription?.subscriptionPlan?.name,
                            current_plan_type:
                              teamSubscription?.subscriptionPlan?.type,
                          },
                        );
                        if (isChecked) {
                          setText('');
                          setOnValueChange(true);
                        }
                        setChecked(isChecked);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        !editBuildShareModal
                          ? 'Set Password'
                          : 'Change Password'
                      }
                      name="password"
                      rules={checked && [required, passwordLength]}
                    >
                      <Input.Password
                        name="input"
                        type="password"
                        placeholder={
                          !editBuildShareModal
                            ? 'Password'
                            : 'Set a new password'
                        }
                        disabled={!checked}
                        value={text}
                        onChange={(e) => setText(e?.target?.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default BuildShareModal;
