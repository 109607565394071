import { Lock } from '@phosphor-icons/react';
import { Button, Modal } from 'antd';
import React from 'react';
import { MODAL_WIDTH } from '../common/constants';

const TesterAccessModal = ({ visible, setVisible }) => {
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <Modal
      wrapClassName="tester-access"
      width={MODAL_WIDTH}
      open={visible}
      title="Access Restricted"
      onCancel={handleCancel}
      centered
    >
      <div className="d-flex align-center flex-vertical justify-center">
        <Lock size={96} weight="thin" />
        <h4 className="mt-16 text-center">
          You don't have permission to perform this action. Only Owners, Admins,
          and Developers can proceed. Please contact your workspace Owner or
          Admin to upgrade your role if you need access.
        </h4>
        <Button className="mt-16" type="primary" onClick={handleCancel}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default TesterAccessModal;
