import { useMutation } from '@apollo/client';
import { Eye, PencilLine } from '@phosphor-icons/react';
import MDEditor from '@uiw/react-md-editor';
import { Button, Form, Input, Modal, Slider, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { ACTIONS } from '../../../../common/constants';
import { formValidatorRules } from '../../../../common/utils';
import AccessControl from '../../../../components/AccessControl';
import LoaderComponent from '../../../../components/LoaderComponent';
import { RELEASE_UPDATE_CODE_PUSH_APP } from '../graphql/mutations';
import { APP_DEPLOYMENT_HISTORY } from '../graphql/queries';

const { TextArea } = Input;

const EditRelease = ({ record, visible, setVisible, type, handleComplete }) => {
  const {
    state: { applicationName },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const { required } = formValidatorRules;
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [displayMarkdown, setDisplayMarkdown] = useState(false);
  const [states, setStates] = useState({
    enabled: record?.isDisabled ? 'Off' : 'On',
    requiredUpdate: record?.isMandatory ? 'On' : 'Off',
    description: record?.description ?? '',
    rollout: record?.rollout ?? 100,
  });

  const [releaseUpdateCodePushApp] = useMutation(RELEASE_UPDATE_CODE_PUSH_APP, {
    refetchQueries: [
      {
        query: APP_DEPLOYMENT_HISTORY,
        variables: { data: { name: applicationName, type } },
      },
    ],
    onCompleted() {
      setButtonLoading(false);
      handleComplete();
    },
    onError() {
      setButtonLoading(false);
    },
  });

  useEffect(() => {
    if (record)
      setStates({
        enabled: record?.isDisabled ? 'Off' : 'On',
        requiredUpdate: record?.isMandatory ? 'On' : 'Off',
        description: record?.description ?? '',
        rollout: record?.rollout ?? 100,
      });
    setLoading(false);
  }, [record]);

  const onFinish = () => {
    setButtonLoading(true);
    const rollout =
      record?.rollout === 100 ||
      record?.rollout === null ||
      record?.rollout === states?.rollout
        ? null
        : states?.rollout;
    releaseUpdateCodePushApp({
      variables: {
        data: {
          label: record?.label,
          appVersion: record?.appVersion,
          description: states?.description,
          isDisabled: states?.enabled === 'Off',
          isMandatory: states?.requiredUpdate === 'On',
          rollout,
          name: applicationName,
          type,
        },
      },
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSwitchChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value ? 'On' : 'Off' }));
  };

  const handleSliderChange = (value) => {
    const rolloutValue = record?.rollout ?? 100;
    if (value >= rolloutValue) {
      setStates((prev) => ({ ...prev, rollout: value }));
    }
  };

  return (
    <div>
      <Modal
        width={700}
        title={`Edit ${record?.label}`}
        centered
        destroyOnClose
        wrapClassName="edit-release-modal"
        onCancel={handleCancel}
        open={visible}
        footer={
          <AccessControl allowedPermissions={ACTIONS?.CODE_PUSH_RELEASE_EDIT}>
            <Button type="primary" onClick={onFinish} loading={buttonLoading}>
              Done
            </Button>
          </AccessControl>
        }
      >
        {loading ? (
          <LoaderComponent setHeight="300" />
        ) : (
          <Form
            name="layout-multiple-horizontal"
            layout="horizontal"
            form={form}
            initialValues={{
              version: record?.appVersion,
              description: record?.description,
              enabled: record?.isDisabled ? 'Off' : 'On',
              requiredUpdate: record?.isMandatory ? 'On' : 'Off',
              rollout: record?.rollout ?? 100,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              layout="vertical"
              label="Version"
              name="version"
              rules={[
                {
                  required,
                },
              ]}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="description-label-with-button"
              layout="vertical"
              label={
                <div className="d-flex justify-between align-center gap-16">
                  <span>Description</span>
                  <div className="d-flex justify-between align-center gap-8">
                    <div
                      className={`edit-icon ${
                        !displayMarkdown ? 'active' : ''
                      }`}
                      onClick={() => setDisplayMarkdown(false)}
                    >
                      <PencilLine size={16} />
                    </div>
                    <div
                      className={`edit-icon ${displayMarkdown ? 'active' : ''}`}
                      onClick={() => setDisplayMarkdown(true)}
                    >
                      <Eye size={16} />
                    </div>
                  </div>
                </div>
              }
              name="description"
              extra="Styling with Markdown is supported. 5000 characters or less."
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              {!displayMarkdown ? (
                <TextArea
                  onChange={(e) =>
                    setStates((prev) => ({
                      ...prev,
                      description: e?.target?.value,
                    }))
                  }
                  rows={17}
                  maxLength={5000}
                />
              ) : (
                <div className="markdown-text">
                  <MDEditor.Markdown
                    source={states?.description}
                    style={{ whiteSpace: 'pre-wrap' }}
                  />
                </div>
              )}
            </Form.Item>
            {['enabled', 'requiredUpdate']?.map((key) => (
              <Form.Item
                key={key}
                label={key === 'enabled' ? 'Enabled' : 'Required Update'}
                name={key}
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <div>
                  <div className="d-flex align-center gap-16">
                    <Switch
                      checked={states[key] === 'On'}
                      onChange={(value) => handleSwitchChange(key, value)}
                    />
                    <p className="value">{states[key]}</p>
                  </div>
                  {key === 'enabled' && (
                    <p className="desc m-0">
                      When disabled, this update will not be available to your
                      users.
                    </p>
                  )}
                </div>
              </Form.Item>
            ))}
            <Form.Item
              className="rollout-slider"
              name="rolloutSlider"
              label="Rollout"
              labelAlign="left"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <div className="d-block">
                <div className="d-flex align-center gap-16">
                  <Slider
                    value={states?.rollout}
                    onChange={handleSliderChange}
                    disabled={
                      record?.rollout === 100 || record?.rollout == null
                    }
                  />
                  <p className="m-0">{states?.rollout}%</p>
                </div>
                <p className="desc mb-0">
                  The percentage of users eligible for this update (value can
                  only be increased).
                </p>
              </div>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default EditRelease;
