import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CreateNewDynamicLink from './CreateNewDynamicLink';
import DynamicLink from './DynamicLink';

const DynamicLinkWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId`}
      component={DynamicLink}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId${ROUTES?.CREATE_NEW_DYNAMIC_LINK}`}
      component={CreateNewDynamicLink}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/:appId${ROUTES?.EDIT_DYNAMIC_LINK}`}
      component={CreateNewDynamicLink}
    />
  </Switch>
);

export default DynamicLinkWrapper;
