import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tag,
} from 'antd';
import { debounce, filter, map, trim, uniqBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import deleteIcon from '../../../assets/images/delete-icon.png';
import defaultIcon from '../../../assets/svg/default-app.svg';
import {
  ACTIONS,
  GA_EVENT,
  GA_LABEL,
  LIMIT,
  MODAL_WIDTH,
  ROLE_KEYS,
} from '../../../common/constants';
import {
  avatarColor,
  formValidatorRules,
  googleAnalyticsEvents,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import { usePlans } from '../../../context/PlanProvider';
import {
  CREATE_GROUP,
  DELETE_GROUP,
  DELETE_GROUP_MEMBER,
  INVITE_GROUP_MEMBER,
} from '../graphql/mutations';
import {
  GET_GROUP_APPS,
  GET_GROUP_MEMBERS_LIST,
  GET_MEMBER_APPS_LIST,
} from '../graphql/queries';
import '../style/teams.less';
import DeleteGroupModal from './DeleteGroupModal';

let debounceScroll;
let searchDebounce;
let debounceScrollMembers;

const { Option } = Select;
const { required } = formValidatorRules;

const GroupModal = ({
  handleModalVisible,
  groupModal,
  teamId,
  setIsEdit,
  isEdit,
  setSearchData,
  setLoaderList,
  record,
  groupLists,
  setUpdateTeamsComp,
}) => {
  const [form] = Form?.useForm();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription } = usePlans();
  const groupId = record?.id;
  const [loading, setLoading] = useState(false);
  const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false);
  const [getDetails, setGetDetails] = useState(null);
  const [editMember, setEditMember] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [loader, setLoader] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isGroupEdit, setIsGroupEdit] = useState(true);
  const [membersAppData, setMembersAppData] = useState([]);
  const [isEndMembers, setIsEndMembers] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [membersSearchFlag, setMembersSearchFlag] = useState(false);
  const [memberSearchValue, setMemberSearchValue] = useState('');
  const [debounceCall, setDebounceCall] = useState(0);
  const inputRef = useRef(null);

  const memberList = {
    teamId,
    skip: 0,
    limit: LIMIT,
  };

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onError(err) {
      setSearchData();
      setLoading(false);
      return err;
    },
  });

  const [createGroup] = useMutation(CREATE_GROUP, {
    onError(error) {
      setSearchData();
      setLoading(false);
      setLoaderList(false);
      return error;
    },
  });

  const [inviteGroupMember] = useMutation(INVITE_GROUP_MEMBER, {
    onError(error) {
      setSearchData();
      setLoading(false);
      setLoaderList(false);
      return error;
    },
  });

  const [getMemberAppsList] = useLazyQuery(GET_MEMBER_APPS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const appsDetails = res?.getMemberAppsList?.appsDetails || [];
      setIsEndMembers(appsDetails?.length < LIMIT);

      const updatedMemberData = membersSearchFlag
        ? uniqBy(appsDetails, 'id')
        : uniqBy([...(membersAppData || []), ...appsDetails], 'id');

      setMembersAppData(updatedMemberData);
      setMembersLoading(false);
    },
    onError: (error) => error,
  });

  const [
    getGroupAppsDetail,
    { data: getGroupApps, loading: appsLoading },
  ] = useLazyQuery(GET_GROUP_APPS, {
    fetchPolicy: 'network-only',
    onError(err) {
      return err;
    },
  });

  const [getGroupMembersList, { data: groupMembers }] = useLazyQuery(
    GET_GROUP_MEMBERS_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (scrollFlag) {
          const tempMemberData = [
            ...membersAppData,
            ...res?.getGroupMembersList?.groupMembers,
          ];
          setMembersData(uniqBy([...tempMemberData], 'id'));
          setScrollFlag(false);
        } else {
          setMembersData([
            ...uniqBy(res?.getGroupMembersList?.groupMembers, 'id'),
          ]);
        }
        setIsEnd(res?.getGroupMembersList?.groupMembers?.length < LIMIT);
        setIsGroupEdit(false);
        setLoader(false);
      },
      onError() {
        setLoader(false);
      },
    },
  );

  useEffect(() => {
    if (groupModal) {
      getMemberAppsList({
        variables: {
          data: memberList,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupModal]);

  useEffect(() => {
    if (groupId && isEdit) {
      getGroupAppsDetail({
        variables: {
          groupId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const filteredMemberData = useMemo(
    () =>
      map(
        filter(membersAppData, (value) => value?.role !== ROLE_KEYS?.OWNER),
        (details) => (
          <Option
            key={details?.id}
            value={details?.invitedUserEmail}
            label={
              details?.firstName
                ? trim(`${details?.firstName} ${details?.lastName}`)
                : details?.invitedUserEmail
            }
          >
            <Row className="row-item-height">
              <Col md={3} lg={3} xl={3} xxl={3}>
                <Avatar
                  size={38}
                  key={details?.firstName}
                  style={
                    !details?.profileImage && {
                      backgroundColor: avatarColor(details?.invitedUserEmail),
                    }
                  }
                  src={details?.profileImage}
                >
                  {details?.invitedUserEmail?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Col>
              <Col md={13} lg={13} xl={13} xxl={13}>
                <div className="d-flex flex-vertical ml-6">
                  {details?.userRegisterStatus === 'REGISTERED' ? (
                    <span className="name-tags">
                      {trim(
                        `${details?.firstName || ''} ${
                          details?.lastName || ''
                        }`,
                      )}
                    </span>
                  ) : (
                    <Tag className="tag-bg">Invited</Tag>
                  )}
                  <span className="font-12">{details?.invitedUserEmail}</span>
                </div>
              </Col>
              <Col
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                className="d-flex justify-end align-center"
              >
                <Tag className="group-modal-member-role-tag">
                  {details?.role}
                </Tag>
              </Col>
            </Row>
          </Option>
        ),
      ),
    [membersAppData],
  );

  const handleAddMemberInGroup = async (data) => {
    if (!data?.addMemberInGroup?.length) {
      messageContext?.error('Please Add Member(s)');
      return;
    }
    try {
      const addMemberInGroupResponse = await inviteGroupMember({
        variables: {
          email: data?.addMemberInGroup,
          groupIds: record?.id,
        },
      });
      if (addMemberInGroupResponse?.data) {
        setSearchData();
        setUpdateTeamsComp(true);
        setLoading(false);
        handleModalVisible(false);
        setIsEdit(false);
        getMemberAppsList({
          variables: {
            data: memberList,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const createGroups = await createGroup({
        variables: {
          groupName: data?.groupName,
          teamId,
        },
      });
      if (createGroups?.data) {
        googleAnalyticsEvents(GA_EVENT?.NEW_GROUP_CREATED, {
          label: GA_LABEL?.NEW_GROUP_CREATED,
          member_id: currentUser?.id,
          current_plan_name: teamSubscription?.subscriptionPlan?.name,
          current_plan_type: teamSubscription?.subscriptionPlan?.type,
        });
        setSearchData();
        setLoading(false);
        handleModalVisible(true);
        if (data?.addMembers) {
          try {
            const addMemberInGroupResponse = await inviteGroupMember({
              variables: {
                email: data?.addMembers,
                groupIds: createGroups?.data?.createGroup?.groupData?.id,
              },
            });
            if (addMemberInGroupResponse?.data) {
              groupLists({
                variables: {
                  data: {
                    teamId,
                    skip: 0,
                    limit: LIMIT,
                  },
                },
              });
              setLoading(false);
              handleModalVisible(false);
            }
          } catch {
            setLoading(false);
          }
        } else {
          groupLists({
            variables: {
              data: {
                teamId,
                skip: 0,
                limit: LIMIT,
              },
            },
          });
          setLoading(false);
          handleModalVisible(false);
        }
        form?.resetFields();
      }
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupId && isEdit) {
      getGroupMembersList({
        variables: {
          data: {
            groupId,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, isEdit]);

  const handleDeleteGroup = async () => {
    setLoading(true);
    setUpdateTeamsComp(true);
    try {
      const deleteGroups = await deleteGroup({
        variables: {
          groupId: record?.id,
        },
      });
      if (deleteGroups) {
        setSearchData();
        setLoaderList(true);
        setIsEdit(false);
        setLoading(false);
        handleModalVisible(false);
        setDeleteGroupModalVisible(false);
      }
      groupLists({
        variables: {
          data: {
            teamId,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const [deleteMembersFromGroup] = useMutation(DELETE_GROUP_MEMBER, {
    onError(error) {
      setLoading(false);
      return error;
    },
  });

  const handleDeleteMemberFromGroup = async () => {
    setLoading(true);
    try {
      const deleteMember = await deleteMembersFromGroup({
        variables: {
          groupMemberId: getDetails?.id,
        },
      });
      if (deleteMember) {
        setIsEdit(false);
        setUpdateTeamsComp(true);
        setLoading(false);
        handleModalVisible(false);
        setDeleteGroupModalVisible(false);
      }
      groupLists({
        variables: {
          data: {
            teamId,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    } catch (error) {
      return error;
    }
  };

  const deleteMemberFromGroup = (value) => {
    setEditMember(true);
    setDeleteGroupModalVisible(true);
    setGetDetails(value);
  };

  const handleCancel = () => {
    form?.resetFields();
    handleModalVisible(false);
    setIsEdit(false);
  };

  const deleteGroupModal = () => {
    setDeleteGroupModalVisible(true);
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        getGroupMembersList({
          variables: {
            data: {
              groupId,
              skip: membersData?.length,
              limit: LIMIT,
            },
          },
        });
      }
    }, 500);
    debounceScroll();
  };

  const onMembersScroll = (event) => {
    setMembersSearchFlag(false);
    if (debounceScrollMembers) {
      debounceScrollMembers?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    debounceScrollMembers = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
      if (scrolledToBottom && !isEndMembers) {
        setMembersLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getMemberAppsList({
          variables: {
            data: {
              teamId,
              skip: (debounceCall + 1) * LIMIT,
              limit: LIMIT,
              search: memberSearchValue,
            },
          },
        });
      }
    }, 500);

    debounceScrollMembers();
  };

  const getSearchData = (value) => {
    getGroupAppsDetail({
      variables: {
        groupId: record?.id,
        search: value,
      },
    });
  };

  const handleSearch = (e) => {
    const { value } = e?.target;
    setSearchValue(value);

    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }

    searchDebounce = debounce(getSearchData, 500);
    searchDebounce(value);
  };

  const handleMemberBlur = () => {
    setMemberSearchValue('');
    setDebounceCall(0);
    setIsEndMembers(false);
    setMembersSearchFlag(true);
    getMemberAppsList({
      variables: {
        data: memberList,
      },
    });
  };

  const handleMemberChange = (value) => {
    setMembersSearchFlag(true);
    setSearchValue(value);
    setMembersLoading(true);
    getMemberAppsList({
      variables: {
        data: {
          ...memberList,
          search: value,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMemberHandler = useCallback(
    debounce(handleMemberChange, 500),
    [],
  );

  const handleMemberClear = () => {
    setMembersAppData([]);
    getMemberAppsList({
      variables: {
        data: memberList,
      },
    });
  };

  useEffect(() => {
    if (groupModal) {
      setTimeout(() => inputRef?.current?.focus(), 100);
    }
  }, [groupModal]);

  const groupData = membersData?.map((value) => (
    <div key={value?.id} className="member-list-hover member-list-row">
      <Row className="group-member-lists">
        <Col span={3}>
          <Avatar
            size={38}
            key={value?.firstName}
            className="edit-group-list-avatar-icon"
            style={
              !value?.profileImage && {
                backgroundColor: avatarColor(
                  value?.firstName || value?.invitedUserEmail,
                ),
              }
            }
            src={value?.profileImage}
          >
            <span className="font-500">
              {value?.firstName?.charAt(0)?.toUpperCase() ||
                value?.invitedUserEmail?.charAt(0)?.toUpperCase()}
            </span>
          </Avatar>
        </Col>
        <Col span={14}>
          <div className="member-tag">
            {value?.userRegisterStatus === 'REGISTERED' ? (
              <div className="name-tags">
                {trim(`${value?.firstName || ''} ${value?.lastName || ''}`)}
              </div>
            ) : (
              <Tag className="tag-bg">Invited</Tag>
            )}
            <span className="line-16">{value?.invitedUserEmail}</span>
          </div>
        </Col>
        <Col span={5}>
          <Tag className="member-tag-role">{value?.role}</Tag>
        </Col>
        <AccessControl allowedPermissions={ACTIONS?.REMOVE_MEMBER}>
          <Col span={2} className="remove-member-column">
            {value?.role !== ROLE_KEYS?.OWNER &&
              value?.userId !== currentUser?.id && (
                <span
                  className="remove-member-text"
                  onClick={() => deleteMemberFromGroup(value)}
                >
                  <img src={deleteIcon} alt="img" className="delete-icon" />
                </span>
              )}
          </Col>
        </AccessControl>
        {loader && <Spin />}
      </Row>
    </div>
  ));

  const items = [
    {
      key: '1',
      label: (
        <div className="d-flex">
          <span className="mr-10">Members</span>
          <Tag className="count mr-0">
            {groupMembers?.getGroupMembersList?.membersCount}
          </Tag>
        </div>
      ),
      children: (
        <>
          {AccessControl({
            allowedPermissions: ACTIONS?.ADD_MEMBER,
          }) && (
            <div className="edit-group">
              <Form
                preserve={false}
                form={form}
                name="addNewMembers"
                className="add-new-members"
                layout="vertical"
                onFinish={handleAddMemberInGroup}
              >
                <Row>
                  <Col span={20}>
                    <Form.Item
                      className="add-member-form-item"
                      name="addMemberInGroup"
                    >
                      <Select
                        placeholder={
                          <div>
                            <SearchOutlined className="mr-8" />
                            <span>Search</span>
                          </div>
                        }
                        allowClear
                        mode="multiple"
                        optionLabelProp="label"
                        loading={membersLoading}
                        onPopupScroll={onMembersScroll}
                        onBlur={handleMemberBlur}
                        onSearch={debouncedMemberHandler}
                        onClear={handleMemberClear}
                        notFoundContent={
                          membersLoading ? (
                            <div className="d-flex justify-center align-center">
                              <Spin />
                            </div>
                          ) : (
                            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        popupClassName="member-data-select"
                      >
                        {filteredMemberData}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item className="add-member-button-form-item">
                      <Button
                        type="primary"
                        className="add-member-btn"
                        htmlType="submit"
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          <div className="group-members-list" onScroll={handleScroll}>
            {groupData}
          </div>
        </>
      ),
    },
    {
      key: '2',
      label: (
        <div className="d-flex">
          <span className="mr-10">Shared Apps</span>
          <Tag className="count mr-0">{getGroupApps?.getGroupApps?.count}</Tag>
        </div>
      ),
      children: (
        <>
          <div className="shared-app-row-div">
            {(getGroupApps?.getGroupApps?.groupApps?.length > 0 ||
              searchValue?.length > 0 ||
              !appsLoading) && (
              <div className="edit-group">
                <Row>
                  <Col span={24}>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="Search"
                      onChange={handleSearch}
                      allowClear
                      value={searchValue}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <div className="group-members-list">
              <Spin spinning={appsLoading}>
                {getGroupApps?.getGroupApps?.groupApps?.length > 0 ? (
                  getGroupApps?.getGroupApps?.groupApps?.map((appValue) => (
                    <div className="shared-app-row" key={appValue?.id}>
                      <div className="width-percent-10 mr-4 ml-16">
                        <Avatar
                          size={38}
                          className="shared-app-avatar"
                          key={appValue?.id}
                          src={appValue?.appLogo || defaultIcon}
                        />
                      </div>
                      <div className="width-percent-90">
                        <span className="shared-app-name">
                          {appValue?.appName}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty description="No Data" />
                )}
              </Spin>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Modal
        width={MODAL_WIDTH}
        title={isEdit ? record?.groupName : 'Create Group'}
        centered
        destroyOnClose
        wrapClassName="group-modal"
        onCancel={handleCancel}
        open={groupModal}
        footer={
          <>
            {AccessControl({ allowedPermissions: ACTIONS?.DELETE_GROUP }) &&
              !!isEdit && (
                <div className="is-edit-group-footer">
                  <img src={deleteIcon} alt="img" className="delete-icon" />
                  <span
                    className="delete-group-text"
                    onClick={deleteGroupModal}
                  >
                    Delete Group
                  </span>
                </div>
              )}
            {AccessControl({
              allowedPermissions: ACTIONS?.CREATE_GROUP,
            }) &&
              !isEdit && (
                <Form.Item className="form-item">
                  <Button
                    className="invite-button"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={form?.submit}
                  >
                    Create
                  </Button>
                </Form.Item>
              )}
          </>
        }
      >
        {isEdit ? (
          <Spin direction="horizontal" spinning={isEdit && isGroupEdit}>
            <div>
              <Tabs
                defaultActiveKey="1"
                className="edit-group-tabs"
                items={items}
              />
            </div>
          </Spin>
        ) : (
          AccessControl({ allowedPermissions: ACTIONS?.CREATE_GROUP }) && (
            <Form
              form={form}
              name="addNewMembers"
              className="add-new-groups"
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Group Name"
                    className="group-list"
                    name="groupName"
                    rules={[required]}
                  >
                    <Input placeholder="Enter Group Name" ref={inputRef} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Add Member(s)" name="addMembers">
                    <Select
                      placeholder="Add Member(s)"
                      allowClear
                      mode="multiple"
                      optionLabelProp="label"
                      loading={membersLoading}
                      onPopupScroll={onMembersScroll}
                      onBlur={handleMemberBlur}
                      onSearch={debouncedMemberHandler}
                      onClear={handleMemberClear}
                      notFoundContent={
                        membersLoading ? (
                          <div className="d-flex justify-center align-center">
                            <Spin />
                          </div>
                        ) : (
                          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      popupClassName="member-data-select"
                    >
                      {filteredMemberData}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )
        )}
      </Modal>
      <DeleteGroupModal
        record={record}
        setDeleteGroupModalVisible={setDeleteGroupModalVisible}
        deleteGroupModalVisible={deleteGroupModalVisible}
        handleDeleteGroup={handleDeleteGroup}
        loading={loading}
        handleDeleteMemberFromGroup={handleDeleteMemberFromGroup}
        editMember={editMember}
        setEditMember={setEditMember}
        getDetails={getDetails}
      />
    </div>
  );
};

export default GroupModal;
