import { useLazyQuery } from '@apollo/client';
import { Button, Carousel, Col, Empty, Input, Row } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PlanCoverApp, SearchIcon, UpgradeCrown } from '../../assets/svg';
import {
  ACTIONS,
  APP_LIST_LIMIT,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
} from '../../common/constants';
import {
  checkTesterAccess,
  googleAnalyticsEvents,
  upgradeToProEvent,
} from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import TesterAccessModal from '../../components/TesterAccessModal';
import { usePlans } from '../../context/PlanProvider';
import { GET_USER_TEAM } from '../auth/graphql/queries';
import NoApps from './NoApps';
import './app-services.less';
import AppCard from './components/AppCard';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import { GET_APPS } from './graphql/queries';
import './style/app-list.less';

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

let searchDebounce = null;
let debounceScroll;

const AppList = () => {
  const history = useHistory();
  const [testerModal, setTesterModal] = useState(false);
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [appData, setAppData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchData, setSearchData] = useState();
  const [scrollFlag, setScrollFlag] = useState(false);

  const query = getQuery();

  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);

  const {
    setCurrentRole,
    teamSubscription,
    isFreePlan,
    teamPlanUsage,
    teamPlanTotalUsage,
    setTeamId,
    teamId,
    userTeams,
    currentRole,
  } = usePlans();

  useEffect(() => {
    if (query?.get('teamId')) {
      setTeamId(query?.get('teamId'));
      history?.push(ROUTES?.APPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getUserTeams] = useLazyQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.getUserTeams?.length <= 0) {
        dispatch({
          type: 'SET_TEAM',
          data: null,
        });
        history?.push(ROUTES?.ONBOARD);
      }
    },
    onError() {},
  });

  const [executeSearch, { loading }] = useLazyQuery(GET_APPS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const appsList = res?.getAppsList?.appsDetails || [];
      const updatedAppsData = scrollFlag ? [...appData, ...appsList] : appsList;
      setAppData(uniqBy(updatedAppsData, 'id'));
      setIsEnd(appsList?.length < APP_LIST_LIMIT);
      setIsLoading(false);
      setScrollFlag(false);
      const currentTeam = userTeams?.find((data) => data?.id === teamId);
      if (currentTeam) setCurrentRole(currentTeam?.role);
    },
    onError() {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (teamId !== 'null') {
      executeSearch({
        variables: {
          data: {
            teamId,
            skip: 0,
            limit: APP_LIST_LIMIT,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    getUserTeams();
  }, []);
  const handleCreate = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.CREATE_APP)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_ON_CREATE_APP, {
        label: GA_LABEL?.CLICK_ON_CREATE_APP,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      if (teamPlanUsage?.app >= Number(teamPlanTotalUsage?.APP)) {
        setProFeatureModalOpen(true);
      } else {
        history?.push(ROUTES?.CREATE_APP);
      }
    } else {
      setTesterModal(true);
    }
  };

  const handleAppSearch = (value) => {
    googleAnalyticsEvents(GA_EVENT?.APP_SEARCH, {
      label: GA_LABEL?.APP_SEARCH,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    executeSearch({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: APP_LIST_LIMIT,
          search: value,
        },
      },
    });
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 15;

      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        executeSearch({
          variables: {
            data: {
              teamId,
              skip: appData?.length,
              limit: APP_LIST_LIMIT,
            },
          },
        });
      }
    }, 500);
    debounceScroll();
  };

  const handleChange = ({ target: { value } }) => {
    setSearchData(value);
    setScrollFlag(false);
    setIsLoading(true);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  return (
    <>
      {!appData?.length && !searchData?.length && !isLoading ? (
        <NoApps />
      ) : (
        <div className="apps-content">
          <div className="apps-header">
            <Row
              className="app-list-row"
              justify="space-between"
              align="middle"
            >
              <Col>
                <h1>
                  Your Apps
                  {teamPlanUsage &&
                    `(${teamPlanUsage?.app}/${teamPlanTotalUsage?.APP})`}
                </h1>
              </Col>
              <Col className="search-col">
                <Input
                  placeholder="Search"
                  className="app-search-input"
                  allowClear
                  onChange={handleChange}
                  suffix={<SearchIcon />}
                />
                <Button
                  type="primary"
                  onClick={handleCreate}
                  className="create-app-btn"
                >
                  Create App
                </Button>
              </Col>
            </Row>
          </div>
          {isLoading ? (
            <LoaderComponent setHeight={170} />
          ) : (
            <>
              {appData?.length > 0 ? (
                <div
                  className={`${
                    loading ? 'app-list flex-vertical' : 'app-list'
                  }`}
                  onScroll={handleScroll}
                >
                  <Row className="app-card-row" gutter={[16, 24]}>
                    {appData?.map((app) => (
                      <AppCard key={app?.id} app={app} />
                    ))}
                  </Row>
                  {(loading || appData?.length === 0) && (
                    <LoaderComponent setHeight={5} />
                  )}
                </div>
              ) : (
                <div className="empty-data app-list-empty">
                  <Empty description="No Data" />
                </div>
              )}
            </>
          )}
          {proFeatureModalOpen && (
            <AppServiceProFeatureModal
              title="Apps"
              visible={proFeatureModalOpen}
              setVisible={setProFeatureModalOpen}
              footer={
                <div className="d-flex justify-center gap-24">
                  {isFreePlan && (
                    <Button
                      type="primary"
                      className="upgrade-button"
                      onClick={() => {
                        upgradeToProEvent(currentUser, teamSubscription);
                        history?.push(
                          `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                        );
                      }}
                    >
                      <UpgradeCrown />
                      Upgrade to pro
                    </Button>
                  )}
                  <Button
                    className="add-ons-upgrade-button"
                    onClick={() => {
                      history?.push(
                        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/${teamId}`,
                      );
                    }}
                  >
                    Purchase add-ons
                  </Button>
                </div>
              }
              content={
                <Carousel autoplay draggable>
                  <div className="app-service-carousel">
                    <PlanCoverApp />
                    <div className="text">
                      {isFreePlan ? (
                        <p>
                          Want more no. of apps? Increase the no. of apps to
                          your current workspace.
                        </p>
                      ) : (
                        <p>Please purchase App add-ons to add new apps.</p>
                      )}
                    </div>
                  </div>
                </Carousel>
              }
            />
          )}
          {testerModal && (
            <TesterAccessModal
              visible={testerModal}
              setVisible={setTesterModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppList;
