import { Layout, Menu } from 'antd';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  BillingHistoryIcon,
  CardIcon,
  ManageAddOnIcon,
  SettingIcon,
} from '../../assets/svg';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import { usePlans } from '../../context/PlanProvider';
import './workspace-header.less';

const { Header } = Layout;

const WorkspaceHeader = () => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const { teamId, refetchPlanDetails, refetchAddons } = usePlans();

  useEffect(() => {
    if (teamId) {
      refetchPlanDetails();
      if (location?.pathname?.includes(ROUTES.MANAGE_ADD_ON)) {
        refetchAddons();
      }
    }
  }, [location, refetchPlanDetails, refetchAddons]);

  const headerItems = [
    {
      key: 'settings',
      label: (
        <Link
          to={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.WORKSPACE_SETTINGS}/${teamId}`}
        >
          <div className="d-flex align-center gap-8">
            <SettingIcon />
            Workspace Settings
          </div>
        </Link>
      ),
    },
    AccessControl({
      allowedPermissions: ACTIONS?.PLANS_AND_PAYMENTS,
    }) && {
      key: 'plan-payment',
      label: (
        <Link
          to={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`}
        >
          <div className="d-flex align-center gap-8">
            <CardIcon />
            Plan and Payment
          </div>
        </Link>
      ),
    },
    AccessControl({
      allowedPermissions: ACTIONS?.PLANS_AND_PAYMENTS,
    }) && {
      key: 'manage-add-ons',
      label: (
        <Link
          to={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/${teamId}`}
        >
          <div className="d-flex align-center gap-8">
            <ManageAddOnIcon />
            Manage Add-ons
          </div>
        </Link>
      ),
    },
    AccessControl({
      allowedPermissions: ACTIONS?.PLANS_AND_PAYMENTS,
    }) && {
      key: 'billing-history',
      label: (
        <Link
          to={`${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.BILLING_HISTORY}/${teamId}`}
        >
          <div className="d-flex align-center gap-8">
            <BillingHistoryIcon />
            Billing History
          </div>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Header className="workspace-header">
        <div className="workspace-header-layout d-flex justify-center align-center">
          <Menu
            mode="horizontal"
            selectedKeys={setLocation?.[2]}
            className="d-flex justify-center"
            items={headerItems}
          />
        </div>
      </Header>
    </>
  );
};

export default WorkspaceHeader;
