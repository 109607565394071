import { Button, Modal } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import { MODAL_WIDTH } from '../../../common/constants';

const DeleteMemberModal = ({
  deleteMemberModal,
  setDeleteMemberModal,
  record,
  handleDeleteMember,
  loading,
}) => {
  const handleCancel = () => {
    setDeleteMemberModal(false);
  };

  return (
    <Modal
      width={MODAL_WIDTH}
      title="Delete Member"
      centered
      destroyOnClose
      wrapClassName="delete-member-modal"
      onCancel={handleCancel}
      open={deleteMemberModal}
      footer={[
        <div key="delete-member">
          <Button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="delete-btn"
            type="primary"
            danger
            loading={loading}
            onClick={handleDeleteMember}
          >
            Delete
          </Button>
        </div>,
      ]}
    >
      <div className="delete-member">
        <span>
          Are you sure you want to remove{' '}
          <b>
            {record?.firstName || record?.lastName
              ? trim(`${record?.firstName || ''} ${record?.lastName || ''}`)
              : record?.invitedUserEmail}
          </b>{' '}
          from workspace?
        </span>
      </div>
    </Modal>
  );
};

export default DeleteMemberModal;
