import { useQuery } from '@apollo/client';
import { Layout } from 'antd';
import Gleap from 'gleap';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { PLANS, ROUTES } from '../common/constants';
import LoaderComponent from '../components/LoaderComponent';
import { usePlans } from '../context/PlanProvider';
import { GET_USER } from '../modules/users/graphql/queries';
import './App.less';
import ContentRoutes from './ContentRoutes';
import BannerLegacyUser from './components/BannerLegacyUser';
import AppHeader from './components/header/AppHeader';

const { Content } = Layout;

const App = () => {
  const {
    setCurrentUser,
    appLoading,
    state: { loading, currentUser },
  } = useContext(AppContext);
  const { teamSubscription } = usePlans();
  const location = useLocation();
  const legacyShow = process.env.REACT_APP_LEGACY_BANNER_SHOW === 'true';
  const isLegacyUser =
    teamSubscription?.subscriptionPlan?.name === PLANS?.LEGACY;
  const [bannerDisplay, setBannerDisplay] = useState(false);

  useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setCurrentUser(res?.user);
      appLoading(false);
    },
    onError() {
      appLoading(false);
    },
  });

  useEffect(() => {
    setBannerDisplay(isLegacyUser && legacyShow);
  }, [isLegacyUser, legacyShow]);

  useEffect(() => {
    if (currentUser) {
      Gleap?.identify(currentUser?.id, {
        name: currentUser?.name,
        email: currentUser?.email,
      });
    }
    return () => Gleap?.clearIdentity();
  }, [currentUser]);

  if (loading) return <LoaderComponent />;

  return (
    <Layout className="gx-app-layout">
      <AppHeader />
      {bannerDisplay &&
        !location?.pathname?.includes(ROUTES?.ONBOARD) &&
        !location?.pathname?.includes(ROUTES?.CODE_PUSH_AUTHENTICATION) && (
          <BannerLegacyUser handleVisible={setBannerDisplay} />
        )}
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          <ContentRoutes />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
