/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  CREATE_APP: '/create-app',
  LOGOUT: '/logout',
  LOGIN: '/login',
  REFRESH_TOKEN: '/refresh-token',
  ACCESS_TOKEN: '/access-denied',
  APPS: '/apps',
  DETAILS: '/app-details',
  RELEASES: '/releases',
  RELEASE: '/upload-release',
  APPS_SERVICES: '/apps-services',
  MEMBERS: '/members',
  UPDATE: '/update',
  MAINTENANCE: '/maintenance',
  API_KEY: '/api-key',
  SHARE: '/app-share',
  DYNAMIC_LINK: '/dynamic-link',
  CREATE_NEW_DYNAMIC_LINK: '/create-new-dynamic-link',
  EDIT_DYNAMIC_LINK: '/edit-dynamic-link',
  TEAMS: '/teams',
  INTEGRATIONS: '/integrations',
  APP_DETAIL: '/app-detail',
  VERIFY_PASSWORD: '/verify-password',
  INSTALL: '/install',
  PROFILE_SETTINGS: '/profile-settings',
  WORKSPACE_DETAILS: '/workspace-details',
  WORKSPACE_SETTINGS: '/settings',
  PLAN_AND_PAYMENT: '/plan-payment',
  MANAGE_ADD_ONS: '/manage-add-ons',
  USERS_MANAGEMENT: '/users',
  CREATE_USERS: '/create-users',
  IOS_UDID: '/get-udid',
  FEEDBACKS: '/feedbacks',
  CODE_PUSH: '/codepush',
  ANDROID: '/android',
  IOS: '/ios',
  CODE_PUSH_AUTHENTICATION: '/codepush-authentication',

  // status
  UNAUTHORIZED: '/unauthorized',

  // onboarding steps
  ONBOARD: '/onboard-steps',
  WORKSPACE: '/workspace',
  APP_CREATE: '/app-create',
  INVITE_MEMBERS: '/invite-members',

  // app-release-details
  VERIFY_USER: '/verify-user',
  CREATE_USER: '/create-user',
  APP_SETTINGS: '/app-settings',
  BUILD_SHARE: '/build-share',
  CREATE_RELEASE: '/create-release',
  NEW_RELEASE: '/new-release',

  // billing history
  BILLING_HISTORY: '/billing-history',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Management',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const TEAM_KEY = 'TEAM_KEY';
export const APPLICATION_NAME = 'APPLICATION_NAME';
export const APPLICATION_ID = 'APPLICATION_ID';
export const CURRENT_EMAIL = 'CURRENT_EMAIL';
export const IS_NEW_USER = 'IS_NEW_USER';
export const REDIRECT_WAITING = 'redirectWaiting';
export const SMART_BANNER_OPEN = 'SMART_BANNER_OPEN';
export const CODE_PUSH_APPLICATION_ID_LOGIN = 'CODE_PUSH_APPLICATION_ID_LOGIN';
export const CODE_PUSH_LIMIT_EXHAUST = 'CODE_PUSH_LIMIT_EXHAUST';
export const NEW_FEATURE_POPUP = 'NEW_FEATURE_POPUP';
export const LEGACY_USER_EXPIRED = 'LEGACY_USER_EXPIRED';
export const LEGACY_USER_FREE = 'LEGACY_USER_FREE';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  DEVELOPER: 'DEVELOPER',
  TESTER: 'TESTER',
};

const { ADMIN, OWNER, DEVELOPER } = ROLE_KEYS;

export const ACTIONS = {
  CREATE_APP: 'CREATE_APP',
  UPDATE_APP: 'UPDATE_APP',
  DELETE_APP: 'DELETE_APP',
  CREATE_TEAM: 'CREATE_TEAM',
  CREATE_GROUP: 'CREATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',
  ADD_MEMBER: 'ADD_MEMBER',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  ADD_APP_MEMBER: 'ADD_APP_MEMBER',
  REMOVE_APP_MEMBER: 'REMOVE_APP_MEMBER',
  APP_RELEASE: 'APP_RELEASE',
  DELETE_RELEASE: 'DELETE_RELEASE',
  DELETE_FEEDBACK: 'DELETE_FEEDBACK',
  APP_SHARE: 'APP_SHARE',
  UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
  UPDATE_WORKSPACE: 'UPDATE_WORKSPACE',
  UPDATE_APP_SERVICE: 'UPDATE_APP_SERVICE',
  FEEDBACK_RESOLVED_ARCHIVED: 'FEEDBACK_RESOLVED_ARCHIVED',
  INTEGRATION_SETTINGS: 'INTEGRATION_SETTINGS',
  PLANS_AND_PAYMENTS: 'PLANS_AND_PAYMENTS',
  DYNAMIC_LINK: 'DYNAMIC_LINK',
  CODE_PUSH_AUTH: 'CODE_PUSH_AUTH',
  CODE_PUSH_GENERATE_DEPLOYMENT: 'CODE_PUSH_GENERATE_DEPLOYMENT',
  CODE_PUSH_RELEASE_EDIT: 'CODE_PUSH_RELEASE_EDIT',
};

export const PERMISSIONS = {
  CREATE_APP: [ADMIN, OWNER, DEVELOPER],
  UPDATE_APP: [ADMIN, OWNER],
  APP_RELEASE: [ADMIN, OWNER, DEVELOPER],
  APP_SHARE: [ADMIN, OWNER, DEVELOPER],
  DELETE_RELEASE: [ADMIN, OWNER],
  DELETE_APP: [ADMIN, OWNER],
  CREATE_TEAM: [ADMIN, OWNER],
  UPDATE_TEAM_MEMBER: [ADMIN, OWNER],
  DELETE_TEAM: [OWNER],
  CREATE_GROUP: [ADMIN, OWNER],
  EDIT_GROUP: [ADMIN, OWNER],
  DELETE_GROUP: [ADMIN, OWNER],
  ADD_MEMBER: [ADMIN, OWNER],
  REMOVE_MEMBER: [ADMIN, OWNER],
  ADD_APP_MEMBER: [ADMIN, OWNER],
  REMOVE_APP_MEMBER: [ADMIN, OWNER],
  UPDATE_WORKSPACE: [ADMIN, OWNER],
  UPDATE_APP_SERVICE: [ADMIN, OWNER, DEVELOPER],
  FEEDBACK_RESOLVED_ARCHIVED: [ADMIN, OWNER],
  INTEGRATION_SETTINGS: [ADMIN, OWNER],
  PLANS_AND_PAYMENTS: [OWNER],
  DYNAMIC_LINK: [OWNER],
  CODE_PUSH_AUTH: [ADMIN, OWNER, DEVELOPER],
  CODE_PUSH_GENERATE_DEPLOYMENT: [ADMIN, OWNER, DEVELOPER],
  CODE_PUSH_RELEASE_EDIT: [ADMIN, OWNER, DEVELOPER],
};

/* OTP */
export const OTP_SIZE = 6;
export const OTP_ATTEMPT = 3;

/* Limit */
export const LIMIT = 10;
export const APP_LIST_LIMIT = 20;
export const DYNAMIC_LINK_LIMIT = 4;

export const SORT_BY = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DYNAMIC_LINK_FILTER = [
  {
    value: 'LAST_WEEK',
    label: 'Last 7 Days',
  },
  {
    value: 'LAST_MONTH',
    label: 'Last month',
  },
  {
    value: 'LAST_YEAR',
    label: 'Last year',
  },
  {
    value: 'ALL_TIME',
    label: 'All time',
  },
  {
    value: 'CUSTOM',
    label: 'Custom',
  },
];

export const ALL_TIME = 'ALL_TIME';

export const SORT_ON = {
  createdAt: 'createdAt',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const ALL_APK_UPLOAD = ['apk'];
export const ALL_IPA_UPLOAD = ['ipa'];
export const ALL_IMAGE_UPLOAD = ['jpeg', 'jpg', 'png'];
export const ALL_IMAGE_VIDEO_UPLOAD = ['jpeg', 'jpg', 'png', 'mp4', 'mov'];

export const AVATAR_COLOR = [
  '#173F5F',
  '#1F639C',
  '#4DA480',
  '#CB543F',
  '#7046A6',
  '#AB567F',
  '#667C89',
  '#49A8EE',
  '#D63964',
];

/* For iOS app */
export const DOWNLOAD_IOS_APP_URL = `itms-services://?action=download-manifest&url=`;
export const DEVELOPER_GUIDELINES_URL = `https://documentation.appsonair.com/`;

export const MAX_RELEASE_NOTE_CHARACTER = 5000;
export const MODAL_WIDTH = 600;
export const MODAL_WIDTH_LARGE = 707;

/* Terms and Condition Link */
export const TERMS_AND_CONDITION_LINK =
  'https://appsonair.com/terms-and-condition';

export const APPSONAIR_MAIN_LINK = 'https://appsonair.com/';
export const APPSONAIR_CODEPUSH_LINK =
  'https://appsonair.com/codepush-alternative';
/* Download Appsonair links for IOS and ANDROID */
export const DOWNLOAD_APPSTORE_URL =
  'https://apps.apple.com/in/app/appsonair/id6443620901';
export const DOWNLOAD_PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.logicwind.appsonair&hl=en&gl=US';

export const LOGICWIND_URL = 'https://www.logicwind.com';

export const MAX_UPLOAD_SIZE_FREE = '300MB';
export const MAX_UPLOAD_SIZE_FREE_RETURN = 300;
export const MAX_UPLOAD_SIZE_LIFETIME = '700MB';
export const MAX_UPLOAD_SIZE_LIFETIME_RETURN = 700;
export const MAX_UPLOAD_SIZE_PRO = '1GB';
export const MAX_UPLOAD_SIZE_PRO_RETURN = 1024;
export const MAX_UPLOAD_SIZE_PRO_PLUS = '2GB';
export const MAX_UPLOAD_SIZE_PRO_PLUS_RETURN = 2048;

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIP_CODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  WEB_URL_PLAY_STORE: /^https:\/\/play\.google\.com\/.*/,
  WEB_URL_APP_STORE: /^https:\/\/apps\.apple\.com\/.*/,
  WEB_URL_PLAY_STORE_AND_APPSONAIR: /^https:\/\/play\.google\.com\/.*|^https:\/.*\.appsonair\.com\/install\/.*/,
  WEB_URL_APP_STORE_AND_APPSONAIR: /^https:\/\/apps\.apple\.com\/.*|^https:\/.*\.appsonair\.com\/install\/.*/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONAL_NEGATIVE_AMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  INPUT_LENGTH: /^.{6,16}$/,
  URI_SCHEME: /^[a-zA-Z0-9+.-]+$/,
  IOS_TEAM_ID: /^[a-zA-Z0-9]{10}$/,
  HEX_COLON_SEPARATED: /^([A-Fa-f0-9]{2}:){31}[A-Fa-f0-9]{2}$/,
  WHITE_SPACE_NAME: /^(?!\s*$).+/,
  WHITE_SPACE: /\s/,
  WORKSPACE_APP_NAME_REGEX: /^[A-Za-z0-9 ]+$/,
};

export const GA_EVENT = {
  CHANGE_WORKSPACE: 'change_workspace_data_fetch',
  CREATE_NEW_WORKSPACE: 'create_new_workspace_data_fetch',
  USER_LOGOUT: 'user_logout_data_fetch',
  NEW_RELEASE: 'new_release_data_fetch',
  RELEASE_DOWNLOAD: 'release_download_data_fetch',
  RELEASE_VIEW_RELEASE_NOTE: 'release_view_release_note_data_fetch',
  RELEASE_PERMISSION: 'release_permissions_data_fetch',
  RELEASE_VIEW_ALL_SHARE_LINKS: 'release_view_all_share_links_data_fetch',
  RELEASE_DELETE: 'release_delete_data_fetch',
  RELEASE_SHARE: 'release_share_data_fetch',
  RELEASE_FEEDBACK: 'release_feedback_data_fetch',
  CREATE_APP: 'create_app_data_fetch',
  SIGN_IN: 'sign_in_data_fetch',
  USER_PROFILE_EDIT: 'user_profile_edit_data_fetch',
  VERIFY_USER_WITH_OTP: 'verify_user_with_otp_data_fetch',
  SEND_OTP_AGAIN: 'send_otp_again_data_fetch',
  TERMS_AND_CONDITION_LINK: 'terms_and_condition_link_data_fetch',
  CREATE_NEW_GROUP: 'create_new_group_data_fetch',
  ADD_NEW_MEMBER: 'add_new_member_data_fetch',
  SKIP_ONBOARDING_INVITE_MEMBER: 'skip_onboarding_invite_member',
  APP_SEARCH: 'app_search_input',
  SHARE_APPLICATION_DOWNLOAD: 'share_application_download',
  SKIP_ONBOARDING_CREATE_APP: 'skip_onboarding_create_app',
  SELECT_PLAN: 'select_plan_data_fetch',
  CANCEL_PLAN: 'cancel_plan_data_fetch',
  SELECT_ADDON: 'select_addon_data_fetch',
  CANCEL_ADDON: 'cancel_addon_data_fetch',
  MANAGE_BILLING: 'manage_billing_data_fetch',
  CONTACT_US: 'contact_us_data_fetch',
  DELETE_ACCOUNT: 'delete_account_data_fetch',
  UPGRADE_BUTTON: 'upgrade_button_data_fetch',
  PRO_RESTRICTION_BUTTON: 'pro_restriction_button_data_fetch',
  LEGACY_CLAIM_NOW: 'legacy_claim_now_data_fetch',
  INTEGRATION_UPGRADE_TO_PRO: 'integration_upgrade_to_pro_data_fetch',
  PURCHASE_PLAN: 'purchase_plan_data_fetch',
  PURCHASE_ADDON: 'purchase_addon_data_fetch',
  // new events
  NEW_USER_SIGNUP: 'new_user_signup',
  PROFILE_NAME_ADDED: 'profile_name_added',
  WORKSPACE_NAME_ADDED: 'workspace_name_added',
  CLICK_CREATE_RELEASE: 'click_create_release',
  SELECT_ONE_RELEASE_NOTE: 'select_one_release_note',
  DESELECT_ONE_RELEASE_NOTE: 'deselect_one_release_note',
  DESELECT_DEFAULT_SHARE_LINK: 'deselect_default_share_link',
  RESELECT_DEFAULT_SHARE_LINK: 'reselect_default_share_link',
  APK_RELEASE_NOTE_ADDED: 'apk_release_note_added',
  IPA_RELEASE_NOTE_ADDED: 'ipa_release_note_added',
  APK_RELEASE_UPLOADED: 'apk_release_uploaded',
  IPA_RELEASE_UPLOADED: 'ipa_release_uploaded',
  BOTH_RELEASE_UPLOADED: 'both_release_uploaded',
  CLICK_ON_CREATE_APP: 'click_on_create_app',
  LATEST_RELEASE_DOWNLOADED: 'latest_release_downloaded',
  CLICK_SHOW_ARCHIVED: 'click_show_archived',
  ALL_FILTER_USED: 'all_filter_used',
  ANDROID_FILTER_USED: 'android_filter_used',
  IOS_FILTER_USED: 'ios_filter_used',
  RELEASE_SHARE_LINK_COPIED: 'release_share_link_copied',
  TOGGLE_PASSWORD_PROTECTION_ON: 'toggle_password_protection_on',
  TOGGLE_PASSWORD_PROTECTION_OFF: 'toggle_password_protection_off',
  PASSWORD_SET: 'password_set',
  CLICK_GENERATE_LINK: 'click_generate_link',
  SHARE_LINK_COPIED: 'share_link_copied',
  LINK_UPDATE_SUCCESSFUL: 'link_update_successful',
  TEAM_MEMBER_INVITATION_SENT: 'team_member_invitation_sent',
  NEW_GROUP_CREATED: 'new_group_created',
  CLICK_PROFILE_SETTING: 'click_profile_setting',
  PROFILE_IMAGE_ADDED: 'profile_image_added',
  DEFAULT_WORKSPACE_CHANGED: 'default_workspace_changed',
  PROFILE_UPDATED: 'profile_updated',
  WORKSPACE_IMAGE_UPDATED: 'workspace_image_updated',
  WORKSPACE_SETTING_SAVED: 'workspace_setting_saved',
  CLICK_APP_SETTING: 'click_app_setting',
  CHANGE_APP_ICON: 'change_app_icon',
  SAVE_APP_SETTING: 'save_app_setting',
  DELETE_APP_INITIATED: 'delete_app_initiated',
  APP_DELETED: 'app_deleted',
  DELETE_ACCOUNT_INITIATED: 'delete_account_initiated',
  DELETE_ACCOUNT_REQ_CANCEL: 'delete_account_req_cancel',
  PROFILE_ACCOUNT_DELETED: 'profile_account_deleted',
  CANCEL_PLAN_INITIATED: 'cancel_plan_initiated',
  RELEASE_DELETE_INITIATED: 'release_delete_initiated',
  RELEASE_DELETE_REQ_CANCEL: 'release_delete_req_cancel',
  ADDED_MEMBER_DELETED: 'added_member_deleted',
  UPGRADE_TO_PRO: 'upgrade_to_pro',
  CODE_PUSH_LIMIT_EXHAUST: 'codepush_limit_exhaust',
  CODE_PUSH_LIMIT_EXHAUST_CLOSE: 'codepush_limit_exhaust_close',
  CODE_PUSH_FEATURE_MODAL_KNOW_MORE: 'codepush_feature_modal_know_more',
  CODE_PUSH_FEATURE_MODAL_DISMISS: 'codepush_feature_modal_dismiss',
  CLICK_AUTHENTICATE: 'click_authenticate',
  CLICK_GENERATE_DEPLOYMENT: 'click_generate_deployment',
};

export const GA_LABEL = {
  CHANGE_WORKSPACE: 'Change Workspace',
  CREATE_NEW_WORKSPACE: 'Create New Workspace',
  USER_LOGOUT: 'User Logout',
  NEW_RELEASE: 'New Release',
  RELEASE_DOWNLOAD: 'Release Download',
  RELEASE_VIEW_RELEASE_NOTE: 'Release View Release Note',
  RELEASE_PERMISSION: 'Release Permission',
  RELEASE_VIEW_ALL_SHARE_LINKS: 'Release View All Share Links',
  RELEASE_DELETE: 'Release Delete',
  RELEASE_SHARE: 'Release Share',
  RELEASE_FEEDBACK: 'Release Feedback',
  CREATE_APP: 'Create App',
  SIGN_IN: 'Sign In',
  USER_PROFILE_EDIT: 'User Profile Edit',
  VERIFY_USER_WITH_OTP: 'Verify User With OTP',
  SEND_OTP_AGAIN: 'Send OTP Again',
  TERMS_AND_CONDITION_LINK: 'Terms And Condition Link',
  CREATE_NEW_GROUP: 'Create New Group',
  ADD_NEW_MEMBER: 'Add New Member',
  SKIP_ONBOARDING_INVITE_MEMBER: 'Skip Onboarding Invite Member',
  APP_SEARCH: 'App Search Input',
  SHARE_APPLICATION_DOWNLOAD: 'Share Application Download',
  SKIP_ONBOARDING_CREATE_APP: 'Skip Onboarding Create App',
  SELECT_PLAN: 'Select Plan',
  CANCEL_PLAN: 'Cancel Plan',
  SELECT_ADDON: 'Select Addon',
  CANCEL_ADDON: 'Cancel Addon',
  MANAGE_BILLING: 'Manage Billing',
  CONTACT_US: 'Contact Us',
  DELETE_ACCOUNT: 'Delete Account',
  UPGRADE_BUTTON: 'Upgrade Button',
  PRO_RESTRICTION_BUTTON: 'Pro Restriction Button',
  LEGACY_CLAIM_NOW: 'Legacy Claim Now',
  INTEGRATION_UPGRADE_TO_PRO: 'Integration Upgrade to Pro',
  PURCHASE_PLAN: 'Purchase Plan',
  PURCHASE_ADDON: 'Purchase Addon',
  // new events
  NEW_USER_SIGNUP: 'New User Signup',
  PROFILE_NAME_ADDED: 'Profile Name Added',
  WORKSPACE_NAME_ADDED: 'Workspace Name Added',
  CLICK_CREATE_RELEASE: 'Click Create Release',
  SELECT_ONE_RELEASE_NOTE: 'Select One Release Note',
  DESELECT_ONE_RELEASE_NOTE: 'Deselect One Release Note',
  DESELECT_DEFAULT_SHARE_LINK: 'Deselect Default Share Link',
  RESELECT_DEFAULT_SHARE_LINK: 'Reselect Default Share Link',
  APK_RELEASE_NOTE_ADDED: 'APK Release Note Added',
  IPA_RELEASE_NOTE_ADDED: 'IPA Release Note Added',
  APK_RELEASE_UPLOADED: 'APK Release Uploaded',
  IPA_RELEASE_UPLOADED: 'IPA Release Uploaded',
  BOTH_RELEASE_UPLOADED: 'Both Release Uploaded',
  CLICK_ON_CREATE_APP: 'Click on Create App',
  LATEST_RELEASE_DOWNLOADED: 'Latest Release Downloaded',
  CLICK_SHOW_ARCHIVED: 'Click Show Archived',
  ALL_FILTER_USED: 'All Filter Used',
  ANDROID_FILTER_USED: 'Android Filter Used',
  IOS_FILTER_USED: 'IOS Filter Used',
  RELEASE_SHARE_LINK_COPIED: 'Release Share Link Copied',
  TOGGLE_PASSWORD_PROTECTION_ON: 'Toggle Password Protection On',
  TOGGLE_PASSWORD_PROTECTION_OFF: 'Toggle Password Protection Off',
  PASSWORD_SET: 'Password Set',
  CLICK_GENERATE_LINK: 'Click Generate Link',
  SHARE_LINK_COPIED: 'Share Link Copied',
  LINK_UPDATE_SUCCESSFUL: 'Link Update Successful',
  TEAM_MEMBER_INVITATION_SENT: 'Team Member Invitation Sent',
  NEW_GROUP_CREATED: 'New Group Created',
  CLICK_PROFILE_SETTING: 'Click Profile Setting',
  PROFILE_IMAGE_ADDED: 'Profile Image Added',
  DEFAULT_WORKSPACE_CHANGED: 'Default Workspace Changed',
  PROFILE_UPDATED: 'Profile Updated',
  WORKSPACE_IMAGE_UPDATED: 'Workspace Image Updated',
  WORKSPACE_SETTING_SAVED: 'Workspace Setting Saved',
  CLICK_APP_SETTING: 'Click App Setting',
  CHANGE_APP_ICON: 'Change App Icon',
  SAVE_APP_SETTING: 'Save App Setting',
  DELETE_APP_INITIATED: 'Delete App Initiated',
  APP_DELETED: 'App Deleted',
  DELETE_ACCOUNT_INITIATED: 'Delete Account Initiated',
  DELETE_ACCOUNT_REQ_CANCEL: 'Delete Account Request Cancelled',
  PROFILE_ACCOUNT_DELETED: 'Profile Account Deleted',
  CANCEL_PLAN_INITIATED: 'Cancel Plan Initiated',
  RELEASE_DELETE_INITIATED: 'Release Delete Initiated',
  RELEASE_DELETE_REQ_CANCEL: 'Release Delete Request Cancelled',
  ADDED_MEMBER_DELETED: 'Added Member Deleted',
  UPGRADE_TO_PRO: 'Upgrade to Pro',
  CODE_PUSH_LIMIT_EXHAUST: 'CodePush Limit Exhaust',
  CODE_PUSH_LIMIT_EXHAUST_CLOSE: 'CodePush Limit Exhaust Close',
  CODE_PUSH_FEATURE_MODAL_KNOW_MORE: 'CodePush Feature Modal Know More',
  CODE_PUSH_FEATURE_MODAL_DISMISS: 'CodePush Feature Modal Dismiss',
  CLICK_AUTHENTICATE: 'Click Authenticate',
  CLICK_GENERATE_DEPLOYMENT: 'Click Generate Deployment',
};

export const WORKSPACE_FEATURES_TAB = {
  INTEGRATIONS: 'INTEGRATIONS',
  CODE_PUSH: 'CODE_PUSH',
};

export const MAX_ADD_ONS_QUANTITY = 99;

export const PLANS = {
  FREE: 'FREE',
  PRO: 'PRO',
  PRO_PLUS: 'PRO_PLUS',
  LIFETIME: 'LIFETIME',
  LEGACY: 'LEGACY',
};

export const PLANS_TYPE = {
  FREE: { key: 'FREE', label: 'Free' },
  MONTHLY: { key: 'MONTHLY', label: 'Monthly' },
  ANNUAL: { key: 'ANNUAL', label: 'Annual' },
};

export const ADD_ON = {
  APP: 'APP',
  STORAGE: 'STORAGE',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  CODE_PUSH_BUNDLE: 'CODE_PUSH_BUNDLE',
};

export const SUBSCRIPTION_STATUS = {
  PAYMENT_DUE: { label: 'Payment due', key: 'PAYMENT_DUE' },
  ACTIVE: { label: 'Active', key: 'ACTIVE' },
};

export const PAYMENT_CHECKOUT = {
  CHECKOUT_CLOSED: 'checkout.closed',
  CHECKOUT_COMPLETED: 'checkout.completed',
  STATUS: {
    COMPLETED: 'completed',
    DRAFT: 'draft',
    PENDING: 'PENDING',
    READY: 'ready',
  },
  KEY: 'LIFE_TIME_DEAL',
};

export const BILLING_HISTORY_STATUS = {
  CANCELLED: { label: 'Cancelled', key: 'CANCELLED' },
  COMPLETED: { label: 'Completed', key: 'COMPLETED' },
};

export const PAYMENT_STATUS = {
  SUCCESS: { label: 'Success', key: 'SUCCESS' },
  ACTIVE: { label: 'Active', key: 'ACTIVE' },
  PENDING: { label: 'Pending', key: 'PENDING' },
  ...BILLING_HISTORY_STATUS,
};

export const BILLING_TYPE = {
  ADDONS: { label: 'Addons', key: 'ADDONS' },
  SUBSCRIPTION: { label: 'Plan', key: 'SUBSCRIPTION' },
};

export const PLAN_NAMES = {
  APP: 'App',
  STORAGE: 'Storage',
  TEAM_MEMBERS: 'Team Members',
  PRO: 'Pro',
  PRO_PLUS: 'Pro Plus',
  CODE_PUSH_BUNDLE: 'CodePush Bundle',
};

export const DEVICE_INFO_FIELDS = {
  deviceModel: 'Device Model',
  deviceUsedStorage: 'Used Storage',
  deviceTotalStorage: 'Total Storage',
  deviceMemory: 'Memory',
  appMemoryUsage: 'App Memory Usage',
  deviceOsVersion: 'OS Version',
  deviceRegionCode: 'Region Code',
  deviceRegionName: 'Region Name',
  deviceBatteryLevel: 'Battery Level',
  deviceScreenSize: 'Screen Size',
  environment: 'Environment',
  appName: 'App Name',
  releaseVersionNumber: 'Version Number',
  networkState: 'Network State',
  timezone: 'Time Zone',
};

export const DELETE_ACCOUNT_REASONS = [
  {
    label: 'Found a better alternative',
    value: 'Found a better alternative',
  },
  {
    label: 'App is too complicated',
    value: 'App is too complicated',
  },
  {
    label: 'Privacy or security concerns',
    value: 'Privacy or security concerns',
  },
  {
    label: 'Customer support issues',
    value: 'Customer support issues',
  },
  {
    label: 'Other: Please specify',
    value: 'OTHER',
  },
];
