import * as Sentry from '@sentry/react';
import { Card } from 'antd';
import Gleap from 'gleap';
import { isMobile } from 'mobile-device-detect';
import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { Route, Router, Switch } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ErrorIcon } from './assets/svg';
import { ROUTES } from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import SmartBanner from './components/SmartBanner';
import history from './historyData';
import IosDeviceUdid from './modules/app-details/IosDeviceUdid';
import PublicPage from './modules/app-details/PublicPage';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import NewUser from './modules/auth/NewUser';
import RefreshToken from './modules/auth/RefreshToken';
import VerifyUser from './modules/auth/VerifyUser';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA?.initialize(TRACKING_ID);

const MyFallbackComponent = ({ error, componentStack }) => (
  <div className="d-flex flex-vertical align-center">
    <div className="d-flex flex-vertical align-center">
      <ErrorIcon className="mt-10" width="65px" height="90px" />
      <p className="font-xx-large">
        <strong>Oops! An error occurred!</strong>
      </p>
      <Card className="width-percent-60">
        <p className="d-flex font-18">
          <strong className="font-large mr-5">Error: </strong>
          <p className="line-24"> {error?.message?.toString()}</p>
        </p>
        <p>
          <strong className="font-large">Stacktrace:</strong>
          <p className="line-24">{componentStack}</p>
        </p>
      </Card>
    </div>
  </div>
);

const Routes = () => {
  const {
    getToken,
    appLoading,
    state: { smartBanner },
  } = useContext(AppContext);
  const idToken = getToken();
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;
  // eslint-disable-next-line no-undef
  const { pathname } = window?.location;
  const isLoginPage = pathname === ROUTES?.LOGIN;

  useEffect(() => {
    appLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA?.send({
      hitType: 'pageview',
      page: pathname,
    });
  }, [pathname]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      Gleap?.initialize(process.env.REACT_APP_GLEAP_KEY);
    }
  }, []);

  useEffect(() => {
    Gleap?.showFeedbackButton(!!idToken);
  }, [idToken]);

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      {isMobile && smartBanner && <SmartBanner />}
      <Helmet>
        <title>{isLoginPage ? 'Login | AppsOnAir' : 'AppsOnAir'}</title>
        {isLoginPage && (
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_WEB_URL}${ROUTES?.LOGIN}`}
          />
        )}
      </Helmet>
      <Router history={history}>
        {maintenance === 'true' ? (
          <MaintenancePage />
        ) : (
          <Switch>
            <PublicRoute exact path={ROUTES?.LOGIN} component={Login} />
            <PublicRoute
              exact
              path={ROUTES?.VERIFY_USER}
              component={VerifyUser}
            />
            <Route
              exact
              path={`${ROUTES?.INSTALL}/:subLinkId`}
              component={PublicPage}
            />
            <Route exact path={ROUTES?.IOS_UDID} component={IosDeviceUdid} />
            <PrivateRoute
              exact
              path={ROUTES?.CREATE_USER}
              component={NewUser}
            />
            <PrivateRoute exact path={ROUTES?.LOGOUT} component={Logout} />
            <Route
              exact
              path={ROUTES?.REFRESH_TOKEN}
              component={RefreshToken}
            />
            <PrivateRoute path={ROUTES?.MAIN} component={App} />
          </Switch>
        )}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
