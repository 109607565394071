import { EllipsisOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dropdown, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { WarningIcon } from '../../../assets/svg';
import {
  ADD_ON,
  GA_EVENT,
  GA_LABEL,
  MAX_ADD_ONS_QUANTITY,
  PAYMENT_STATUS,
} from '../../../common/constants';
import {
  currencyFormat,
  findEarliestRenewalDate,
  formatPurchaseDate,
  googleAnalyticsEvents,
  isDueWithin7Days,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PaymentModal from '../../../components/PaymentModal';
import { usePlans } from '../../../context/PlanProvider';
import AppServiceProFeatureModal from '../../apps/components/AppServiceProFeatureModal';
import PurchaseConfirmationModal from '../../apps/components/PurchaseConfirmationModal';
import CountdownModal from '../common/CountdownModal';
import { triggerPaddlePayment } from '../common/paddlePaymentHandlers';
import AddonDetails from './components/AddonDetails';
import AddonList from './components/AddonList';
import CancelAddOnModal from './components/CancelAddOnModal';
import CancelAddonRestrictionModal from './components/CancelAddonRestrictionModal';
import EditAddOnsQuantityModal from './components/EditAddOnsQuantityModal';
import PurchaseAgainModal from './components/PurchaseAgainModal';
import {
  CANCEL_ADD_ON,
  EDIT_ADD_ON,
  PURCHASE_ADD_ON,
} from './graphql/mutations';
import { ADD_ONS } from './graphql/queries';
import './manage-addon.less';

const ManageAddOns = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const {
    refetchPlanDetails,
    refetchAddons,
    teamPlanAddOns,
    teamId,
    teamSubscription,
  } = usePlans();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [modalState, setModalState] = useState({
    payment: false,
    paymentStatus: '',
    cancelRestriction: false,
    purchaseConfirmation: false,
    cancelAddOn: false,
    editAddOn: false,
    purchaseAgain: false,
    usageExceed: false,
    countdown: false,
  });
  const [addOnsData, setAddOnsData] = useState([]);
  const [purchaseDetails, setPurchaseDetails] = useState({
    id: null,
    name: '',
  });
  const [addOnsState, setAddOnsState] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedAddOn, setSelectedAddOn] = useState([]);
  const codePushPlan = addOnsData.find(
    (plan) => plan.name === 'CODE_PUSH_BUNDLE',
  );

  const updateModalState = (key, value) => {
    setModalState((prev) => ({ ...prev, [key]: value }));
  };

  const getTotalQuantity = (name, feature, quantity = 1) => {
    if (name === ADD_ON?.APP) return quantity * 5;
    if (name === ADD_ON?.CODE_PUSH_BUNDLE)
      return quantity * feature?.CODE_PUSH_BUNDLE;
    return `${quantity * 10} GB`;
  };

  const [addOns] = useLazyQuery(ADD_ONS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const addons = res?.addons?.data || [];
      setAddOnsData(addons);
      const newState = addons.reduce((acc, item) => {
        acc[item.name] = {
          amount: item?.amount,
          feature: item?.subscriptionFeatures,
          quantity: 1,
          totalQuantity: getTotalQuantity(
            item?.name,
            item?.subscriptionFeatures,
          ),
          total: item?.amount,
          loading: false,
        };
        return acc;
      }, {});

      setAddOnsState(newState);
      setLoading(false);
    },
  });

  const handlePurchaseSuccess = () => {
    googleAnalyticsEvents(GA_EVENT?.PURCHASE_ADDON, {
      label: GA_LABEL?.PURCHASE_ADDON,
      member_id: currentUser?.id,
      purchase_addon_name: purchaseDetails?.name,
      amount: addOnsState?.[purchaseDetails?.name]?.total,
      quantity: addOnsState?.[purchaseDetails?.name]?.quantity,
    });
    refetchPlanDetails();
    refetchAddons();
    updateModalState('countdown', true);
  };

  const [purchaseAddOn] = useMutation(PURCHASE_ADD_ON, {
    onCompleted(res) {
      const transactionId = res?.purchaseAddon?.data?.transactionId;
      if (transactionId) {
        triggerPaddlePayment(transactionId, handlePurchaseSuccess, () => {});
      } else {
        handlePurchaseSuccess();
      }
      setAddOnsState((prev) => ({
        ...prev,
        [purchaseDetails?.name]: {
          ...prev[purchaseDetails?.name],
          loading: false,
        },
      }));
    },
    onError() {
      setAddOnsState((prev) => ({
        ...prev,
        [purchaseDetails?.name]: {
          ...prev[purchaseDetails?.name],
          loading: false,
        },
      }));
    },
  });

  const [cancelAddOn] = useMutation(CANCEL_ADD_ON, {
    onCompleted: handlePurchaseSuccess,
    onError(errorRes) {
      if (
        errorRes?.graphQLErrors?.[0]?.extensions?.code ===
        'SUBSCRIPTION_CANCELLATION_RESTRICTED'
      ) {
        updateModalState('cancelRestriction', true);
      }
    },
  });

  const [editAddOn] = useMutation(EDIT_ADD_ON, {
    onCompleted: handlePurchaseSuccess,
    onError(errorRes) {
      if (
        errorRes?.graphQLErrors?.[0]?.extensions?.code ===
        'SUBSCRIPTION_CANCELLATION_RESTRICTED'
      ) {
        updateModalState('cancelRestriction', true);
      }
    },
  });

  const handleAddOnPurchase = (purchaseId, addOnName) => {
    setPurchaseDetails({ id: purchaseId, name: addOnName });
    setAddOnsState((prev) => ({
      ...prev,
      [addOnName]: { ...prev[addOnName], loading: true },
    }));
    googleAnalyticsEvents(GA_EVENT?.SELECT_ADDON, {
      label: GA_LABEL?.SELECT_ADDON,
      member_id: currentUser?.id,
      addon_name: addOnName,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });

    if (teamPlanAddOns?.data?.length > 0) {
      updateModalState('purchaseAgain', true);
    } else {
      purchaseAddOn({
        variables: {
          data: {
            id: purchaseId,
            quantity: addOnsState?.[addOnName]?.quantity,
            teamId,
          },
        },
      });
    }
  };

  const updateQuantity = (addOnType, newQuantity) => {
    setAddOnsState((prev) => ({
      ...prev,
      [addOnType]: {
        ...prev[addOnType],
        quantity: newQuantity,
        totalQuantity: getTotalQuantity(
          addOnType,
          codePushPlan?.subscriptionFeatures,
          newQuantity,
        ),
        total: prev[addOnType]?.amount * newQuantity,
      },
    }));
  };

  const onHandlePlus = (addOnType) => {
    if (addOnsState?.[addOnType]?.quantity < MAX_ADD_ONS_QUANTITY) {
      updateQuantity(addOnType, addOnsState?.[addOnType]?.quantity + 1);
    }
  };

  const onHandleMinus = (addOnType) => {
    if (addOnsState?.[addOnType]?.quantity > 1) {
      updateQuantity(addOnType, addOnsState?.[addOnType]?.quantity - 1);
    }
  };

  const handleQuantityChange = (e, name) => {
    const value = parseFloat(e?.target?.value) || 0;
    if (value >= 0 && value <= MAX_ADD_ONS_QUANTITY) {
      updateQuantity(name, value);
    }
  };

  useEffect(() => {
    addOns();
  }, []);

  useEffect(() => {
    const paymentStatus = params?.get('payment');
    if (paymentStatus) {
      updateModalState('payment', true);
      updateModalState(
        'paymentStatus',
        paymentStatus === 'success' ? 'success' : 'failed',
      );
    }
  }, [location.search]);

  const manageAddOnsColumns = [
    {
      title: 'Add-ons Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.subscriptionPlan?.label,
    },
    {
      title: 'Purchase Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatPurchaseDate(record?.createdAt),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Total Bill',
      key: 'subscriptionAmount',
      dataIndex: 'subscriptionAmount',
      render: (_, record) =>
        currencyFormat.format(record?.subscriptionAmount || 0),
    },
    {
      title: 'Status',
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      render: (_, record) => {
        if (record?.subscriptionStatus === PAYMENT_STATUS.COMPLETED.key) {
          return <span className="active">Completed</span>;
        }
        return <span className="cancelled">Cancelled</span>;
      },
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => {
        const dropdownItems = [];

        if (record.subscriptionStatus === PAYMENT_STATUS.COMPLETED.key) {
          if (record.subscriptionPlan?.name === ADD_ON?.TEAM_MEMBERS) {
            dropdownItems.push({
              key: 'cancel',
              label: 'Cancel',
              onClick: () => updateModalState('cancelAddOn', true),
            });
          } else if (record.quantity > 1) {
            dropdownItems.push(
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => updateModalState('editAddOn', true),
              },
              {
                key: 'cancel',
                label: 'Cancel',
                onClick: () => updateModalState('cancelAddOn', true),
              },
            );
          } else {
            dropdownItems.push({
              key: 'cancel',
              label: 'Cancel',
              onClick: () => updateModalState('cancelAddOn', true),
            });
          }
        } else {
          dropdownItems.push({
            key: 'edit',
            label: 'Edit',
            onClick: () => updateModalState('editAddOn', true),
          });
        }

        return (
          <Dropdown
            overlayClassName="add-on-manage-menu"
            menu={{ items: dropdownItems }}
            trigger={['click']}
            placement="bottomRight"
            containsTeamMembers
            getPopupContainer={(triggerNode) => triggerNode}
            dropdownRender={(menu) => (
              <div className="app-name-header text-left">
                {React.cloneElement(menu)}
              </div>
            )}
          >
            <EllipsisOutlined
              rotate={90}
              onClick={() => setSelectedAddOn(record)}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {teamPlanAddOns?.data?.length > 0 &&
        isDueWithin7Days(
          formatPurchaseDate(findEarliestRenewalDate(teamPlanAddOns?.data)),
        ) && (
          <div className="payment-banner">
            <p>Your payment is due</p>
          </div>
        )}
      {loading ? (
        <LoaderComponent setHeight={150} />
      ) : (
        <div className="manage-add-on">
          <div className="manage-add-ons">
            <AddonDetails teamPlanAddOns={teamPlanAddOns} />
            <AddonList
              addOnsData={addOnsData}
              addOnsState={addOnsState}
              onHandleMinus={onHandleMinus}
              handleQuantityChange={handleQuantityChange}
              onHandlePlus={onHandlePlus}
              handleAddOnPurchase={handleAddOnPurchase}
            />
            <p className="available-text">
              Available to all free*, pro and enterprise accounts.
            </p>
            <div className="manage-add-ons-accordion">
              <h3 className="title">Manage Add-ons</h3>
              <div className="manage-add-ons-list">
                <Table
                  rowKey={(e) => e?.id}
                  columns={manageAddOnsColumns}
                  dataSource={teamPlanAddOns?.data}
                  pagination={false}
                />
              </div>
            </div>
          </div>
          {modalState?.cancelAddOn && (
            <CancelAddOnModal
              visible={modalState?.cancelAddOn}
              setVisible={(value) => updateModalState('cancelAddOn', value)}
              selectedAddOn={selectedAddOn}
              setSelectedAddOn={setSelectedAddOn}
              cancelAddOn={cancelAddOn}
            />
          )}
          {modalState?.editAddOn && (
            <EditAddOnsQuantityModal
              visible={modalState?.editAddOn}
              setVisible={(value) => updateModalState('editAddOn', value)}
              selectedAddOn={selectedAddOn}
              setSelectedAddOn={setSelectedAddOn}
              editAddOn={editAddOn}
            />
          )}
          {modalState?.payment && (
            <PaymentModal
              visible={modalState?.payment}
              setVisible={(value) => updateModalState('payment', value)}
              paymentModalStatus={modalState?.paymentStatus}
              setCountdownModalOpen={(value) =>
                updateModalState('countdown', value)
              }
            />
          )}
          {modalState?.purchaseConfirmation && (
            <PurchaseConfirmationModal
              visible={modalState?.purchaseConfirmation}
              setVisible={(value) =>
                updateModalState('purchaseConfirmation', value)
              }
            />
          )}
          {modalState?.purchaseAgain && (
            <PurchaseAgainModal
              visible={modalState?.purchaseAgain}
              setVisible={(value) => updateModalState('purchaseAgain', value)}
              handleAgainCancel={() => {
                setAddOnsState((prevState) => ({
                  ...prevState,
                  [purchaseDetails?.name]: {
                    ...prevState[purchaseDetails?.name],
                    loading: false,
                  },
                }));
              }}
              handleAgainPurchase={() => {
                purchaseAddOn({
                  variables: {
                    data: {
                      id: purchaseDetails?.id,
                      quantity: addOnsState?.[purchaseDetails?.name]?.quantity,
                      teamId,
                    },
                  },
                });
              }}
            />
          )}
          {modalState?.usageExceed && (
            <AppServiceProFeatureModal
              modalWidth={520}
              wrapClassName="usage-exceed-modal"
              visible={modalState?.usageExceed}
              setVisible={(value) => updateModalState('usageExceed', value)}
              footer={false}
              content={
                <div className="d-flex flex-vertical align-center">
                  <div className="payment-modal-content d-flex flex-vertical align-center text-center gap-24">
                    <WarningIcon />
                    <div>
                      <p className="m-0">
                        We're sorry, but we couldn't process your request to
                        cancel the add-ons at this time. Your current usage
                        exceeds the free allotted usage limit. To cancel the
                        add-ons, please ensure your usage falls within the free
                        given limit. Reduce or adjust your usage accordingly to
                        proceed with the cancellation. Thank you for your
                        understanding.
                      </p>
                    </div>
                  </div>
                </div>
              }
            />
          )}
          {modalState?.countdown && (
            <CountdownModal
              countdownModalOpen={modalState?.countdown}
              setCountdownModalOpen={(value) =>
                updateModalState('countdown', value)
              }
            />
          )}
          {modalState?.cancelRestriction && (
            <CancelAddonRestrictionModal
              selectedAddOn={selectedAddOn}
              visible={modalState?.cancelRestriction}
              handleVisible={(value) =>
                updateModalState('cancelRestriction', value)
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default ManageAddOns;
