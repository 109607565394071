import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import './codePush.less';
import CodePushAndroid from './CodePushAndroid';
import CodePushIos from './CodePushIos';
import CodePushLogin from './CodePushLogin';

const CodePushWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/:appId`}
      component={CodePushLogin}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/:appId${ROUTES?.ANDROID}`}
      component={CodePushAndroid}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/:appId${ROUTES?.IOS}`}
      component={CodePushIos}
    />
  </Switch>
);

export default CodePushWrapper;
