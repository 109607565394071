import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { usePlans } from '../../context/PlanProvider';
import { UPDATE_DOWNLOAD_COUNT } from './graphql/mutations';
import { GET_PLATFORM_SPECIFIC_RELEASE } from './graphql/queries';

const DownloadButton = ({ platform, data, loading, onClick }) => (
  <Button
    type="primary"
    className="download-btn align-center mr-12"
    loading={loading}
    href={data?.appStorageUrl}
    onClick={onClick}
  >
    <span className="download-span">
      Download&nbsp;{data?.appVersion || 'N/A'}
    </span>
    {platform === 'ANDROID' ? (
      <AndroidFilled className="font-16" />
    ) : (
      <AppleFilled className="font-16" />
    )}
  </Button>
);

const LatestRelease = ({ refetchTableData, refetchType, setRefetchType }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription } = usePlans();
  const { appId } = useParams();
  const [loading, setLoading] = useState(true);
  const [platformData, setPlatformData] = useState({
    IOS: null,
    ANDROID: null,
  });

  const [fetchIosData, { loading: iosDataLoading }] = useLazyQuery(
    GET_PLATFORM_SPECIFIC_RELEASE,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: appId,
        platform: 'IOS',
      },
      onCompleted(response) {
        setPlatformData((prev) => ({ ...prev, IOS: response }));
        setLoading(false);
      },
      onError(error) {
        setLoading(false);
        return error;
      },
    },
  );

  const [fetchAndroidData, { loading: androidDataLoading }] = useLazyQuery(
    GET_PLATFORM_SPECIFIC_RELEASE,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: appId,
        platform: 'ANDROID',
      },
      onCompleted(response) {
        setPlatformData((prev) => ({ ...prev, ANDROID: response }));
        setLoading(false);
      },
      onError(error) {
        setLoading(false);
        return error;
      },
    },
  );

  const [updateDownloadCount, { loading: countLoading }] = useMutation(
    UPDATE_DOWNLOAD_COUNT,
    {
      onCompleted: () => {
        if (refetchTableData) {
          refetchTableData();
        }
      },
      onError() {},
    },
  );

  useEffect(() => {
    fetchIosData();
    fetchAndroidData();
  }, []);

  useEffect(() => {
    if (refetchType) {
      if (refetchType === 'ANDROID') {
        fetchAndroidData();
      } else {
        fetchIosData();
      }
      setRefetchType(null);
    }
  }, [refetchType]);

  const updateDownloadFunc = (platform) => {
    googleAnalyticsEvents(GA_EVENT?.LATEST_RELEASE_DOWNLOADED, {
      label: GA_LABEL?.LATEST_RELEASE_DOWNLOADED,
      member_id: currentUser?.id,
      current_plan_name: teamSubscription?.subscriptionPlan?.name,
      current_plan_type: teamSubscription?.subscriptionPlan?.type,
    });
    const platformReleaseData =
      platformData[platform]?.getPlatformSpecificLatestRelease;

    if (platformReleaseData) {
      setTimeout(() => {
        updateDownloadCount({
          variables: { applicationDetailId: platformReleaseData.id },
        });
      }, 500);
    }
  };

  if (iosDataLoading || androidDataLoading || loading)
    return <LoaderComponent setHeight={700} />;

  return (
    <div className="latest-release">
      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xl={24} xxl={10}>
          <Card
            size="small"
            title="Latest Release :"
            className="latest-release-card"
          >
            {platformData?.ANDROID?.getPlatformSpecificLatestRelease
              ?.appVersion && (
              <DownloadButton
                platform="ANDROID"
                data={platformData?.ANDROID?.getPlatformSpecificLatestRelease}
                loading={countLoading}
                onClick={() => updateDownloadFunc('ANDROID')}
              />
            )}
            {platformData?.IOS?.getPlatformSpecificLatestRelease
              ?.appVersion && (
              <DownloadButton
                platform="IOS"
                data={platformData?.IOS?.getPlatformSpecificLatestRelease}
                loading={countLoading}
                onClick={() => updateDownloadFunc('IOS')}
              />
            )}
          </Card>
        </Col>
        {['ANDROID', 'IOS'].map((platform) => (
          <Col
            key={platform}
            md={12}
            lg={12}
            xl={12}
            xxl={7}
            className="release-col android-release"
          >
            <div className="latest-release-card">
              <div className="android-release">
                {platform === 'ANDROID' ? (
                  <AndroidFilled className="android-color" />
                ) : (
                  <AppleFilled className="ios-color" />
                )}
              </div>
              <div className="releases">
                <div className="total-release">
                  <div className="line-24">Total Releases</div>
                  <span className="line-24">
                    {platformData?.[platform]?.getPlatformSpecificLatestRelease
                      ?.platformSpecificDashboard
                      ?.totalPlatformSpecificUsersUpload || '0'}
                  </span>
                </div>

                <div className="total-downloads">
                  <div className="line-24">Total Downloads</div>
                  <span className="line-24">
                    {platformData?.[platform]?.getPlatformSpecificLatestRelease
                      ?.platformSpecificDashboard
                      ?.totalPlatformSpecificAppsDownload || '0'}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LatestRelease;
