import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CURRENT_EMAIL,
  GA_EVENT,
  GA_LABEL,
  IS_NEW_USER,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules, googleAnalyticsEvents } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import './Login.less';
import { LOGIN } from './graphql/mutations';
import AuthLayout from './layout/Layout';

const { required, email } = formValidatorRules;

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { dispatch, getToken } = useContext(AppContext);
  const idToken = getToken();
  const [loading, setLoading] = useState(true);
  const [tempEmail, setTempEmail] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const params = new URLSearchParams(location?.search);
  const redirectAfterLogin = params?.get('redirect');
  const emailValue = params?.get('email');

  const [form] = Form?.useForm();

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res?.loginUser?.status === 'Success!') {
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(IS_NEW_USER);
        const lowerCaseEmail = form?.getFieldValue('email')?.toLowerCase();
        dispatch({ type: 'SET_EMAIL', data: lowerCaseEmail });
        // eslint-disable-next-line no-undef
        localStorage?.setItem(CURRENT_EMAIL, lowerCaseEmail);
        if (res?.loginUser?.isNewUser) {
          // eslint-disable-next-line no-undef
          localStorage?.setItem(IS_NEW_USER, res?.loginUser?.isNewUser);
          googleAnalyticsEvents(GA_EVENT?.NEW_USER_SIGNUP, {
            label: GA_LABEL?.NEW_USER_SIGNUP,
          });
        } else {
          googleAnalyticsEvents(GA_EVENT?.SIGN_IN, {
            label: GA_LABEL?.SIGN_IN,
            user_email: tempEmail,
          });
        }
        history.push(
          `${ROUTES.VERIFY_USER}${
            redirectAfterLogin
              ? `?redirect=${encodeURIComponent(redirectAfterLogin)}`
              : ''
          }`,
        );
      }
    },
    onError() {},
  });

  const handleFinish = async (values) => {
    const lowerCaseEmail = values?.email?.toLowerCase();
    setTempEmail(lowerCaseEmail);
    await loginMutate({
      variables: { email: lowerCaseEmail },
    });
  };

  useEffect(() => {
    if (emailValue) {
      setInitialValues({ email: emailValue });
      setLoading(false);
      history?.replace(ROUTES?.LOGIN);
    } else {
      if (idToken) {
        history?.replace(ROUTES?.MAIN);
      } else {
        history.push(
          `${ROUTES.LOGIN}${
            redirectAfterLogin
              ? `?redirect=${encodeURIComponent(redirectAfterLogin)}`
              : ''
          }`,
        );
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) form?.submit();
  }, [initialValues]);

  if (loading) return <LoaderComponent />;

  return (
    <AuthLayout>
      <div className="gx-login-content">
        <div className="login-text">
          <span className="login-heading-text font-24">Get Started</span>
          <span className="login-description">
            You will receive a 6 digit OTP in your inbox for verification.
          </span>
        </div>
        <Form
          name="Login"
          form={form}
          initialValues={initialValues}
          onFinish={handleFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required, email]}>
            <Input className="email-input" placeholder="Email" autoFocus />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="send-otp-button font-500"
              loading={loginLoading}
            >
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
