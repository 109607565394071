import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DatePicker, Dropdown, Input, Row, Select } from 'antd';
import { isNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import noURLPrefixImage from '../../../assets/images/no-url-prefix.png';
import { ArchiveTick, SettingIcon } from '../../../assets/svg';
import {
  ACTIONS,
  ALL_TIME,
  DYNAMIC_LINK_LIMIT,
  ROUTES,
  SORT_BY,
  SORT_ON,
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import { ARCHIVE_DYNAMIC_LINK } from '../graphql/mutations';
import { GET_DYNAMIC_LINKS, GET_DYNAMIC_LINK_CONFIG } from '../graphql/queries';
import './dynamicLink.less';
import DynamicLinkConfigModal from './DynamicLinkConfigModal';

const { RangePicker } = DatePicker;

const initialFilter = {
  sort: {
    order: SORT_BY.DESC,
    field: SORT_ON.createdAt,
  },
  filter: {
    skip: 0,
    limit: DYNAMIC_LINK_LIMIT,
    isArchive: false,
  },
  tableParams: {
    current: 1,
  },
  filterType: {
    dataFilterType: ALL_TIME,
  },
  rangeDate: {
    startDate: '',
    endDate: '',
  },
};

const selectItems = [
  {
    value: 'LAST_WEEK',
    label: 'Last 7 Days',
  },
  {
    value: 'LAST_MONTH',
    label: 'Last month',
  },
  {
    value: 'LAST_YEAR',
    label: 'Last year',
  },
  {
    value: 'ALL_TIME',
    label: 'All time',
  },
];

const DynamicLink = () => {
  const history = useHistory();
  const { appId } = useParams();
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('ALL_TIME');
  const [paginationProp, setPaginationProp] = useState(initialFilter);
  const [urlPrefixModal, setUrlPrefixModal] = useState(false);
  const [dynamicLinkPrefix, setDynamicLinkPrefix] = useState(false);
  const [dynamicLinkList, setDynamicLinkList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editDynamicLinkConfig, setEditDynamicLinkConfig] = useState(false);
  const [archiveDynamicLinkId, setArchiveDynamicLinkId] = useState(null);
  const [dynamicLinkConfigId, setDynamicLinkConfigId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [getDynamicLinks, { data, loading: loadingList }] = useLazyQuery(
    GET_DYNAMIC_LINKS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setDynamicLinkList(res?.dynamicLinks?.data);
      },
      onError() {},
    },
  );

  const [getDynamicLinkConfig] = useLazyQuery(GET_DYNAMIC_LINK_CONFIG, {
    fetchPolicy: 'network-only',
    variables: {
      where: { applicationId: appId },
    },
    onCompleted(res) {
      if (res?.dynamicLinkConfigs?.[0]?.id) {
        setDynamicLinkConfigId(res?.dynamicLinkConfigs?.[0]?.id);
        setDynamicLinkPrefix(res?.dynamicLinkConfigs?.[0]);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [ArchiveDynamicLink] = useMutation(ARCHIVE_DYNAMIC_LINK, {
    onCompleted() {
      setArchiveDynamicLinkId(null);
      getDynamicLinks({
        variables: {
          where: {
            dynamicLinkConfigId: dynamicLinkPrefix?.id,
          },
          filter: paginationProp?.filter,
          sort: paginationProp?.sort,
        },
      });
    },
    onError() {},
  });

  const menu = [
    {
      key: 'edit',
      label: (
        <>
          <SettingIcon /> Edit link
        </>
      ),
      onClick: () => {
        history.push({
          pathname: `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/${appId}${ROUTES?.EDIT_DYNAMIC_LINK}`,
          state: { dynamicLinkId: archiveDynamicLinkId },
        });
      },
    },
    { key: 'divider-1', type: 'divider' },
    {
      key: 'archive',
      label: (
        <>
          <ArchiveTick /> Archive link
        </>
      ),
      onClick: () => {
        ArchiveDynamicLink({
          variables: { where: { dynamicLinkId: archiveDynamicLinkId } },
        });
      },
    },
  ];

  const columns = [
    {
      title: 'Link name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'shortLink',
      key: 'shortLink',
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        const dateObject = new Date(record?.createdAt);

        const day = dateObject.getUTCDate();
        const month = dateObject.getUTCMonth() + 1;
        const year = dateObject.getUTCFullYear();

        const formattedDate = `${day < 10 ? '0' : ''}${day}-${
          month < 10 ? '0' : ''
        }${month}-${year}`;

        return formattedDate;
      },
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      render: (_, record) => {
        if (isNumber(record?.clicks)) {
          return record?.clicks;
        }
        return '-';
      },
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',
      className: 'action',
      width: '5%',
      render: (_, record) => {
        if (record?.isArchive) {
          return '';
        }
        return (
          <div className="action-col">
            <Dropdown
              overlayClassName="dropdown dynamic-link-list-menu"
              menu={{ items: menu }}
              placement="bottomRight"
              trigger={['click']}
            >
              <EllipsisOutlined
                rotate={90}
                onClick={() => setArchiveDynamicLinkId(record?.id)}
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const items = [
    {
      key: 'edit',
      label: (
        <>
          <SettingIcon /> Edit Config
        </>
      ),
      onClick: () => {
        setEditDynamicLinkConfig(true);
        setUrlPrefixModal(true);
      },
    },
  ];

  const updatePagination = (updates) => {
    setPaginationProp((prev) => ({
      ...prev,
      ...updates,
      filter: {
        ...prev?.filter,
        skip: 0,
        limit: DYNAMIC_LINK_LIMIT,
        ...updates?.filter,
      },
      tableParams: {
        ...prev?.tableParams,
        current: 1,
        ...updates?.tableParams,
      },
      filterType: {
        ...prev?.filterType,
        ...updates?.filterType,
      },
      rangeDate:
        updates?.filterType?.dataFilterType === 'CUSTOM'
          ? { ...prev?.rangeDate, ...updates?.rangeDate }
          : { startDate: '', endDate: '' },
    }));
  };

  const handlePagination = ({ current, pageSize }) => {
    updatePagination({
      filter: { skip: (current - 1) * pageSize },
      tableParams: { current },
    });
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    if (value !== 'CUSTOM') {
      setSelectedRange(null); // Clear RangePicker selection when filter is NOT "CUSTOM"
    }
    updatePagination({
      filterType: { dataFilterType: value },
    });
  };

  const handleDateChange = (_, dayString) => {
    setSelectedFilter('Custom Date');
    setSelectedRange(dayString);
    updatePagination({
      filterType: { dataFilterType: 'CUSTOM' },
      rangeDate: { startDate: dayString?.[0], endDate: dayString?.[1] },
    });
  };

  const handleDropdownVisibleChange = (open) => {
    setDropdownOpen(!dropdownOpen && open);
  };

  useEffect(() => {
    getDynamicLinkConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dynamicLinkConfigId) {
      getDynamicLinks({
        variables: {
          where: {
            dynamicLinkConfigId,
            dateFilterType: paginationProp?.filterType?.dataFilterType,
            rangeDate: paginationProp?.rangeDate,
          },
          filter: paginationProp?.filter,
          sort: paginationProp?.sort,
        },
      });
    }
  }, [dynamicLinkConfigId, paginationProp]);

  const newDynamicLink = () => {
    history?.push(
      `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/${appId}${ROUTES?.CREATE_NEW_DYNAMIC_LINK}`,
    );
  };

  const newUrlPrefix = () => {
    setUrlPrefixModal(true);
  };

  return (
    <>
      {loading ? (
        <LoaderComponent setHeight={200} spinning={loading} />
      ) : (
        <>
          {!dynamicLinkPrefix ? (
            <div className="no-url-prefix d-flex justify-center align-center">
              <div className="no-release-img">
                <img
                  src={noURLPrefixImage}
                  alt={noURLPrefixImage}
                  width={394}
                />
                <div className="no-url-prefix-content d-flex justify-center flex-vertical align-center">
                  <Row className="row-app">You have no URL prefix</Row>
                  <Row className="row-create font-16">
                    Setup a specific URL prefix you need to create a dynamic
                    link.
                  </Row>
                  <div className="button-create">
                    <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE}>
                      <Button type="primary" onClick={newUrlPrefix}>
                        <PlusOutlined /> Create your URL prefix
                      </Button>
                    </AccessControl>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="dynamic-link-content">
                <div className="apps-content">
                  <div className="app-details-inner">
                    <h1 className="m-0">URL Prefix</h1>
                    <div className="d-flex align-center">
                      <Input value={dynamicLinkPrefix?.urlPrefix} disabled />
                      <Dropdown
                        overlayClassName="dropdown edit-url-prefix"
                        menu={{ items }}
                        placement="bottomRight"
                        trigger={['click']}
                      >
                        <EllipsisOutlined rotate={90} />
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="apps-details-content">
                  <div className="app-details-inner">
                    <Row className="table-row">
                      <div className="table-header">
                        <div className="table-header-left">
                          <h2>Dynamic Links</h2>
                        </div>
                        <div className="table-header-right">
                          <div className="filter-platform">
                            <span className="mr-8">Filter by date</span>
                            <div className="select-platform">
                              <Select
                                className="select-filter"
                                placeholder="Select filter"
                                name="platform"
                                onChange={handleFilterChange}
                                value={selectedFilter}
                                defaultValue="ALL_TIME"
                                open={dropdownOpen}
                                options={selectItems}
                                onDropdownVisibleChange={
                                  handleDropdownVisibleChange
                                }
                                dropdownRender={(menus) => (
                                  <div onMouseDown={(e) => e.stopPropagation()}>
                                    {menus}
                                    <div className="dynamic-link-filter-date">
                                      <RangePicker
                                        className="pointer"
                                        value={
                                          selectedRange
                                            ? [
                                                moment(selectedRange?.[0]),
                                                moment(selectedRange?.[1]),
                                              ]
                                            : null
                                        }
                                        onChange={handleDateChange}
                                        onOpenChange={(open) => {
                                          if (!open) {
                                            setDropdownOpen(false);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <AccessControl
                            allowedPermissions={ACTIONS?.APP_RELEASE}
                          >
                            <Button
                              type="primary"
                              className="new-release-btn"
                              onClick={newDynamicLink}
                            >
                              <PlusOutlined />
                              Create New Link
                            </Button>
                          </AccessControl>
                        </div>
                      </div>
                      <div className="table">
                        <CommonTable
                          rowKey={(e) => e?.id}
                          className="table-content"
                          columns={columns}
                          loading={loadingList}
                          data={dynamicLinkList}
                          onChange={handlePagination}
                          paginationConfig={{
                            total: data?.dynamicLinks?.count,
                            pageSize: paginationProp?.filter?.limit,
                            current: paginationProp?.tableParams?.current,
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
          {urlPrefixModal && (
            <DynamicLinkConfigModal
              visible={urlPrefixModal}
              handleVisible={setUrlPrefixModal}
              isEdit={editDynamicLinkConfig}
              setIsEdit={setEditDynamicLinkConfig}
              onUpdate={() => getDynamicLinkConfig()}
            />
          )}
        </>
      )}
    </>
  );
};

export default DynamicLink;
