import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { PlatformDeployment } from '../../../assets/svg';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../../common/constants';
import {
  checkTesterAccess,
  googleAnalyticsEvents,
  removeQueryParamsFromUrl,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import TesterAccessModal from '../../../components/TesterAccessModal';
import { usePlans } from '../../../context/PlanProvider';
import { GET_APP } from '../graphql/queries';
import CodePushAuthenticationModal from './components/CodePushAuthenticationModal';
import CodePushBanner from './components/CodePushBanner';
import { ADD_CODE_PUSH_APP } from './graphql/mutations';
import { AUTHENTICATE_CODE_PUSH } from './graphql/queries';

const CodePushLogin = () => {
  const { appId } = useParams();
  const { currentRole, currentWorkspace, teamSubscription } = usePlans();
  const {
    state: { currentUser, applicationName },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const authSuccess = searchParams.get('success');
  const [success, setSuccess] = useState(null);
  const [authSuccessModal, setAuthSuccessModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testerModal, setTesterModal] = useState(false);

  const { data } = useQuery(GET_APP, {
    variables: {
      appId,
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_APPLICATION_NAME',
        data: res?.getApplication?.appName,
      });
    },
    onError() {},
  });

  const [addCodePushApp] = useMutation(ADD_CODE_PUSH_APP, {
    refetchQueries: [{ query: GET_APP, variables: { appId } }],
    onCompleted() {
      history?.push(
        `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${appId}${ROUTES?.ANDROID}`,
      );
    },
    onError() {},
  });

  const [codePushAuthenticate] = useLazyQuery(AUTHENTICATE_CODE_PUSH, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const url = res?.getCodePushAuthUrl?.url;
      if (url) {
        // eslint-disable-next-line no-undef
        window.location.href = url;
      }
    },
  });

  useEffect(() => {
    if (authSuccess) {
      if (data?.getApplication?.isCodePushEnabled) {
        history?.push(
          `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${appId}${ROUTES?.ANDROID}`,
        );
      }
      setLoading(false);
      setSuccess(authSuccess);
      setAuthSuccessModal(true);

      const timeout = setTimeout(removeQueryParamsFromUrl, 1000);

      return () => clearTimeout(timeout);
    }
    if (currentUser) {
      setLoading(false);
    }
  }, [authSuccess, data, currentUser]);

  const connectToAuthenticate = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.CODE_PUSH_AUTH)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_AUTHENTICATE, {
        label: GA_LABEL?.CLICK_AUTHENTICATE,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      dispatch({ type: 'SET_CODE_PUSH_APPLICATION_ID_LOGIN', data: appId });
      codePushAuthenticate({
        variables: {
          data: {
            platform: 'JWT',
          },
        },
      });
    } else {
      setTesterModal(true);
    }
  };

  const generateNewKey = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.CODE_PUSH_AUTH)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_GENERATE_DEPLOYMENT, {
        label: GA_LABEL?.CLICK_GENERATE_DEPLOYMENT,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      if (currentWorkspace?.teamCreator?.isCodePushConnect) {
        addCodePushApp({
          variables: {
            data: {
              name: applicationName,
            },
          },
        });
      } else {
        setAuthSuccessModal(true);
      }
    } else {
      setTesterModal(true);
    }
  };

  if (loading) return <LoaderComponent setHeight="200" />;

  return (
    <div className="code-push-content">
      <CodePushBanner />
      {currentUser?.isCodePushConnect ? (
        <div className="platform-deployment">
          <PlatformDeployment />
          <div className="text-center mt-32">
            <h2>Generate platform deployments</h2>
            <p className="m-0">
              This will generate deployment key for Android and iOS on the React
              Native platform.
            </p>
            <Button className="mt-24" type="primary" onClick={generateNewKey}>
              Generate Deployment
            </Button>
          </div>
        </div>
      ) : (
        <div className="connect-github m-16">
          <Row gutter={[16, 16]}>
            <Col xl={24} md={24}>
              <div className="d-flex justify-between align-center mb-16">
                <h3 className="m-0">Authenticate your account</h3>
                <Button type="primary" onClick={connectToAuthenticate}>
                  Authenticate
                </Button>
              </div>
              <p>
                To enable CodePush in Appsonair, you must authenticate using a
                JWT-based strategy. This authentication ensures secure access to
                the service without requiring external account linking. Once
                authenticated, you can proceed with configuring CodePush for
                your project.
              </p>
            </Col>
          </Row>
          <div className="mt-24">
            <h4 className="mb-16">How to?</h4>
            <Row gutter={[24, 24]}>
              <Col xl={24} md={24}>
                <fieldset className="how-to-block">
                  <legend>Step1</legend>
                  <h4>Authenticate Your Account</h4>
                  <ul>
                    <li>
                      Click on <b>"Authenticate"</b> button.
                    </li>
                  </ul>
                </fieldset>
              </Col>
              <Col xl={24} md={24}>
                <fieldset className="how-to-block">
                  <legend>Step2</legend>
                  <h4>Authentication Process</h4>
                  <ul>
                    <li>
                      The system will automatically authenticate your logged-in{' '}
                      <b>Appsonair</b> account.
                    </li>
                  </ul>
                </fieldset>
              </Col>
              <Col xl={24} md={24}>
                <fieldset className="how-to-block">
                  <legend>Step3</legend>
                  <h4>Confirmation</h4>
                  <ul>
                    <li>
                      Once authorized, a confirmation message will confirm that
                      your account is successfully connected.
                    </li>
                  </ul>
                </fieldset>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {authSuccessModal && (
        <CodePushAuthenticationModal
          visible={authSuccessModal}
          setVisible={setAuthSuccessModal}
          currentRole={currentRole}
          success={success}
        />
      )}
      {testerModal && (
        <TesterAccessModal visible={testerModal} setVisible={setTesterModal} />
      )}
    </div>
  );
};

export default CodePushLogin;
