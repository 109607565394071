import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import { DocumentCopy } from '../../../assets/svg';
import {
  ACTIONS,
  CODE_PUSH_APPLICATION_ID_LOGIN,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
} from '../../../common/constants';
import {
  checkTesterAccess,
  copyToClipboard,
  googleAnalyticsEvents,
  removeQueryParamsFromUrl,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import TesterAccessModal from '../../../components/TesterAccessModal';
import { usePlans } from '../../../context/PlanProvider';
import { AUTHENTICATE_CODE_PUSH } from './graphql/queries';

const CodePushCLILogin = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    state: { codePushApplicationId, currentUser },
  } = useContext(AppContext);
  const { teamSubscription, currentRole } = usePlans();
  const searchParams = new URLSearchParams(location.search);
  const initialToken = searchParams.get('accessToken');
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [testerModal, setTesterModal] = useState(false);

  const [codePushAuthenticate] = useLazyQuery(AUTHENTICATE_CODE_PUSH, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const url = res?.getCodePushAuthUrl?.url;
      if (url) {
        // eslint-disable-next-line no-undef
        window.location.href = url;
      }
    },
  });

  useEffect(() => {
    if (currentUser) {
      if (codePushApplicationId) {
        if (initialToken === 'null') {
          history?.push(
            `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${codePushApplicationId}?success=false`,
          );
        } else {
          history?.push(
            `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.CODE_PUSH}/${codePushApplicationId}?success=true`,
          );
        }
        // eslint-disable-next-line no-undef
        localStorage?.removeItem(CODE_PUSH_APPLICATION_ID_LOGIN);
      } else {
        setLoading(false);
      }
    }
  }, [codePushApplicationId, currentUser]);

  useEffect(() => {
    if (initialToken) {
      setAccessToken(initialToken);

      const timeout = setTimeout(removeQueryParamsFromUrl, 1000);

      return () => clearTimeout(timeout);
    }
  }, [initialToken]);

  const copy = () => {
    copyToClipboard(accessToken);
    messageContext?.success('Code Copied to clipboard');
  };

  const handleGenerateKey = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.APP_RELEASE)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_AUTHENTICATE, {
        label: GA_LABEL?.CLICK_AUTHENTICATE,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      codePushAuthenticate({
        variables: {
          data: {
            platform: 'JWT',
          },
        },
      });
    } else {
      setTesterModal(true);
    }
  };

  const handleInitialToken = () =>
    initialToken === 'null' ? (
      <>
        <div className="authentication-card">
          <h2>Authentication Failed</h2>
          <p>Your API token could not be created.</p>
          <p className="mt-24">Please try again later.</p>
        </div>
      </>
    ) : (
      <div className="authentication-card">
        <h2>Authentication Succeeded</h2>
        <p>Please copy and paste this token to the command window:</p>
        <div className="token">
          <p className="key">{accessToken}</p>
          <div className="copy" onClick={copy}>
            <DocumentCopy />
          </div>
        </div>
        <p className="mt-24">After doing so, please close the browser tab.</p>
      </div>
    );

  if (loading) return <LoaderComponent setHeight="100" />;

  return (
    <div className="apps-content github">
      <div className="github-login">
        {!accessToken ? (
          <>
            {currentUser?.isCodePushConnect ? (
              <>
                <h1>Generate New Authentication Key for CodePush!</h1>
                <Button type="primary" size="large" onClick={handleGenerateKey}>
                  Generate Key
                </Button>
              </>
            ) : (
              <>
                <h1>Authenticate Account for CodePush!</h1>
                <Button type="primary" size="large" onClick={handleGenerateKey}>
                  Authenticate
                </Button>
              </>
            )}
          </>
        ) : (
          handleInitialToken()
        )}
        {testerModal && (
          <TesterAccessModal
            visible={testerModal}
            setVisible={setTesterModal}
          />
        )}
      </div>
    </div>
  );
};

export default CodePushCLILogin;
