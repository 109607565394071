import { Button, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import noRelease from '../../assets/images/no-release.png';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { checkTesterAccess, googleAnalyticsEvents } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import TesterAccessModal from '../../components/TesterAccessModal';
import { usePlans } from '../../context/PlanProvider';
import './style/no-release.less';

const NoReleases = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { teamSubscription, currentRole } = usePlans();
  const history = useHistory();
  const { appId } = useParams();
  const [testerModal, setTesterModal] = useState(false);

  const newRelease = () => {
    if (checkTesterAccess(currentRole, ACTIONS?.APP_RELEASE)) {
      googleAnalyticsEvents(GA_EVENT?.CLICK_CREATE_RELEASE, {
        label: GA_LABEL?.CLICK_CREATE_RELEASE,
        member_id: currentUser?.id,
        current_plan_name: teamSubscription?.subscriptionPlan?.name,
        current_plan_type: teamSubscription?.subscriptionPlan?.type,
      });
      const path = `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${appId}${ROUTES?.CREATE_RELEASE}`;
      history?.push(path);
    } else {
      setTesterModal(true);
    }
  };

  return (
    <div className="no-release d-flex justify-center align-center">
      <div className="no-release-img">
        <img src={noRelease} alt={noRelease} width={394} />
        <div className="no-release-content d-flex justify-center flex-vertical align-center">
          <Row className="row-app">No builds in this app</Row>
          <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE}>
            <Row className="row-create font-16">
              Create releases in this app.
            </Row>
          </AccessControl>
          <div className="button-release">
            <Button type="primary" onClick={newRelease}>
              Create Release
            </Button>
          </div>
        </div>
        {testerModal && (
          <TesterAccessModal
            visible={testerModal}
            setVisible={setTesterModal}
          />
        )}
      </div>
    </div>
  );
};

export default NoReleases;
