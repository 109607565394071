import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ACTIONS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AppList from './AppList';
import InsideApps from './InsideApps';
import UploadRelease from './UploadRelease';

const AppWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.APPS} component={AppList} />
    <Route
      path={`${ROUTES?.APPS}${ROUTES?.DETAILS}/:asdd/:appId`}
      component={InsideApps}
    />
    <Route
      exact
      path={`${ROUTES?.APPS}${ROUTES?.RELEASE}/:appId`}
      render={(props) => (
        <AccessControl allowedPermissions={ACTIONS?.APP_RELEASE} showNoAccess>
          <UploadRelease {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default AppWrapper;
