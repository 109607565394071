import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { CodePushFeature } from '../assets/svg';
import { ROUTES } from '../common/constants';
import { usePlans } from '../context/PlanProvider';

const CodePushAddonFreeRestriction = ({ visible, setVisible }) => {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const { teamId } = usePlans();

  const handleClose = () => {
    dispatch({ type: 'SET_LEGACY_USER_FREE', data: true });
    setVisible(false);
  };

  const handleRedirectToPlan = () => {
    history?.push(
      `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
    );
    handleClose();
  };

  const newFeature = {
    title: '🚀 Want More CodePush Downloads? Go Pro!',
    description: (
      <div className="p-16 text-center">
        <CodePushFeature />
        <h4 className="mt-24 mb-24">
          Your current plan doesn’t support CodePush add-ons. Upgrade to Pro to
          increase your quota today.
        </h4>
        <div className="d-flex align-center gap-16 justify-center">
          <Button
            type="primary"
            className="upgrade-button"
            onClick={handleRedirectToPlan}
          >
            Upgrade Now
          </Button>
        </div>
      </div>
    ),
  };

  return (
    <Modal
      width={600}
      open={visible}
      onCancel={handleClose}
      footer={null}
      centered
      rootClassName="new-feature-modal"
      title={newFeature?.title}
    >
      {newFeature?.description}
    </Modal>
  );
};

export default CodePushAddonFreeRestriction;
