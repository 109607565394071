import { useMutation } from '@apollo/client';
import { Form, Input, Radio } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { REGEX } from '../../../common/constants';
import { CHECK_DOMAIN_AVAILABILITY } from '../graphql/mutations';
import '../release-feedbacks.less';

const DomainForm = ({
  isEdit,
  domainForm,
  dynamicLinkConfigId,
  initialDomainPrefix = '',
  setIsButtonDisable,
  customDomainCheckBoxValue,
  setCustomDomainCheckBoxValue,
}) => {
  const [checkDomain] = useMutation(CHECK_DOMAIN_AVAILABILITY, {
    onError() {},
  });

  useEffect(() => {
    if (initialDomainPrefix) {
      domainForm?.setFieldsValue({ domainPrefix: initialDomainPrefix });
    }
  }, [initialDomainPrefix, domainForm]);

  const onChangeCustomDomain = (e) => {
    setCustomDomainCheckBoxValue(e?.target?.value);
  };

  const debouncedCheckDomain = useMemo(
    () =>
      debounce((domainPrefix, resolve, reject) => {
        if (isEmpty(domainPrefix)) {
          setIsButtonDisable(true);
          return reject('Please enter a Domain Prefix');
        }
        if (REGEX?.WHITE_SPACE?.test(domainPrefix)) {
          setIsButtonDisable(true);
          return reject('Domain Prefix cannot contain spaces');
        }
        if (domainPrefix) {
          checkDomain({
            variables: {
              where: {
                domain: domainPrefix,
                configId: dynamicLinkConfigId,
              },
            },
            onCompleted: (res) => {
              if (res?.checkDomainAvailability?.isAvailable) {
                setIsButtonDisable(false);
                resolve();
              } else {
                setIsButtonDisable(true);
                reject('Domain name is already taken');
              }
            },
            onError: () => {
              reject('An error occurred while checking domain availability');
            },
          });
        }
      }, 500),
    [checkDomain],
  );

  const checkDomainName = () => async (_, value) => {
    if (isEdit) {
      setIsButtonDisable(false);
      return Promise?.resolve();
    }
    return new Promise((resolve, reject) => {
      debouncedCheckDomain(value, resolve, reject);
    });
  };

  return (
    <Form
      name="domainForm"
      form={domainForm}
      layout="vertical"
      initialValues={{ domainPrefix: initialDomainPrefix }}
    >
      <div className="d-flex align-center justify-between">
        <p className="title-text mb-24">
          Do you want to switch to custom domain?
        </p>
        <p className="title-text mb-24" title="Upcoming Feature">
          <Radio.Group
            onChange={onChangeCustomDomain}
            value={customDomainCheckBoxValue}
            disabled
          >
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </p>
      </div>
      {!customDomainCheckBoxValue ? (
        <div className="d-flex align-end">
          <Form.Item
            label="Customize your link to a domain you prefer"
            name="domainPrefix"
            className="m-0"
            requiredMark={isEdit}
            rules={[
              {
                validator: checkDomainName(),
              },
            ]}
          >
            <Input
              className="domain-input"
              type="text"
              placeholder="Enter your domain name"
              disabled={isEdit}
            />
          </Form.Item>
          <p className="main-domain"> .appsonair.link</p>
        </div>
      ) : (
        <Form.Item
          label="Customize your link to a domain you prefer"
          name="domainPrefix"
          shouldUpdate
          className="mb-4"
          rules={[
            {
              validator: checkDomainName(),
            },
          ]}
        >
          <Input
            className="domain-input"
            type="text"
            placeholder="Enter your domain name"
            disabled={isEdit}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default DomainForm;
