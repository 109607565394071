import { Key } from '@phosphor-icons/react';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { DefaultUserIcon } from '../../../../assets/svg';
import { convertTimeToLocal } from '../../../../common/utils';
import CommonTable from '../../../../components/CommonTable';
import ManageDeploymentModal from './ManageDeploymentModal';
import ReleasePreview from './ReleasePreview';

const columns = [
  {
    title: 'Releases',
    dataIndex: 'release',
    key: 'release',
    render: (_, record) => (
      <div>
        <div className="release-user">
          {record?.profileImage ? (
            <Avatar src={record?.profileImage} alt="userIcon" size={18} />
          ) : (
            <DefaultUserIcon />
          )}
          <h5 className="m-0">{record?.label}</h5>
        </div>
        <p className="m-0">{record?.releasedBy}</p>
      </div>
    ),
  },
  {
    title: 'Version',
    dataIndex: 'appVersion',
    key: 'appVersion',
    width: '150px',
  },
  {
    title: 'Status',
    dataIndex: 'isDisabled',
    key: 'isDisabled',
    width: '120px',
    render: (value) => (value ? 'Disable' : 'Enable'),
  },
  {
    title: 'Mandatory',
    dataIndex: 'isMandatory',
    key: 'isMandatory',
    width: '120px',
    render: (value) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Date',
    dataIndex: 'uploadTime',
    key: 'uploadTime',
    width: '170px',
    render: (value) => convertTimeToLocal(value),
  },
];

const ReleasesTable = ({ data, type, manageDeploymentKey }) => {
  const [selectedRelease, setSelectedRelease] = useState(null);
  const [open, setOpen] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const reversedData = [...data].reverse();

  const handleRowClick = (record) => {
    setSelectedRelease(record);
    setOpen(true);
  };

  return (
    <div className="releases-data">
      <div className="all-releases">
        <h4 className="m-0">All releases</h4>
        <div className="key" onClick={() => setManageModal(true)}>
          <Key size={24} />
        </div>
      </div>
      <div className="table">
        <CommonTable
          dataSource={reversedData}
          columns={columns}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          scroll={{
            y: 'calc(100vh - 238px)',
          }}
          rowKey={(obj) => obj?.label}
          rowClassName="clickable-row"
          pagination={false}
        />
      </div>
      {open && (
        <ReleasePreview
          open={open}
          setOpen={setOpen}
          selectedRelease={selectedRelease}
          type={type}
        />
      )}
      {manageModal && (
        <ManageDeploymentModal
          visible={manageModal}
          setVisible={setManageModal}
          manageDeploymentKey={manageDeploymentKey}
        />
      )}
    </div>
  );
};
export default ReleasesTable;
