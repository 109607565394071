import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Upload } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import Image from '../../assets/images/create-app-img.png';
import cameraIcon from '../../assets/svg/camer-icon.svg';
import {
  ALL_IMAGE_UPLOAD,
  APPLICATION_ID,
  APPLICATION_NAME,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules, getBase64 } from '../../common/utils';
import { usePlans } from '../../context/PlanProvider';
import { GET_SIGNED_URL } from '../onboard/graphql/mutation';
import { CREATE_APP } from './graphql/mutations';
import './main.less';

function MainWrapper() {
  const { dispatch } = useContext(AppContext);
  const { refetchPlanDetails, refetchAddons, teamId } = usePlans();
  const history = useHistory();
  const [form] = Form?.useForm();
  const {
    required,
    workspaceAppNameCheck,
    whiteSpaceAppName,
  } = formValidatorRules;
  const [loading, setLoading] = useState(false);
  const [appName, setAppName] = useState('');
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });

  const [currentFile, setCurrentFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [imageUpload, setImageUpload] = useState([]);

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const [createApp] = useMutation(CREATE_APP, {
    onCompleted(res) {
      setLoading(false);
      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        APPLICATION_NAME,
        res?.createApplication?.data?.appName,
      );
      // eslint-disable-next-line no-undef
      localStorage?.setItem(APPLICATION_ID, res?.createApplication?.data?.id);
      dispatch({
        type: 'SET_APPLICATION_ID',
        data: res?.createApplication?.data?.id,
      });
      dispatch({
        type: 'SET_APPLICATION_NAME',
        data: res?.createApplication?.data?.appName,
      });
      history?.push(
        `${ROUTES?.APPS}${ROUTES?.RELEASE}/${res?.createApplication?.data?.id}`,
      );
    },
    onError() {
      setLoading(false);
    },
  });

  const { selectedImage } = state;

  const handleUpload = async () => {
    setLoading(true);
    let res;
    if (imageUpload?.length > 0) {
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData?.append('file', selectedImage);
      setState({
        ...state,
        uploading: true,
      });

      res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type,
          },
        },
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read',
        },
      };
      const uploadAppIcon = await axios?.put(
        res?.data?.getSignedUrl?.signedURL,
        currentFile,
        config,
      );

      if (uploadAppIcon?.status === 200) {
        messageContext?.success('Icon Uploaded');
      }
    }
    await createApp({
      variables: {
        data: {
          teamId,
          name: appName,
          logo: res?.data?.getSignedUrl?.fileUrl,
        },
      },
    });
    refetchPlanDetails();
    refetchAddons();
  };

  const handleChange = (e) => {
    setAppName(e?.target?.value?.replace(/^\s+/, ''));
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);

      const preview = await getBase64(info?.fileList?.[0]?.originFileObj);
      setPreviewImage(preview);
    } else {
      setCurrentFile(null);
      setImageUpload([]);
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  return (
    <>
      <div className="create-app-div">
        <Card className="create-app-card">
          <span className="create-card-heading font-24">Create App</span>
          <div className="card-content">
            <Upload
              maxCount={1}
              onChange={onChangeImageUpload}
              fileList={imageUpload}
              beforeUpload={() => false}
              showUploadList={false}
            >
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="appIcon"
                  className="h-16 pointer"
                />
              ) : (
                <img src={cameraIcon} alt="camera-icon" className="pointer" />
              )}
            </Upload>
            <span className="card-text font-12">Set an App Icon</span>
          </div>
          <div className="card-form">
            <Form form={form} onFinish={handleUpload}>
              <div className="d-flex gap-20">
                <Form.Item
                  className="flex-1"
                  name="appName"
                  rules={[
                    {
                      ...required,
                      message: 'Enter a valid App Name',
                    },
                    workspaceAppNameCheck,
                    whiteSpaceAppName,
                  ]}
                >
                  <Input
                    className="app-name-input"
                    placeholder="Enter App Name"
                    onChange={handleChange}
                    maxLength={50}
                    autoFocus
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!appName}
                  loading={loading}
                >
                  Create
                </Button>
              </div>
            </Form>
          </div>
        </Card>
        <div className="mob">
          <img src={Image} alt="create-app-img" />
        </div>
      </div>
    </>
  );
}

export default MainWrapper;
